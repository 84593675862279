import {
  Tariff,
  DeductibleForTariff,
} from '@getpopsure/private-health-insurance-pricing-engine';

export type SurchargeMatrix = {
  [Ta in Tariff]: { [De in DeductibleForTariff<Ta>]: number };
};

export const nullSurchargeMatrix: SurchargeMatrix = {
  NK: { '0': 0, '300': 0, '600': 0, '1200': 0, '3000': 0 },
  NKSelectS: { '0': 0, '600': 0, '1200': 0, '3000': 0 },
  NKSelectL: { '0': 0, '600': 0, '1200': 0, '3000': 0 },
  NKSelectXL: { '0': 0, '600': 0, '1200': 0, '3000': 0 },
  KS: { '0': 0, '300': 0, '600': 0, '1200': 0 },
  Primo: { '0': 0, '300': 0, '600': 0, '1200': 0 },
  PrimoPlus: { '0': 0, '300': 0, '600': 0, '1200': 0 },
  HiMedical: { '1200': 0, '500': 0 },
  HiMedicalPlus: { '1200': 0, '500': 0 },
};

interface HiMedicalSurcharge {
  HiMedical: { '1200': number; '500': number };
  HiMedicalPlus: { '1200': number; '500': number };
}

type HiMedicalSurchageMatrix = [
  HiMedicalSurcharge,
  HiMedicalSurcharge,
  HiMedicalSurcharge,
  HiMedicalSurcharge,
  HiMedicalSurcharge,
  HiMedicalSurcharge,
  HiMedicalSurcharge,
  HiMedicalSurcharge,
  HiMedicalSurcharge,
  HiMedicalSurcharge
];

const HI_MEDICAL_AGE_LOWER_BOUND = 50;
const HI_MEDICAL_AGE_UPPER_BOUND = 59;

const normalizedAgeForHiMedicalSurchargeArray = (age: number) =>
  Math.min(
    Math.max(0, age - HI_MEDICAL_AGE_LOWER_BOUND),
    HI_MEDICAL_AGE_UPPER_BOUND - HI_MEDICAL_AGE_LOWER_BOUND
  );

const hiMedicalSurchargeMatrixForAge = (
  matrix: HiMedicalSurchageMatrix,
  age: number
) => matrix[normalizedAgeForHiMedicalSurchargeArray(age)];

export const lowSurchargeMatrix = (age: number): SurchargeMatrix => ({
  NK: { '0': 7, '300': 8, '600': 9, '1200': 9, '3000': 13 },
  NKSelectS: { '0': 7, '600': 9, '1200': 9, '3000': 13 },
  NKSelectL: { '0': 7, '600': 9, '1200': 9, '3000': 13 },
  NKSelectXL: { '0': 7, '600': 9, '1200': 9, '3000': 13 },
  KS: { '0': 6, '300': 5, '600': 5, '1200': 7 },
  Primo: { '0': 7, '300': 7, '600': 7, '1200': 8 },
  PrimoPlus: { '0': 8, '300': 8, '600': 8, '1200': 10 },
  ...hiMedicalSurchargeMatrixForAge(
    [
      {
        HiMedical: { '500': 18, '1200': 32 },
        HiMedicalPlus: { '500': 15, '1200': 21 },
      },
      {
        HiMedical: { '500': 18, '1200': 32 },
        HiMedicalPlus: { '500': 15, '1200': 21 },
      },
      {
        HiMedical: { '500': 18, '1200': 32 },
        HiMedicalPlus: { '500': 15, '1200': 21 },
      },
      {
        HiMedical: { '500': 18, '1200': 32 },
        HiMedicalPlus: { '500': 14.6, '1200': 21 },
      },
      {
        HiMedical: { '500': 18, '1200': 32 },
        HiMedicalPlus: { '500': 14.2, '1200': 20.9 },
      },
      {
        HiMedical: { '500': 18, '1200': 31.6 },
        HiMedicalPlus: { '500': 13.7, '1200': 20.2 },
      },
      {
        HiMedical: { '500': 17.5, '1200': 30.6 },
        HiMedicalPlus: { '500': 13.3, '1200': 19.5 },
      },
      {
        HiMedical: { '500': 17, '1200': 29.6 },
        HiMedicalPlus: { '500': 12.8, '1200': 18.9 },
      },
      {
        HiMedical: { '500': 16.5, '1200': 28.7 },
        HiMedicalPlus: { '500': 12.4, '1200': 18.2 },
      },
      {
        HiMedical: { '500': 15.9, '1200': 27.8 },
        HiMedicalPlus: { '500': 12, '1200': 17.6 },
      },
    ],
    age
  ),
});

export const intermediateSurchargeMatrix = (age: number): SurchargeMatrix => ({
  NK: { '0': 12, '300': 13, '600': 14, '1200': 16, '3000': 24 },
  NKSelectS: { '0': 12, '600': 14, '1200': 16, '3000': 24 },
  NKSelectL: { '0': 12, '600': 14, '1200': 16, '3000': 24 },
  NKSelectXL: { '0': 12, '600': 14, '1200': 16, '3000': 24 },
  KS: { '0': 14, '300': 13, '600': 15, '1200': 21 },
  Primo: { '0': 15, '300': 15, '600': 16, '1200': 20 },
  PrimoPlus: { '0': 12, '300': 12, '600': 13, '1200': 16 },
  ...hiMedicalSurchargeMatrixForAge(
    [
      {
        HiMedical: { '500': 35, '1200': 62 },
        HiMedicalPlus: { '500': 25, '1200': 36 },
      },
      {
        HiMedical: { '500': 35, '1200': 62 },
        HiMedicalPlus: { '500': 25, '1200': 36 },
      },
      {
        HiMedical: { '500': 35, '1200': 62 },
        HiMedicalPlus: { '500': 25, '1200': 36 },
      },
      {
        HiMedical: { '500': 35, '1200': 62 },
        HiMedicalPlus: { '500': 24.9, '1200': 36 },
      },
      {
        HiMedical: { '500': 35, '1200': 62 },
        HiMedicalPlus: { '500': 24.1, '1200': 35.6 },
      },
      {
        HiMedical: { '500': 35, '1200': 61.1 },
        HiMedicalPlus: { '500': 23.3, '1200': 34.4 },
      },
      {
        HiMedical: { '500': 33.9, '1200': 59.2 },
        HiMedicalPlus: { '500': 22.6, '1200': 33.2 },
      },
      {
        HiMedical: { '500': 32.9, '1200': 57.3 },
        HiMedicalPlus: { '500': 21.8, '1200': 32.1 },
      },
      {
        HiMedical: { '500': 31.9, '1200': 55.5 },
        HiMedicalPlus: { '500': 21.1, '1200': 31 },
      },
      {
        HiMedical: { '500': 30.9, '1200': 53.7 },
        HiMedicalPlus: { '500': 20.4, '1200': 30 },
      },
    ],
    age
  ),
});

export const highSurchargeMatrix = (age: number): SurchargeMatrix => ({
  NK: { '0': 19, '300': 21, '600': 23, '1200': 25, '3000': 37 },
  NKSelectS: { '0': 19, '600': 23, '1200': 25, '3000': 37 },
  NKSelectL: { '0': 19, '600': 23, '1200': 25, '3000': 37 },
  NKSelectXL: { '0': 19, '600': 23, '1200': 25, '3000': 37 },
  KS: { '0': 20, '300': 18, '600': 20, '1200': 28 },
  Primo: { '0': 22, '300': 22, '600': 23, '1200': 28 },
  PrimoPlus: { '0': 20, '300': 20, '600': 21, '1200': 26 },
  ...hiMedicalSurchargeMatrixForAge(
    [
      {
        HiMedical: { '500': 53, '1200': 94 },
        HiMedicalPlus: { '500': 40, '1200': 57 },
      },
      {
        HiMedical: { '500': 53, '1200': 94 },
        HiMedicalPlus: { '500': 40, '1200': 57 },
      },
      {
        HiMedical: { '500': 53, '1200': 94 },
        HiMedicalPlus: { '500': 40, '1200': 57 },
      },
      {
        HiMedical: { '500': 53, '1200': 94 },
        HiMedicalPlus: { '500': 39.5, '1200': 57 },
      },
      {
        HiMedical: { '500': 53, '1200': 94 },
        HiMedicalPlus: { '500': 38.3, '1200': 56.5 },
      },
      {
        HiMedical: { '500': 53, '1200': 92.7 },
        HiMedicalPlus: { '500': 37, '1200': 54.6 },
      },
      {
        HiMedical: { '500': 51.4, '1200': 89.8 },
        HiMedicalPlus: { '500': 35.9, '1200': 52.7 },
      },
      {
        HiMedical: { '500': 49.9, '1200': 86.9 },
        HiMedicalPlus: { '500': 34.6, '1200': 51 },
      },
      {
        HiMedical: { '500': 48.4, '1200': 84.2 },
        HiMedicalPlus: { '500': 33.5, '1200': 49.2 },
      },
      {
        HiMedical: { '500': 46.8, '1200': 81.5 },
        HiMedicalPlus: { '500': 32.4, '1200': 47.6 },
      },
    ],
    age
  ),
});
