import { insurance } from '@getpopsure/private-constants';
import { captureException } from '@sentry/browser';
import React, { useEffect } from 'react';
import Center from 'components/center';

import errorImg from './img/error.svg';

const MissingInfoError = () => {
  useEffect(() => {
    captureException(
      new Error('[MISSING_INFO]: Missing or invalid deductible or tariff')
    );
  }, []);

  return (
    <Center>
      <div data-testid="missing-info-error">
        <img src={errorImg} alt="" className="d-block ml-auto mr-auto" />
        <h1 className="p-h2 d-block ta-center mt24 tc-grey-900">
          Some information is missing
        </h1>
        <p className="p-p ta-center tc-grey-600 mt8 w100 wmx6 ml-auto mr-auto">
          Sorry, some of the information required to sign you up seems to be
          missing. Starting over from the quote page should fix this issue.
        </p>
        <a
          className="p-btn--primary mt32 wmn3 wmx4 d-block mr-auto ml-auto"
          href={`${insurance.privateHealth.preSignup}/signup/quote`}
        >
          Start over from quote
        </a>
      </div>
    </Center>
  );
};

export default MissingInfoError;
