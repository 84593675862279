import { all, call, put, select, take } from 'redux-saga/effects';
import { app } from '@getpopsure/private-constants';

import { transformQuestionnaireModel } from 'network/api/transformQuestionnaireModel';
import {
  clearAllRemoteData,
  clearRemoteData,
  mergeQuestionnaire,
  mergeQuote,
  mergeUser,
  storeAnsweredQuestion,
  submitQuestionnaire as submitQnrAction,
  fetchUser as fetchUserAction,
  setQuestionnaire,
} from 'actions';
import {
  existingCustomer,
  signIn as signInAction,
  signOut as signOutAction,
} from 'actions/session';
import {
  getQuestionAfter,
  getQuestionnaireWithoutUnreachableQuestions,
} from 'selectors';
import {
  setAsyncOperationErrored,
  setAsyncOperationFinished,
  setAsyncOperationInProgress,
} from 'actions/asyncOperation';
import { Action, ActionType } from 'actions/type';
import { path, pathForBlockerId, pathForQuestion } from 'routes/path';
import { getBlocker } from 'selectors/blocker';
import * as API from 'network/api';
import { getRiskLevel, getSpecifyQuestionnaire } from 'selectors/specify';
import { AssociatedShapeFromIdentifier, AsyncReturnType } from 'utils/types';
import { User } from 'models';
import { Question } from 'models/questions';
import { SubmitBankAccountDetailResponse } from 'models/bankAccount';
import { getSickDayPayout, getTariffAndDeductible } from 'selectors/tariff';
import { getQuote, getUser } from 'selectors/remoteData';
import {
  getAnswersMetadata,
  getQuestionOrderMetadata,
} from 'selectors/metadata';
import { AddOn } from '@getpopsure/private-health-insurance-pricing-engine';
import { setTokenCheckTimer } from 'utils/session';
import browserHistory from 'utils/browserHistory';
import { getInvestigationQuestionnaire } from 'selectors/investigation';

import { beginNewApplication } from './beginNewApplication';
import { trackConversion } from '@getpopsure/analytics';
import { verifyReferralCode } from 'features/referralEngine/sagas';
import { getValidReferralCode } from 'features/referralEngine/selectors';
import { updateReferralInfo } from 'features/referralEngine/actions';
import { shutdownIntercom } from 'features/intercomWidget';
import Session from '@getpopsure/session';
import { getPriceBreakdownFromState } from 'selectors/price';

export type AssociatedActionFromType<
  T extends Action['type']
> = AssociatedShapeFromIdentifier<Action, 'type', T>;

export function push(toPath: string) {
  browserHistory.push(toPath);
}

export function* verifyPhoneNumber(api = API) {
  while (true) {
    const actionType: ActionType = 'START_PHONE_VERIFICATION';

    const {
      phoneNumber,
    }: AssociatedActionFromType<typeof actionType> = yield take(actionType);

    yield put(setAsyncOperationInProgress('post.phone.verify'));

    try {
      yield call(api.startPhoneVerification, phoneNumber);
      yield put(setAsyncOperationFinished('post.phone.verify'));
    } catch (error) {
      yield put(setAsyncOperationErrored('post.phone.verify', error));
    }
  }
}

export function* confirmPhoneNumber(api = API) {
  while (true) {
    const actionType: ActionType = 'CONFIRM_PHONE_NUMBER';

    const {
      phoneNumber,
      code,
    }: AssociatedActionFromType<typeof actionType> = yield take(actionType);

    yield put(setAsyncOperationInProgress('post.phone.confirm'));
    try {
      yield call(api.confirmPhoneNumber, phoneNumber, code);
      yield call(push, path.legalDocuments);
      yield put(setAsyncOperationFinished('post.phone.confirm'));
    } catch (error) {
      yield put(setAsyncOperationErrored('post.phone.confirm', error));
    }
  }
}

export function* signIn() {
  while (true) {
    const actionType: ActionType = 'SIGN_IN';

    yield take(actionType);

    yield call(setTokenCheckTimer);

    yield put(fetchUserAction());
  }
}

export function* signInWithTemporaryLoginCode(api = API) {
  while (true) {
    const actionType: ActionType = 'SIGN_IN_WITH_LOGIN_CODE';

    const {
      code,
      email,
      signedInCallback,
    }: AssociatedActionFromType<typeof actionType> = yield take(actionType);
    yield put(setAsyncOperationInProgress('post.signin.code'));
    try {
      yield call(api.signInWithTemporaryLoginCode, code, email);
      yield put(setAsyncOperationFinished('post.signin.code'));
      yield put(signInAction());
      yield put(fetchUserAction());
      yield call(shutdownIntercom);

      signedInCallback?.();
    } catch (error) {
      yield put(setAsyncOperationErrored('post.signin.code', error));
    }
  }
}

export function* createUser(
  userData: Pick<
    User,
    'email' | 'firstName' | 'lastName' | 'dateOfBirth' | 'gender'
  >,
  api = API
) {
  try {
    yield put(setAsyncOperationInProgress('post.user'));
    yield call(api.createUser, userData);
    yield put(setAsyncOperationFinished('post.user'));
    yield put(signInAction());
  } catch (error) {
    yield put(setAsyncOperationErrored('post.user', error));
    throw error;
  }
}

export function* signOut(api = API) {
  while (true) {
    const actionType: ActionType = 'SIGN_OUT';
    yield take(actionType);

    yield put(setAsyncOperationInProgress('signout.user'));
    try {
      yield call(api.signOutUser);
      yield put(setAsyncOperationFinished('signout.user'));
      yield put(clearRemoteData('user'));
      yield put(existingCustomer(false));
      yield call(shutdownIntercom);
    } catch (error) {
      yield put(setAsyncOperationErrored('signout.user', error));
      throw error;
    }
  }
}

export function* submitQuestionnaire(api = API) {
  while (true) {
    const actionType: ActionType = 'SUBMIT_QUESTIONNAIRE';

    yield take(actionType);

    yield put(clearRemoteData('questionnaire'));
    yield put(setAsyncOperationInProgress('post.questionnaire'));

    const questionnaire: ReturnType<
      typeof getQuestionnaireWithoutUnreachableQuestions
    > = yield select(getQuestionnaireWithoutUnreachableQuestions);

    if (
      !questionnaire.personalInfo ||
      !questionnaire.personalInfo.name ||
      !questionnaire.personalInfo.gender ||
      !questionnaire.personalInfo.dateOfBirth ||
      !questionnaire.personalInfo.email
    ) {
      throw new Error('Trying to submit missing or incomplete questionnaire');
    }

    const remoteUser: ReturnType<typeof getUser> = yield select(getUser);

    if (remoteUser && remoteUser.email !== questionnaire.personalInfo.email) {
      yield put(clearAllRemoteData());
      yield put(signOutAction(false));
    }

    const specify: ReturnType<typeof getSpecifyQuestionnaire> = yield select(
      getSpecifyQuestionnaire
    );

    const investigation: ReturnType<
      typeof getInvestigationQuestionnaire
    > = yield select(getInvestigationQuestionnaire);

    const tarrifAndDeductible: ReturnType<
      typeof getTariffAndDeductible
    > = yield select(getTariffAndDeductible);

    if (!tarrifAndDeductible) {
      throw new Error(
        'Trying to submit questionnaire without any tariff or deductible'
      );
    }

    const { tariff, deductible } = tarrifAndDeductible;

    const answersMetadata: ReturnType<typeof getAnswersMetadata> = yield select(
      getAnswersMetadata
    );

    const priceBreakdown: ReturnType<
      typeof getPriceBreakdownFromState
    > = yield select(getPriceBreakdownFromState);

    const sickDayPayout: ReturnType<typeof getSickDayPayout> = yield select(
      getSickDayPayout
    );

    const questionOrder: ReturnType<
      typeof getQuestionOrderMetadata
    > = yield select(getQuestionOrderMetadata);
    const manipulatedQuestionOrderToPutPhoneNumberInDocs = [
      ...questionOrder,
      {
        type: 'general',
        sectionId: 'personalInfo',
        questionId: 'phoneNumber',
      } as Question,
      {
        type: 'general',
        sectionId: 'insuranceHistory',
        questionId: 'startDate',
      } as Question,
    ];

    const riskLevel: ReturnType<typeof getRiskLevel> = yield select(
      getRiskLevel
    );

    if (!priceBreakdown) {
      throw new Error('Trying to submit questionnaire with missing prices');
    }

    const transformedQuestionnaire = transformQuestionnaireModel(
      {
        ...questionnaire,
        personalInfo: {
          // Backend expects coverageStartDate
          coverageStartDate: questionnaire.insuranceHistory?.startDate,
          ...questionnaire.personalInfo,
        },
      },
      specify,
      investigation,
      {
        tariff,
        deductible,
        addOns: Object.entries(priceBreakdown.addOns.pricePerAddOn).map(
          ([k, v]) => ({
            addOn: k as AddOn,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            monthlyPrice: v!,
          })
        ),
        tariffMonthlyPrice: priceBreakdown.tariff.basePrice,
        totalMonthlyPrice: priceBreakdown.tariff.totalPrice,
        riskFactorSurcharge: priceBreakdown.tariff.riskSurcharge,
        statutorySurcharge: priceBreakdown.tariff.statutorySurcharge,
        longTermCare: priceBreakdown.tariff.longTermCare,
        ownContributions: priceBreakdown.contributions.own,
        employerContributions:
          priceBreakdown.contributions.employer ?? undefined,
        sickDayPayout,
        riskLevel,
      },
      answersMetadata,
      manipulatedQuestionOrderToPutPhoneNumberInDocs
    );

    try {
      const {
        personalInfo: {
          email,
          name: { firstName, lastName },
          dateOfBirth,
          gender,
        },
      } = questionnaire;

      const isAuthenticated: boolean = yield Session.isAuthenticated;

      const {
        data: { userExists },
      } = yield call(api.verifyCustomerEmail, email);

      if (!isAuthenticated && !userExists) {
        yield createUser(
          {
            email,
            firstName,
            lastName,
            dateOfBirth,
            gender,
          },
          api
        );
      }

      const {
        data: returnedQuestionnaire,
      }: AsyncReturnType<typeof API.createQuestionnaire> = yield call(
        api.createQuestionnaire,
        transformedQuestionnaire
      );
      yield put(mergeQuestionnaire(returnedQuestionnaire));
      yield put(setAsyncOperationFinished('post.questionnaire'));
    } catch (error) {
      yield put(setAsyncOperationErrored('post.questionnaire', error));
    }
  }
}

export function* submitBankAccountDetail(api = API) {
  while (true) {
    const actionType: ActionType = 'SUBMIT_BANK_ACCOUNT_DETAIL';
    const {
      bankAccountDetails,
    }: AssociatedActionFromType<typeof actionType> = yield take(actionType);
    const quote: ReturnType<typeof getQuote> = yield select(getQuote);
    const referralCode: string | undefined = yield select(getValidReferralCode);

    if (!quote) {
      throw new Error('Trying to submit bank account detail without a quote');
    }

    try {
      yield put(setAsyncOperationInProgress('post.bankaccountdetail'));
      const response: SubmitBankAccountDetailResponse = yield call(
        api.submitBankAccountDetail,
        {
          bankAccountDetails,
          quoteId: quote.id,
          ...(referralCode ? { referralCode } : {}),
        }
      );

      /**
       * Clear referral code after successful checkout
       */
      if (referralCode) {
        yield put(updateReferralInfo({ referralCode: undefined }));
      }

      yield call(trackConversion, {
        vertical: 'PRIVATE_HEALTH',
        policy_id: response.data.policy.id,
      });

      yield call(API.trackFinanceAdsConversion, {
        policyId: response.data.policy.id,
      });

      yield put(setAsyncOperationFinished('post.bankaccountdetail'));

      /** Automatically redirect user to the Feather App after purchase */

      window.location.href = `${app.myPolicies}?signupSuccess=privateHealth`;
    } catch (error) {
      yield put(setAsyncOperationErrored('post.bankaccountdetail', error));
    }
  }
}

function* fetchQuote() {
  while (true) {
    const actionType: ActionType = 'FETCH_QUOTE';
    const {
      questionnaireId,
    }: AssociatedActionFromType<typeof actionType> = yield take(actionType);

    try {
      yield put(setAsyncOperationInProgress('get.quote'));
      const { data: quote }: AsyncReturnType<typeof API.getQuote> = yield call(
        API.getQuote,
        questionnaireId
      );
      yield put(setAsyncOperationFinished('get.quote'));
      yield put(
        mergeQuote(quote[0].tariffInfo, quote[0].id, quote[0].userInfo)
      );
    } catch (error) {
      yield put(setAsyncOperationErrored('get.quote', error));
    }
  }
}

function* fetchUser() {
  while (true) {
    const actionType: ActionType = 'FETCH_USER';

    yield take(actionType);

    try {
      yield put(setAsyncOperationInProgress('get.user'));
      const { data: user }: AsyncReturnType<typeof API.getUser> = yield call(
        API.getUser
      );

      yield put(setAsyncOperationFinished('get.user'));
      yield put(mergeUser(user));
      yield put(
        storeAnsweredQuestion(
          {
            questionId: 'email',
            sectionId: 'personalInfo',
            type: 'general',
          },
          user.email
        )
      );
    } catch (error) {
      yield put(setAsyncOperationErrored('get.user', error));
    }
  }
}

export function* answeredSpecifyQuestion() {
  while (true) {
    const actionType: ActionType = 'ANSWERED_SPECIFY_QUESTION';
    const {
      question,
    }: AssociatedActionFromType<typeof actionType> = yield take(actionType);

    const nextQuestion: ReturnType<typeof getQuestionAfter> = yield select(
      getQuestionAfter,
      question
    );

    if (nextQuestion === undefined) {
      // This is the last question
      yield call(push, path.legalDocuments);
      continue;
    }

    yield call(push, pathForQuestion(nextQuestion));
  }
}

export function* answeredQuestion() {
  while (true) {
    const actionType: ActionType = 'ANSWERED_QUESTION';
    const action: AssociatedActionFromType<typeof actionType> = yield take(
      actionType
    );

    const { question, answer, label } = action;
    const { sectionId, questionId } = question;

    yield put(storeAnsweredQuestion(question, answer, label));

    // Get questionnaire with all unreachable answers removed
    const questionnaireWithoutUnreachableQuestions: ReturnType<
      typeof getQuestionnaireWithoutUnreachableQuestions
    > = yield select(getQuestionnaireWithoutUnreachableQuestions);

    // Overwrite current questionnaire with cleaned up version
    yield put(setQuestionnaire(questionnaireWithoutUnreachableQuestions));

    if (questionId === 'phoneNumber') {
      yield put(submitQnrAction());
      continue;
    }

    const blockerId: ReturnType<typeof getBlocker> = yield select(
      getBlocker,
      sectionId,
      questionId
    );

    if (blockerId) {
      yield call(push, pathForBlockerId(blockerId));
      continue;
    }

    const nextQuestion: ReturnType<typeof getQuestionAfter> = yield select(
      getQuestionAfter,
      question
    );

    if (nextQuestion === undefined) {
      // This is the last question
      yield call(push, path.legalDocuments);
      continue;
    }

    yield call(push, pathForQuestion(nextQuestion));
  }
}

export function* answeredInvestigation() {
  while (true) {
    const actionType: ActionType = 'ANSWERED_INVESTIGATION';
    const {
      question,
    }: AssociatedActionFromType<typeof actionType> = yield take(actionType);

    const nextQuestion: ReturnType<typeof getQuestionAfter> = yield select(
      getQuestionAfter,
      question
    );

    if (nextQuestion === undefined) {
      // This is the last question
      yield call(push, path.legalDocuments);
      continue;
    }

    yield call(push, pathForQuestion(nextQuestion));
  }
}

export default function* root() {
  yield all([
    beginNewApplication(),
    answeredQuestion(),
    answeredInvestigation(),
    submitQuestionnaire(),
    answeredSpecifyQuestion(),
    submitBankAccountDetail(),
    fetchQuote(),
    fetchUser(),
    verifyPhoneNumber(),
    confirmPhoneNumber(),
    signInWithTemporaryLoginCode(),
    verifyReferralCode(),
    signOut(),
  ]);
}
