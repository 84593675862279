import React from 'react';
import { Input } from '@popsure/dirty-swan';
import { useTranslation } from '@getpopsure/i18n-react';

import { WrappedForm } from 'pages/questions/formWrapper';
import { nonEmptyStringValidator } from 'validators';
import Center from 'components/center';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';

export default () => {
  const { t } = useTranslation();

  return (
    <Center>
      <h1 className="p-h1">
        {generalQuestionsTitleMapping.whatIsYourJobTitle?.(t)}
      </h1>
      <WrappedForm
        validator={nonEmptyStringValidator}
        sectionId="financialHistory"
        questionId="whatIsYourJobTitle"
      >
        {({ value, setValue }) => (
          <div className="mt8">
            <Input
              data-cy="questionnaire-jobtitle"
              className="w100 mt8 wmx5"
              placeholder={t(
                'questions.jobtitle.input.placeholder',
                'Job title'
              )}
              type="text"
              value={value ?? ''}
              onChange={(v) => {
                setValue(v.target.value);
              }}
              required={true}
            />
          </div>
        )}
      </WrappedForm>
    </Center>
  );
};
