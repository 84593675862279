import * as referralEngineApi from '../api';
import {
  setAsyncOperationErrored,
  setAsyncOperationFinished,
  setAsyncOperationInProgress,
} from 'actions/asyncOperation';
import { call, put, take } from 'redux-saga/effects';
import { ActionType } from 'actions/type';
import { AssociatedActionFromType } from 'sagas';
import { REFERRAL_VERIFY_OPERATION_ID } from '../constants';
import { AxiosResponse } from 'axios';
import { IsEligibleUserResponse } from '../models';
import { updateReferralInfo } from '../actions';

export function* verifyReferralCode(api = referralEngineApi) {
  while (true) {
    const actionType: ActionType = 'REFERRAL_CODE_VERIFY';
    const {
      referralCode,
    }: AssociatedActionFromType<typeof actionType> = yield take(actionType);

    try {
      yield put(setAsyncOperationInProgress(REFERRAL_VERIFY_OPERATION_ID));

      const { data }: AxiosResponse<IsEligibleUserResponse> = yield call(
        api.isEligibleUser,
        {
          referralCode,
        }
      );

      if (data.isEligibleUser) {
        yield put(
          updateReferralInfo({
            referralCode,
            referralCodeError: undefined,
          })
        );
      } else {
        yield put(updateReferralInfo({ referralCodeError: data.reason }));
      }

      yield put(setAsyncOperationFinished(REFERRAL_VERIFY_OPERATION_ID));
    } catch (err) {
      const error = err as Error;
      yield put(setAsyncOperationErrored(REFERRAL_VERIFY_OPERATION_ID, error));
    }
  }
}
