import _rawConditions from './conditions.json';
import { Tree } from 'models/questions/specify';

type RawCondition = [
  number, // condition id
  string | null, // tree id
  string, // name
  string // categoryName
];

const rawConditions = _rawConditions as RawCondition[];

const categoryNames = [
  ...new Set(rawConditions.map((condition) => condition[3])),
];

export type TreeId = Tree['id'] | null | 'ZZ';

export type CategoryName = typeof categoryNames[number];

export interface Condition {
  id: number;
  name: string;
  categoryName: CategoryName;
  treeId: TreeId;
}

export interface Category {
  name: CategoryName;
  conditions: Condition[];
}

export const conditionFromConditionId = (conditionId: number): Condition => {
  const condition = conditions.find(({ id }) => `${id}` === `${conditionId}`);

  if (!condition) {
    throw new Error(
      `Couldn’t find associated condition for condition id: ${conditionId}`
    );
  }

  return condition;
};

export const treeIdFromConditionId = (conditionId: number): TreeId => {
  return conditionFromConditionId(conditionId).treeId;
};

export const conditionNameFromConditionId = (conditionId: number): string => {
  return conditionFromConditionId(conditionId).name;
};

export const conditions: Condition[] = rawConditions.map(
  (rawCondition: RawCondition) => ({
    id: rawCondition[0],
    name: rawCondition[2],
    categoryName: rawCondition[3],
    treeId: rawCondition[1] as TreeId,
  })
);

export const categories: {
  [K in CategoryName]: Condition[];
} = conditions.reduce((mapping: { [K in string]: any }, condition) => {
  if (mapping.hasOwnProperty(condition.categoryName)) {
    mapping[condition.categoryName].push(condition);
  } else {
    mapping[condition.categoryName] = [condition];
  }

  return mapping;
}, {});
