import React, { useState } from 'react';
import { BottomOrRegularModal } from '@popsure/dirty-swan';
import { useTranslation, Trans } from '@getpopsure/i18n-react';
import { faq } from '@getpopsure/private-constants';
import { insurance } from '@getpopsure/private-constants';
import { StartDateContentVariation } from 'models/questions/general/insuranceHistoryV2';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';

export const StartDateContent = ({
  variationType,
}: {
  variationType: StartDateContentVariation;
}) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  switch (variationType) {
    case 'NOT_ON_PUBLIC_OR_PRIVATE_IN_DE_AND_EMPLOYED':
      return (
        <Trans i18nKey="questions.startDate.content.NOT_ON_PUBLIC_OR_PRIVATE_IN_DE_AND_EMPLOYED">
          <p className="p-p">
            The coverage has to start on the 1st day of the month on which you
            started your employment.
          </p>
        </Trans>
      );
    case 'ON_PUBLIC_IN_DE_AND_EMPLOYED_AND_EMPLOYMENT_START_UNDER_2_MONTHS':
    case 'ON_PUBLIC_IN_DE_AND_SELF_EMPLOYED_AND_SELF_EMPLOYMENT_START_UNDER_2_MONTHS':
      return (
        <Trans i18nKey="questions.startDate.content.ON_PUBLIC_IN_DE_AND_EMPLOYED_AND_EMPLOYMENT_START_UNDER_2_MONTHS">
          <p className="p-p">
            Choose the <span className="fw-bold">first day of the month</span>{' '}
            you began your new job, if you were previously unemployed or
            employed for under{' '}
            {{
              employeeThreshold: englishFormattedEuroCurrency(
                insurance.privateHealth.employeeThreshold,
                true
              ),
            }}
            . After we approve your coverage, you’ll receive a confirmation in
            your Feather account. Share this with your public health provider
            and they will pay you back for the period since your new job
            started.
          </p>
          <p className="p-p mt24">
            In other cases,{' '}
            <button
              className="p-a fw-bold c-pointer bg-transparent"
              onClick={() => setShowModal(true)}
              type="button"
            >
              cancellation periods
            </button>{' '}
            apply. If you’re not sure what to pick, we can adjust the date
            later!
          </p>
          <BottomOrRegularModal
            title={t(
              'questions.startDate.modal.ON_PUBLIC_IN_DE_AND_EMPLOYED_AND_EMPLOYMENT_START_UNDER_2_MONTHS.title',
              'How to choose a start date'
            )}
            isOpen={showModal}
            onClose={() => setShowModal(false)}
          >
            <div className="p24">
              <p className="p-p">
                If a cancellation periods applies, you need to notify your
                public health provider through a cancellation letter at least 2
                full calendar months in advance. If you hand in your
                cancellation letter this month, your coverage can start at the
                earliest 2 full calendar months after the current month.
              </p>
              <p className="p-p mt16">
                To help you get started, we will attach a letter of cancellation
                to your account once you submit this application. Learn more in
                <a
                  className="p-a fw-bold"
                  href={`${faq.base}/en/articles/28584-how-to-switch-from-public-to-private-health-insurance-in-3-steps`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  our cancellation periods FAQ
                </a>
                .
              </p>
            </div>
          </BottomOrRegularModal>
        </Trans>
      );
    case 'ON_PUBLIC_IN_DE_AND_EMPLOYED_AND_EMPLOYMENT_START_2_MONTHS_AND_OVER':
    case 'ON_PUBLIC_IN_DE_AND_SELF_EMPLOYED_AND_SELF_EMPLOYMENT_START_2_MONTHS_AND_OVER':
      return (
        <Trans i18nKey="questions.startDate.content.ON_PUBLIC_IN_DE_AND_EMPLOYED_AND_EMPLOYMENT_START_2_MONTHS_AND_OVER">
          <p className="p-p">
            Public health insurance has a cancellation period of 2 full calendar
            months. If you have not canceled it yet, select a start date that is
            at least 2 full months from now. We’ll reach out to you to help with
            the cancellation process after you submit the application.
          </p>
          <p className="p-p mt24">
            If you’re not sure what to pick, don’t worry, we can adjust the date
            later.
          </p>
        </Trans>
      );
    case 'GENERIC':
    case 'NOT_ON_PUBLIC_OR_PRIVATE_IN_DE_AND_NOT_EMPLOYED':
    default:
      return (
        <Trans i18nKey="questions.startDate.content.NOT_ON_PUBLIC_OR_PRIVATE_IN_DE_AND_NOT_EMPLOYED">
          <p className="p-p">
            The coverage has to start on the 1st day of the month.
          </p>
        </Trans>
      );
  }
};
