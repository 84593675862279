import {
  getPriceBreakdown,
  EmploymentStatus as PricingEngineEmploymentStatus,
} from '@getpopsure/private-health-insurance-pricing-engine';

import { AppState } from 'reducers';
import { getReachedAgeThisYear, getReachedAgeWhenPolicyStart } from 'selectors';
import {
  getTariffAndDeductible,
  getSickDayPayout,
  getAddOns,
} from 'selectors/tariff';
import { getRiskFactor } from 'selectors/specify';
import { QNREmploymentStatus } from 'models/questions/general/financialHistory';

export const getPriceBreakdownFromState = (state: AppState) => {
  const tariffAndDeductible = getTariffAndDeductible(state);

  const qnrEmploymentStatus =
    state.questionnaire.financialHistory?.employmentStatus;

  const employedOutsideGermany =
    state.questionnaire.financialHistory?.employedOutsideGermany;

  const age =
    getReachedAgeWhenPolicyStart(state) ?? getReachedAgeThisYear(state);
  const riskFactor = getRiskFactor(state);
  const sickDayPayout = getSickDayPayout(state) ?? 0;
  const selectedAddOns = getAddOns(state);

  if (!age || !tariffAndDeductible) {
    return undefined;
  }

  const qnrEmploymentStatusToPricingEngineMapping: Record<
    QNREmploymentStatus,
    PricingEngineEmploymentStatus
  > = {
    EMPLOYED: employedOutsideGermany
      ? 'EMPLOYED_OUTSIDE_OF_GERMANY'
      : 'EMPLOYED_IN_GERMANY',
    SELF_EMPLOYED: 'SELF_EMPLOYED',
    CIVIL_SERVANT: 'OTHER',
    UNIVERSITY_STUDENT: 'OTHER',
    OTHER: 'OTHER',
  };

  const employmentStatus = qnrEmploymentStatus
    ? qnrEmploymentStatusToPricingEngineMapping[qnrEmploymentStatus]
    : undefined;

  const { tariff, deductible } = tariffAndDeductible;
  const birthYear = new Date().getFullYear() - age;

  return getPriceBreakdown({
    tariff,
    birthYear,
    sickDayPayout,
    riskFactor,
    employmentStatus,
    selectedAddOns,
    deductible,
  });
};
