import { AppState } from 'reducers';
import { OperationId } from 'models/asyncOperation';
import { formatErrorMessageFromError } from 'models/error';

export const getAsyncOperationInProgress = (
  state: AppState,
  ...operationIds: OperationId[]
): boolean => {
  return operationIds.reduce((seed: boolean, operation) => {
    const inProgress = state.asyncOperation[operation]?.inProgress ?? false;
    return seed && inProgress;
  }, true);
};

export const getAsyncOperationError = (
  state: AppState,
  ...operationIds: OperationId[]
) => {
  const result = Object.entries(state.asyncOperation).find(
    ([operationId, { error }]: [any, any]) =>
      operationIds.includes(operationId) && error !== undefined
  );

  if (result) {
    const [, operation] = result;
    if (typeof operation === 'boolean') {
      return undefined;
    }
    return operation?.error;
  }

  return undefined;
};

export const getAsyncOperationErrorMessage = (
  state: AppState,
  ...operationIds: OperationId[]
): string | undefined => {
  const error = getAsyncOperationError(state, ...operationIds);

  if (error) {
    return formatErrorMessageFromError(error);
  }

  return undefined;
};
