import { Action } from 'actions/type';
import { OperationId } from 'models/asyncOperation';

export function setAsyncOperationInProgress(operationId: OperationId): Action {
  return {
    type: 'ASYNC_OPERATION_IN_PROGRESS',
    operationId,
    inProgress: true,
  };
}

export function setAsyncOperationFinished(operationId: OperationId): Action {
  return {
    type: 'ASYNC_OPERATION_IN_PROGRESS',
    operationId,
    inProgress: false,
  };
}

export function setAsyncOperationErrored(
  operationId: OperationId,
  error: Error
): Action {
  return {
    type: 'ASYNC_OPERATION_ERRORED',
    operationId,
    error,
  };
}

export function flushOperationError(operationId: OperationId): Action {
  return {
    type: 'FLUSH_OPERATION_ERROR',
    operationId,
  };
}
