import { Reducer } from 'redux';
import {
  Tariff,
  DeductibleForTariff,
  AddOn,
} from '@getpopsure/private-health-insurance-pricing-engine';

import { Action } from 'actions/type';

const DEFAULT_SICK_DAY_PAYOUT = 100;

export type TariffReducerState = {
  tariff?: Tariff;
  deductible?: DeductibleForTariff<Tariff>;
  addOns?: AddOn[];
  sickDayPayout?: number;
};

const initialState: TariffReducerState = {
  addOns: [],
  sickDayPayout: DEFAULT_SICK_DAY_PAYOUT,
};

const reducer: Reducer<TariffReducerState, Action> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'SET_TARIFF_AND_DEDUCTIBLE':
      return { ...state, tariff: action.tariff, deductible: action.deductible };
    case 'SET_ADD_ONS':
      return { ...state, addOns: action.addOns };
    case 'SET_SICK_DAY_PAYOUT':
      return { ...state, sickDayPayout: action.sickDayPayout };
    default:
      return state;
  }
};

export default reducer;
