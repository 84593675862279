import React from 'react';
import { useTranslation } from '@getpopsure/i18n-react';

import { WrappedForm } from 'pages/questions/formWrapper';
import { generateCheckboxInput } from 'pages/questions/generateInput';
import { nonEmptyArrayValidator } from 'validators';

import { InfoHintContainer, InfoHint } from 'components/infoHint';
import Center from 'components/center';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';
import {
  JawCondition,
  jawConditionMapping,
} from 'models/questions/general/medicalHistory';

export default () => {
  const { t } = useTranslation();

  return (
    <Center>
      <h1 className="p-h1">{generalQuestionsTitleMapping.jawCondition?.(t)}</h1>
      <WrappedForm
        validator={nonEmptyArrayValidator}
        sectionId="medicalHistory"
        questionId="jawCondition"
        label={jawConditionMapping(t)}
      >
        {({ value, setValue }) => (
          <>
            <InfoHintContainer className="mt8">
              {generateCheckboxInput(
                'jawCondition',
                value,
                jawConditionMapping(t),
                (updated: JawCondition[]) => {
                  const diff = updated.filter((x) => !value?.includes(x));
                  let newValue: JawCondition[];
                  if (diff.includes('NONE')) {
                    newValue = ['NONE'];
                  } else {
                    newValue = updated.filter((x) => x !== 'NONE');
                  }

                  setValue(newValue);
                }
              )}
              {value &&
                value.length > 0 &&
                value.includes('NONE') === false && (
                  <InfoHint
                    title={t(
                      'questions.jawcondition.hint.title',
                      'How will this affect my policy?'
                    )}
                  >
                    {t(
                      'questions.jawcondition.hint.description',
                      'This is a pre-existing condition that cannot be covered in this policy. You will not be covered for future treatments relating to this'
                    )}
                  </InfoHint>
                )}
            </InfoHintContainer>
          </>
        )}
      </WrappedForm>
    </Center>
  );
};
