import Welcome from 'pages/welcome';

import Blocker from 'pages/blocker';

import ConditionsQuestion from 'pages/questions/medicalHistory/investigate';
import ConfirmationScreen from 'pages/confirmationScreen';
import GenericSpecifyQuestion from 'pages/specify/generic';
import SpecificSpecifyQuestion from 'pages/specify/specific';
import AnswerCheck from 'pages/answerCheck';

import LegalDocument from 'pages/legalDocument';
import BrokerMandate from 'pages/brokerMandate';
import SepaMandate from 'pages/sepaMandate';
import { GeneralQuestionId, GeneralSectionId } from 'models/questions/general';
import { questionRoutes } from './question';
import { camelCaseToKebabCase } from 'utils/camelCaseToKebabCase';
import { Question } from 'models/questions';
import { specifyQuestionsTitleMapping } from 'models/questions/specify/mapping';
import { Tree } from 'models/questions/specify';
import { MedicalHistoryQuestion } from 'models/questions/general/medicalHistory';
import { ComponentType } from 'react';
import { path, pathForQuestion } from './path';
import { SeedQuestionnaire } from 'components/seedQuestionnaire/SeedQuestionnaire';

// TODO: Make more generic
const flatQuestionRoutes = Object.entries(questionRoutes).reduce(
  (a, [sectionId, section]) => ({
    ...a,
    ...Object.entries(section.routes).reduce(
      (b, [questionId, component]) => ({
        ...b,
        [questionId]: {
          path: pathForQuestion({
            sectionId: sectionId as any,
            questionId: questionId as any,
            type: 'general',
          }),
          component,
        },
      }),
      {}
    ),
  }),
  {} as {
    [K in GeneralSectionId]: {
      path: string;
      component: any;
    };
  }
);

export const components: { [K in keyof typeof path]: ComponentType<any> } = {
  welcome: Welcome,
  blocker: Blocker,
  questionInvestigation: ConditionsQuestion,
  legalDocuments: LegalDocument,
  brokerMandate: BrokerMandate,
  confirmation: ConfirmationScreen,
  genericSpecifyQuestion: GenericSpecifyQuestion,
  sepcificSpecifyQuestion: SpecificSpecifyQuestion,
  answerCheck: AnswerCheck,
  submitApplication: SepaMandate,
  setup: SeedQuestionnaire,
};

const routes = Object.entries(components).reduce(
  (a, [key, value]) => ({
    ...a,
    [key]: { component: value, path: path[key as keyof typeof path] },
  }),
  {} as {
    [K in keyof typeof components]: {
      path: string;
      component: ComponentType;
    };
  }
);

export default [...Object.values(routes), ...Object.values(flatQuestionRoutes)];

export const sectionIdFromKebabCaseSectionId = (
  input: string
): GeneralSectionId | undefined =>
  Object.keys(questionRoutes).find(
    (r) => camelCaseToKebabCase(r) === input
  ) as GeneralSectionId;

export const questionIdFromKebabCaseQuestionId = (
  sectionId: GeneralSectionId,
  input: string
): GeneralQuestionId | undefined =>
  Object.keys(questionRoutes[sectionId].routes).find(
    (r) => camelCaseToKebabCase(r) === input
  ) as GeneralQuestionId;

export const specifyQuestionIdFromKebabCaseSpecifyQuestionId = (
  input: string
): Tree['question']['id'] => {
  const allSpecifyQuestionIds = Object.values(specifyQuestionsTitleMapping)
    .flatMap((v) => Object.keys(v))
    .reduce(
      (a, b) => ({ ...a, [camelCaseToKebabCase(b)]: b }),
      {} as { [x: string]: string }
    );
  return allSpecifyQuestionIds[input] as Tree['question']['id'];
};

export function questionFromPathName(pathname: string): Question | undefined {
  const split = pathname.split('/');
  const preffix = split[2];
  const suffix = split[3];
  const type = determineType(split[4]);

  function determineType(
    input: string | undefined
  ): 'general' | 'investigation' | 'specify' {
    if (input === undefined) {
      return 'general';
    }

    if (input === 'investigation') {
      return 'investigation';
    }

    if (input === 'specify') {
      return 'specify';
    }

    throw new Error(`Can’t determine question from path name for ${pathname}`);
  }

  const sectionId = sectionIdFromKebabCaseSectionId(preffix);

  if (!sectionId) {
    return undefined;
  }

  const questionId = questionIdFromKebabCaseQuestionId(sectionId, suffix);

  if (!questionId) {
    return undefined;
  }

  switch (type) {
    case 'specify':
      const conditionId = parseInt(split[6], 10);
      const kebabCaseSpecifyQuestionId: string | undefined = split[7];

      if (!kebabCaseSpecifyQuestionId) {
        return {
          sectionId,
          questionId: questionId as MedicalHistoryQuestion['id'],
          type,
          conditionId,
        };
      }

      return {
        sectionId,
        questionId: questionId as MedicalHistoryQuestion['id'],
        type,
        conditionId,
        specifyQuestionId: specifyQuestionIdFromKebabCaseSpecifyQuestionId(
          kebabCaseSpecifyQuestionId
        ),
      };
    case 'general':
      return { sectionId, questionId, type };
    case 'investigation':
      return { sectionId, questionId, type };
  }
}
