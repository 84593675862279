import axios, { AxiosError } from 'axios';
import { getFixedT } from '@getpopsure/i18n-react';
import { TranslationFn } from '@getpopsure/i18n-react';

export type APIResponseError = AxiosError | Error;

export const formatErrorMessageFromError = (
  error?: APIResponseError,
  t: TranslationFn = getFixedT()
) => {
  if (!error) {
    return undefined;
  }
  let message;

  if (axios.isAxiosError(error)) {
    message = error?.response?.data?.message;
  }

  if (message) {
    return message;
  }
  return error.message || t('error.generic.text', 'Something went wrong');
};
