import { Action } from 'actions/type';

export function signIn(): Action {
  return {
    type: 'SIGN_IN',
  };
}

export function existingCustomer(existingCustomer: boolean): Action {
  return { type: 'EXISTING_CUSTOMER', existingCustomer };
}

export function signOut(displayLogin?: boolean): Action {
  return {
    type: 'SIGN_OUT',
    displayLogin,
  };
}
