import { store } from 'reducers';
import { signOut } from 'actions/session';
import { clearAllRemoteData } from 'actions';
import SessionLib from '@getpopsure/session';
import Cookies from 'js-cookie';

const IS_NEW_SESSION = 'IS_NEW_SESSION';

export class Session {
  public static setSessionHasStarted() {
    sessionStorage.setItem(IS_NEW_SESSION, 'false');
  }

  public static get isNewSession(): boolean {
    return sessionStorage.getItem(IS_NEW_SESSION) !== 'false';
  }
}

// Sets timer on token check with difference of expiration timestamp - current timestamp
export function setTokenCheckTimer() {
  const expiryCookie = Cookies.get('_frt.expiry');
  const expiryDate = expiryCookie ? new Date(expiryCookie).getTime() : 0;
  const nowUnixTimeStamp = Math.floor(Date.now() / 1000);
  const expireTimeLimit = Math.max(expiryDate - nowUnixTimeStamp, 0);

  return setTimeout(() => {
    if (!SessionLib.isAuthenticated) {
      store.dispatch(signOut());
      store.dispatch(clearAllRemoteData());
    }
  }, expireTimeLimit * 1000);
}
