import { connect } from 'react-redux';

import { AppState } from 'reducers';
import View from './view';
import { Section, GeneralSectionId } from 'models/questions/general';
import { getAllAnswersAndLabelsForSection } from 'selectors';
import { answeredQuestion } from 'actions';

interface StateProps {
  answers: Section['question'][];
}

interface DispatchProps {
  onContinue: () => void;
}

type OwnProps<
  S extends GeneralSectionId,
  Q extends Section['question']['id']
> = {
  sectionId: S;
  questionId: Q;
};

export type ViewProps<
  S extends GeneralSectionId,
  Q extends Section['question']['id']
> = StateProps & DispatchProps & OwnProps<S, Q>;

const mapStateToProps = <
  S extends GeneralSectionId,
  Q extends Section['question']['id']
>(
  state: AppState,
  { sectionId }: OwnProps<S, Q>
): StateProps => ({
  answers: getAllAnswersAndLabelsForSection(state, sectionId),
});

const mapDispatchToProps = <
  S extends GeneralSectionId,
  Q extends Section['question']['id']
>(
  dispatch: any,
  { sectionId, questionId }: OwnProps<S, Q>
): DispatchProps => ({
  onContinue: () =>
    dispatch(
      answeredQuestion(
        { sectionId, questionId: questionId as any, type: 'general' },
        true
      )
    ),
});

export default connect<
  StateProps,
  DispatchProps,
  OwnProps<GeneralSectionId, Section['question']['id']>,
  AppState
>(
  mapStateToProps,
  mapDispatchToProps
)(View);
