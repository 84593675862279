import React from 'react';
import { useTranslation } from '@getpopsure/i18n-react';

import { InfoHint, InfoHintContainer } from 'components/infoHint';
import { WrappedForm } from 'pages/questions/formWrapper';
import { generateRadioInput } from 'pages/questions/generateInput';
import { nonEmptyValidator } from 'validators';
import Center from 'components/center';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';
import { employmentStatusMapping } from 'models/questions/general/financialHistory';

export default () => {
  const { t } = useTranslation();
  return (
    <Center>
      <WrappedForm
        validator={nonEmptyValidator}
        sectionId="financialHistory"
        questionId="employmentStatus"
        label={employmentStatusMapping(t)}
      >
        {({ value, setValue }) => (
          <>
            <h1 className="p-h1">
              {generalQuestionsTitleMapping.employmentStatus?.(t)}
            </h1>
            <InfoHintContainer className="mt8">
              {generateRadioInput(
                'employmentStatus',
                value,
                employmentStatusMapping(t),
                setValue
              )}
              {value && value === 'CIVIL_SERVANT' && (
                <InfoHint
                  title={t(
                    'questions.employmenstatus.hint.civilservant.title',
                    'Who qualifies as a civil servant?'
                  )}
                >
                  {t(
                    'questions.employmenstatus.hint.civilservant.description',
                    'Civil servants are all government employees also including police officers, firefighters, judges and soldiers.'
                  )}
                </InfoHint>
              )}
            </InfoHintContainer>
          </>
        )}
      </WrappedForm>
    </Center>
  );
};
