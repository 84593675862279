const calendlyStyleSheetImport = document.createElement('link');
calendlyStyleSheetImport.rel = 'stylesheet';
calendlyStyleSheetImport.href =
  'https://assets.calendly.com/assets/external/widget.css';

const calendlyImportScript = document.createElement('script');
calendlyImportScript.type = 'text/javascript';
calendlyImportScript.src =
  'https://assets.calendly.com/assets/external/widget.js';

const allHeadScripts = [calendlyStyleSheetImport, calendlyImportScript];

export default (() => {
  allHeadScripts.forEach((script) => {
    document.head.appendChild(script);
  });
})();
