import React from 'react';
import { useTranslation, TranslationFn } from '@getpopsure/i18n-react';

import { WrappedForm } from 'pages/questions/formWrapper';
import { nonEmptyValidator } from 'validators';
import Center from 'components/center';
import { generateRadioInput } from 'pages/questions/generateInput';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';
import type { IdentificationDocument } from 'models/questions/general/personalInfo';

const statusMapping = (
  t: TranslationFn
): Partial<Record<IdentificationDocument, string>> => ({
  PASSPORT: t('questions.identificationDocument.mapping.passport', 'Passport'),
  NATIONAL_ID: t(
    'questions.identificationDocument.mapping.nationalId',
    'National ID'
  ),
});

const secondaryTextMapping = (
  t: TranslationFn
): Partial<Record<IdentificationDocument, string>> => ({
  NATIONAL_ID: t(
    'questions.identificationDocument.secondaryText.mapping.nationalId',
    'EU, EEA or Switzerland '
  ),
});

export default () => {
  const { t } = useTranslation();
  const labelMapping = statusMapping(t);
  const { PASSPORT = '', NATIONAL_ID = '' } = labelMapping;

  const skipButtonCaption = t(
    'questions.identificationDocument.skip.action',
    'Provide later'
  );

  return (
    <Center>
      <h1 className="p-h1 mb8">
        {generalQuestionsTitleMapping.identificationDocument?.(t)}
      </h1>
      <WrappedForm
        validator={nonEmptyValidator}
        sectionId="personalInfo"
        questionId="identificationDocument"
        label={{ PASSPORT, NATIONAL_ID }}
        skipButtonProps={{
          caption: skipButtonCaption,
          skipValue: '',
        }}
      >
        {({ value, setValue }) => (
          <>
            {generateRadioInput(
              'identificationDocument',
              value,
              labelMapping,
              setValue,
              secondaryTextMapping(t)
            )}
          </>
        )}
      </WrappedForm>
    </Center>
  );
};
