import { Country } from '@getpopsure/public-models';
import { storeAnsweredQuestion } from 'actions';
import { Name } from 'models';
import { QNREmploymentStatus } from 'models/questions/general/financialHistory';
import { ResidencePermitType } from 'models/questions/general/personalInfo';

export const setDateOfBirth = (dateOfBirth: string) =>
  storeAnsweredQuestion(
    {
      sectionId: 'personalInfo',
      questionId: 'dateOfBirth',
      type: 'general',
    },
    dateOfBirth
  );

export const setIncome = (income: number) =>
  storeAnsweredQuestion(
    {
      sectionId: 'financialHistory',
      questionId: 'income',
      type: 'general',
    },
    income
  );

export const setEmploymentStatus = (employmentStatus: QNREmploymentStatus) =>
  storeAnsweredQuestion(
    {
      sectionId: 'financialHistory',
      questionId: 'employmentStatus',
      type: 'general',
    },
    employmentStatus
  );

export const setName = ({ firstName, lastName }: Name) =>
  storeAnsweredQuestion(
    {
      sectionId: 'personalInfo',
      questionId: 'name',
      type: 'general',
    },
    { firstName, lastName }
  );

export const setEmail = (email: string) =>
  storeAnsweredQuestion(
    {
      sectionId: 'personalInfo',
      questionId: 'email',
      type: 'general',
    },
    email
  );

export const setNationality = (citizenshipCountries: Country[]) =>
  storeAnsweredQuestion(
    {
      sectionId: 'personalInfo',
      questionId: 'nationality',
      type: 'general',
    },
    citizenshipCountries
  );

export const setResidencePermitType = (
  residencePermitType: ResidencePermitType
) =>
  storeAnsweredQuestion(
    {
      sectionId: 'personalInfo',
      questionId: 'whatTypeOfResidencePermit',
      type: 'general',
    },
    residencePermitType
  );

export const setEmployedOutsideGermany = (employedOutsideGermany: boolean) =>
  storeAnsweredQuestion(
    {
      sectionId: 'financialHistory',
      questionId: 'employedOutsideGermany',
      type: 'general',
    },
    employedOutsideGermany
  );
