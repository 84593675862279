import { FinancialHistoryQuestion } from './financialHistory';
import { PersonalInfoQuestion } from './personalInfo';
import { InsuranceHistoryQuestion as InsuranceHistoryV2Question } from './insuranceHistoryV2';
import { MedicalHistoryQuestion } from './medicalHistory';
import { AssociatedShapeFromIdentifier } from 'utils/types';
import { TranslationFn } from '@getpopsure/i18n-react';

export type Section =
  | {
      id: 'personalInfo';
      question: PersonalInfoQuestion;
    }
  | {
      id: 'financialHistory';
      question: FinancialHistoryQuestion;
    }
  | {
      id: 'insuranceHistory';
      question: InsuranceHistoryV2Question;
    }
  | {
      id: 'medicalHistory';
      question: MedicalHistoryQuestion;
    };

export const sectionFriendlyName = (
  t: TranslationFn
): { [K in GeneralSectionId]: string } => ({
  personalInfo: t('questions.section.personalinfo.title', 'Personal info'),
  financialHistory: t(
    'questions.section.financialhistory.title',
    'Financial history'
  ),
  insuranceHistory: t(
    'questions.section.insurancehistory.title',
    'Insurance history'
  ),
  medicalHistory: t(
    'questions.section.medicalhistory.title',
    'Medical history'
  ),
});

export type GeneralQuestionnaireModel = {
  [T in GeneralSectionId]?: {
    [U in QuestionIdInSectionId<T>]?: AssociatedShapeFromIdentifier<
      Section['question'],
      'id',
      U
    >['answer'];
  };
};

export type GeneralSectionId = Section['id'];

export type GeneralQuestionId = Section['question']['id'];

export type QuestionIdInSectionId<
  S extends GeneralSectionId
> = AssociatedShapeFromIdentifier<Section, 'id', S>['question']['id'];

export const addQuestionAfter = ({
  questionsToAdd,
  afterQuestion,
  order,
}: {
  questionsToAdd: GeneralQuestionId[];
  afterQuestion: GeneralQuestionId;
  order: GeneralQuestionId[];
}): GeneralQuestionId[] => {
  const copy = order.slice(0);
  const index = copy.indexOf(afterQuestion);

  if (index === -1) {
    return order;
  }

  copy.splice(copy.indexOf(afterQuestion) + 1, 0, ...questionsToAdd);
  return copy;
};
