import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from '@getpopsure/i18n-react';

import { generalQuestionsShortTitleMapping } from 'models/questions/text/title';
import { reviewLegalText } from 'models/questions/text/legal';
import { isAddress } from 'models';
import styles from './style.module.scss';
import { ViewProps } from '..';
import Chip from './chip';
import { pathForQuestion } from 'routes/path';
import {
  GeneralSectionId,
  QuestionIdInSectionId,
} from 'models/questions/general';
import { valueForQuestion } from './util';

export default <
  S extends GeneralSectionId,
  Q extends QuestionIdInSectionId<S>
>({
  sectionId,
  answers,
  onContinue,
}: ViewProps<S, Q>) => {
  const { t } = useTranslation();
  return (
    <div className="p-body">
      <p className="p-p mt48">
        {t(
          'questions.review.description',
          'Review or edit the information you have provided so far.'
        )}
      </p>
      <div className="wmx5 mt16">
        {answers.map((question) => {
          const title = generalQuestionsShortTitleMapping(t)[question.id];
          const value = valueForQuestion(question);

          if (!title || !value) {
            return undefined;
          }

          return (
            <Chip
              key={question.id}
              link={pathForQuestion({
                sectionId,
                questionId: question.id,
                type: 'general',
              })}
              title={title}
              value={value}
              truncated={!isAddress(question.answer)}
            />
          );
        })}
      </div>
      <ReactMarkdown
        linkTarget="_blank"
        className={`${styles.legal} p-p--small mt24 wmx7`}
      >
        {reviewLegalText}
      </ReactMarkdown>
      {/* TODO: Continue to xxx  */}
      <button
        data-cy="review-continue"
        className={`p-btn--primary mt24 wmn3 ${styles.continue}`}
        onClick={() => onContinue()}
      >
        {t('questions.review.continue.action', 'Continue')}
      </button>
    </div>
  );
};
