import React from 'react';

import styles from './style.module.scss';
import { Link } from 'react-router-dom';

export default ({
  title,
  value,
  link,
  truncated = true,
}: {
  title: string;
  value: string;
  link: string;
  truncated?: boolean;
}) => {
  return (
    <Link to={link} className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>{title}</div>
        <div
          className={`${styles.value} ${
            truncated ? styles['value--truncated'] : ''
          }`}
        >
          {value}
        </div>
      </div>
      <div className={styles['icon-wrapper']}>
        <div className={styles.icon} />
      </div>
    </Link>
  );
};
