import { AppState } from 'reducers';
import { User } from 'models';

export const getQuote = (state: AppState) => {
  return state.remoteData.quote;
};

export const getUser = (state: AppState): User | undefined => {
  return state.remoteData.user;
};

export const getQuestionnaire = (state: AppState) => {
  return state.remoteData.questionnaire;
};
