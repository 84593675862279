import { connect } from 'react-redux';

import { Quote, User, BankAccountDetails } from 'models';

import View from './component';
import { AppState } from 'reducers';
import {
  getAsyncOperationInProgress,
  getAsyncOperationErrorMessage,
  getAsyncOperationError,
} from 'selectors/asyncOperation';
import { submitIban, fetchQuote, fetchUser } from 'actions';
import { getQuote, getUser } from 'selectors/remoteData';
import { GeneralQuestionnaireModel } from 'models/questions/general';
import { OperationId } from 'models/asyncOperation';

type StateProps = {
  loading: boolean;
  loadingError?: Error;
  submitting: boolean;
  quote?: Quote;
  user?: User;
  questionnaire?: { id: string; answers: GeneralQuestionnaireModel };
  submittingError?: string;
};

interface DispatchProps {
  fetchData: (questionnaireId: string) => void;
  onSubmitBankAccountDetail: (bankAccountDetails: BankAccountDetails) => void;
}

export type ViewProps = StateProps & DispatchProps;

const mapStateToProps = (state: AppState): StateProps => {
  const operations: OperationId[] = ['get.quote', 'get.user'];

  const quote = getQuote(state);

  return {
    loading: getAsyncOperationInProgress(state, ...operations),
    loadingError: getAsyncOperationError(state, ...operations),
    submitting: getAsyncOperationInProgress(state, 'post.bankaccountdetail'),
    submittingError: getAsyncOperationErrorMessage(
      state,
      'post.bankaccountdetail'
    ),
    quote,
    user: getUser(state),
    questionnaire: state.remoteData.questionnaire,
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  fetchData: (questionnaireId: string) => {
    dispatch(fetchQuote(questionnaireId));

    dispatch(fetchUser());
  },
  onSubmitBankAccountDetail: (bankAccountDetails: BankAccountDetails) => {
    dispatch(submitIban(bankAccountDetails));
  },
});

export default connect<StateProps, DispatchProps, unknown, AppState>(
  mapStateToProps,
  mapDispatchToProps
)(View);
