import { connect } from 'react-redux';

import { AppState } from 'reducers';
import { answeredQuestion, storeAnsweredQuestion } from 'actions';
import { GeneralQuestionnaireModel } from 'models/questions/general';
import { LabelType } from 'actions/type';

interface DispatchProps<
  S extends keyof GeneralQuestionnaireModel,
  Q extends keyof Required<GeneralQuestionnaireModel>[S]
> {
  onAnswerQuestion: (
    answer: Required<GeneralQuestionnaireModel>[S][Q],
    label?: LabelType
  ) => void;
  storeAnsweredQuestion: (
    answer: Required<GeneralQuestionnaireModel>[S][Q],
    label?: LabelType
  ) => void;
}

interface StateProps<
  S extends keyof GeneralQuestionnaireModel,
  Q extends keyof Required<GeneralQuestionnaireModel>[S]
> {
  answer?: Required<GeneralQuestionnaireModel>[S][Q];
  questionnaireAnswers?: GeneralQuestionnaireModel;
}

export type ViewProps<
  S extends keyof GeneralQuestionnaireModel,
  Q extends keyof Required<GeneralQuestionnaireModel>[S]
> = StateProps<S, Q> & DispatchProps<S, Q>;

const mapStateToProps = <
  S extends keyof GeneralQuestionnaireModel,
  Q extends keyof Required<GeneralQuestionnaireModel>[S]
>(
  sectionId: S,
  questionId: Q
) => {
  return (state: AppState): StateProps<S, Q> => {
    return {
      answer: state.questionnaire[sectionId]?.[questionId],
      questionnaireAnswers: state.questionnaire,
    };
  };
};

const mapDispatchToProps = <
  S extends keyof GeneralQuestionnaireModel,
  Q extends keyof Required<GeneralQuestionnaireModel>[S]
>(
  sectionId: S,
  questionId: Q
) => {
  return (dispatch: any): DispatchProps<S, Q> => {
    return {
      onAnswerQuestion: (answer, label) =>
        dispatch(
          answeredQuestion(
            // TODO: Type safe
            { sectionId, questionId: questionId as any, type: 'general' },
            answer as any,
            label
          )
        ),
      storeAnsweredQuestion: (answer, label) =>
        dispatch(
          storeAnsweredQuestion(
            { sectionId, questionId: questionId as any, type: 'general' },
            answer as any,
            label
          )
        ),
    };
  };
};

export default <
  S extends keyof GeneralQuestionnaireModel,
  Q extends keyof Required<GeneralQuestionnaireModel>[S]
>(
  sectionId: S,
  questionId: Q
) =>
  connect<StateProps<S, Q>, DispatchProps<S, Q>, unknown, AppState>(
    mapStateToProps(sectionId, questionId),
    mapDispatchToProps(sectionId, questionId)
  );
