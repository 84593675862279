import { Reducer } from 'redux';
import { OperationId } from 'models/asyncOperation';
import { Action } from 'actions/type';
import { AxiosError } from 'axios';

type State = {
  [ID in OperationId]?: {
    inProgress: boolean;
    error?: AxiosError | Error;
  };
};

const initialState: State = {};

const reducer: Reducer<State, Action> = (state = initialState, action) => {
  switch (action.type) {
    case 'ASYNC_OPERATION_IN_PROGRESS':
      return {
        ...state,
        [action.operationId]: {
          inProgress: action.inProgress,
          error: undefined,
        },
      };
    case 'ASYNC_OPERATION_ERRORED':
      return {
        ...state,
        [action.operationId]: {
          inProgress: false,
          error: action.error,
        },
      };
    case 'FLUSH_OPERATION_ERROR':
      return {
        ...state,
        [action.operationId]: {
          inProgress: false,
          error: undefined,
        },
      };
    default:
      return state;
  }
};

export default reducer;
