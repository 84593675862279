import React, { useState } from 'react';
import { useTranslation } from '@getpopsure/i18n-react';

import Form from 'components/form';
import Center from 'components/center';
import connectedAnswer from 'pages/questions/connectedAnswer';
import { nonEmptyStringValidator } from 'validators';
import { ViewProps } from 'pages/questions/connectedAnswer';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';

import styles from 'pages/specify/generic/view/style.module.scss';

export default connectedAnswer(
  'financialHistory',
  'jobDescription'
)(
  ({
    answer,
    onAnswerQuestion,
  }: ViewProps<'financialHistory', 'jobDescription'>) => {
    const { t } = useTranslation();
    const [value, setValue] = useState(answer);
    const valid = nonEmptyStringValidator(value);

    return (
      <Center>
        <h1 className="p-h1">
          {generalQuestionsTitleMapping.jobDescription?.(t)}
        </h1>
        <p className="p-p mt8">
          {t(
            'questions.jobdescription.description',
            'Please explain in a paragraph what your work consists of.'
          )}
        </p>
        <Form valid={valid} value={value} onAnswerQuestion={onAnswerQuestion}>
          <div className="mt16">
            <textarea
              data-cy="questionnaire-job-description"
              className={`w100 wmn6 ${styles.textarea}`}
              placeholder={t(
                'questions.jobdescription.input.placeholder',
                'I work as a...'
              )}
              id="jobDescription"
              name="jobDescription"
              value={value}
              onChange={(v) => {
                setValue(v.target.value);
              }}
              required={true}
            />
          </div>
          <div className="mt24">
            <button
              data-cy="questionnaire-continue"
              className="p-btn--primary wmn3"
              type="submit"
              disabled={!valid}
            >
              {t('questions.jobdescription.continue.action', 'Continue')}
            </button>
          </div>
        </Form>
      </Center>
    );
  }
);
