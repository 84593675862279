import { Condition } from 'models/medicalConditions';

export default ({
  conditions,
  query,
}: {
  conditions: Condition[];
  query: string;
}): Condition[] => {
  const substring = query.toLowerCase();

  return conditions.filter((condition) => {
    return (
      condition.categoryName.toLowerCase().includes(substring) ||
      condition.name.toLowerCase().includes(substring)
    );
  });
};
