import React, { Component, ErrorInfo } from 'react';
import * as Sentry from '@sentry/browser';
import { Trans } from '@getpopsure/i18n-react';

import styles from './style.module.scss';
import { useDispatch } from 'react-redux';
import { resetStore } from 'actions';

interface StateProps {
  haserror: boolean;
  eventId?: string;
}

const ResetStoreComponent = () => {
  const dispatch = useDispatch();
  dispatch(resetStore());
  return <></>;
};

export default class ErrorBoundary extends Component<unknown, StateProps> {
  public static getDerivedStateFromError() {
    return { haserror: true };
  }

  constructor(props: unknown) {
    super(props);
    this.state = { haserror: false };
  }

  public componentDidCatch(error: Error, info: ErrorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(info as any);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  public render() {
    if (this.state.haserror) {
      return (
        <div className={styles.container} data-cy="error-boundary">
          <div className="p-body">
            <div className={styles.content}>
              <h1 className="p-h1 mt24">
                <Trans i18nKey="component.error.title">Oops!</Trans>
              </h1>
              <p className="p-p mt24">
                <Trans i18nKey="component.error.description">
                  It seems like something went wrong. Any saved information may
                  be lost.
                  <br /> We recommend updating your browser to the latest
                  version.
                </Trans>
              </p>
              <div className="mt16">
                <button
                  className={`p-btn--primary ${styles.button}`}
                  onClick={() => {
                    Sentry.showReportDialog({ eventId: this.state.eventId });
                  }}
                >
                  <Trans i18nKey="component.error.report.action">
                    Report Problem
                  </Trans>
                </button>
                <a
                  data-cy="error-boundary-refresh-page"
                  href="/#"
                  onClick={() => {
                    this.setState({ haserror: false, eventId: undefined });
                  }}
                  className={`p-btn p-a ${styles.button}`}
                >
                  <Trans i18nKey="component.error.refresh.action">
                    Refresh the page
                  </Trans>
                </a>
              </div>
              <ResetStoreComponent />
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}
