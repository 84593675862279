import React from 'react';

import warningIcon from './img/warning.svg';

import styles from './style.module.scss';

interface Props {
  description: string;
  progress: number;
  warning: boolean;
}

const CheckboxIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6673 5.83301L7.50065 14.9997L3.33398 10.833"
      className={styles['stroke-primary-500']}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ProgressRow = ({ description, progress, warning }: Props) => {
  const cappedProgress = Math.min(Math.max(0, progress), 100);

  return (
    <div className={styles.container}>
      <p className="p-p d-block">{description}</p>
      <div className={styles['progress-root']}>
        <div
          className={
            warning
              ? styles['progress-container--warning']
              : styles['progress-container']
          }
        >
          <div
            style={{ width: `${cappedProgress}%` }}
            className={warning ? styles['progress--warning'] : styles.progress}
          ></div>
        </div>
        {(() => {
          if (warning === true) {
            return (
              <img src={warningIcon} width={20} height={20} alt="warning" />
            );
          } else if (cappedProgress === 100) {
            return <CheckboxIcon />;
          } else if (cappedProgress > 0) {
            return <div className={styles.loading} />;
          } else {
            return <div className={styles.spacer} />;
          }
        })()}
      </div>
    </div>
  );
};

export default ProgressRow;
