import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { Input } from '@popsure/dirty-swan';
import { useTranslation } from '@getpopsure/i18n-react';

import Center from 'components/center';
import { WrappedForm } from 'pages/questions/formWrapper';
import { nonEmptyArrayValidator } from 'validators';

import styles from './style.module.scss';

export default ({
  children: Children,
  questionId,
  title,
}: {
  children: React.ComponentType<{
    onSelectTeeth: (teeth: number) => void;
    selectedTeeth: Set<number>;
  }>;
  questionId: 'upperJawMissingTeeth' | 'lowerJawMissingTeeth';
  title: string;
}) => {
  const { t } = useTranslation();

  return (
    <Center>
      <h4 className="p-p tc-primary-500 fw-bold">
        {t('questions.hasmissingteeth.firsttitle', 'Missing teeth')}
      </h4>
      <h1 className="p-h1">{title}</h1>
      <WrappedForm
        validator={nonEmptyArrayValidator}
        sectionId="medicalHistory"
        questionId={questionId}
      >
        {({ value, setValue }) => {
          const selectedTeeth = new Set(value);
          const [selectedTeethStr, setSelectedTeethStr] = useState(
            [...selectedTeeth].join(', ')
          );
          const [manualMode, setManualMode] = useState(false);

          const onSelectTeeth = (teeth: number) => {
            selectedTeeth.has(teeth)
              ? selectedTeeth.delete(teeth)
              : selectedTeeth.add(teeth);
            setValue([...selectedTeeth]);
            setSelectedTeethStr([...selectedTeeth].join(', '));
          };

          const onEditSelectedTeethStr = (newValue: string) => {
            const selectedTeethStrArrayReprenseation = newValue
              .replace(/ /g, '')
              .split(',')
              .map((v) => parseInt(v, 10))
              .filter((v) => isNaN(v) === false);
            setValue(selectedTeethStrArrayReprenseation);
            setSelectedTeethStr(newValue);
          };

          return (
            <div>
              <div className={`wmx6 ${styles['children-container']}`}>
                <Children
                  onSelectTeeth={onSelectTeeth}
                  selectedTeeth={selectedTeeth}
                />
              </div>
              <AnimateHeight
                duration={300}
                height={manualMode === true ? 'auto' : 0}
              >
                <div className={styles['manual-mode-container']}>
                  <Input
                    data-cy="manual-teeth-enter-input"
                    className="w100 mt24 wmx5"
                    placeholder={t(
                      'questions.hasmissingteeth.input.placeholder',
                      'Tooth Number'
                    )}
                    type="text"
                    value={selectedTeethStr}
                    required={true}
                    onChange={(e) => {
                      onEditSelectedTeethStr(e.target.value);
                    }}
                  />
                  <p className="p-p--small	 mt8 tc-grey-500">
                    {t(
                      'questions.hasmissingteeth.input.help',
                      'When entering multiple teeth manually, separate each number with a comma (e.g. 1, 23)'
                    )}
                  </p>
                </div>
              </AnimateHeight>
              <AnimateHeight
                duration={300}
                height={manualMode === true ? 0 : 'auto'}
              >
                <button
                  data-cy="manual-teeth-enter-button"
                  type="button"
                  className={`${styles['manual-button']}`}
                  onClick={() => setManualMode(true)}
                >
                  {t(
                    'questions.hasmissingteeth.manualinput.action',
                    'Enter manually +'
                  )}
                </button>
              </AnimateHeight>
            </div>
          );
        }}
      </WrappedForm>
    </Center>
  );
};
