import { Reducer } from 'redux';

import { GeneralQuestionnaireModel } from 'models/questions/general';
import { Action } from 'actions/type';

type State = GeneralQuestionnaireModel;

const initialState: State = {};

const reducer: Reducer<State, Action> = (
  state = initialState,
  action: Action
) => {
  switch (action.type) {
    case 'STORE_ANSWERED_QUESTION':
      return {
        ...state,
        [action.question.sectionId]: {
          ...state[action.question.sectionId],
          [action.question.questionId]: action.answer,
        },
      };
    case 'SET_QUESTIONNAIRE':
      return action.questionnaire;
    default:
      return state;
  }
};

export default reducer;
