import { connect } from 'react-redux';
import { AppState } from 'reducers';

import View from './component';
import { pathForQuestion } from 'routes/path';
import {
  getReachableSections,
  getReachableQuestionsInSection,
} from 'selectors';
import { RouteComponentProps } from 'react-router-dom';

interface StateProps {
  firstQuestionUrl: string;
}

type OwnProps = RouteComponentProps<Record<string, any>>;

export type ViewProps = StateProps & OwnProps;

const mapStateToProps = (state: AppState): StateProps => {
  const firstSection = getReachableSections(state)[0];
  const firstQuestion = getReachableQuestionsInSection(state, firstSection)[0];
  const firstQuestionUrl = pathForQuestion(firstQuestion);

  if (!firstQuestionUrl) {
    throw new Error('Can’t find a path for first question');
  }

  return {
    firstQuestionUrl,
  };
};

export default connect<StateProps, unknown, OwnProps, AppState>(
  mapStateToProps
)(View);
