import React from 'react';
import { Input } from '@popsure/dirty-swan';
import { useTranslation } from '@getpopsure/i18n-react';

import { WrappedForm } from 'pages/questions/formWrapper';
import { nonEmptyValidator } from 'validators';
import Center from 'components/center';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';

export default () => {
  const { t } = useTranslation();

  return (
    <Center>
      <WrappedForm
        validator={nonEmptyValidator}
        sectionId="medicalHistory"
        questionId="weightInKg"
      >
        {({ value, setValue }) => (
          <>
            <h1 className="p-h1">
              {generalQuestionsTitleMapping.weightInKg?.(t)}
            </h1>
            <div className="mt8">
              <Input
                data-cy="questionnaire-weight"
                className="w100 wmx5"
                placeholder={t(
                  'questions.weightinkg.input.placeholder',
                  'Weight (kg)'
                )}
                type="number"
                value={value ?? ''}
                onChange={(v) => {
                  const castedValue = Number(v.target.value);
                  setValue(castedValue === 0 ? undefined : castedValue);
                }}
                required={true}
              />
            </div>
          </>
        )}
      </WrappedForm>
    </Center>
  );
};
