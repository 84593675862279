import { Action } from 'actions/type';
import { UserReferralInfo } from '../models';

export const verifyReferralCode = (referralCode: string): Action => ({
  type: 'REFERRAL_CODE_VERIFY',
  referralCode,
});

export const updateReferralInfo = (referralInfo: UserReferralInfo): Action => ({
  type: 'UPDATE_REFERRAL_CODE_INFO',
  referralInfo,
});

export function flushReferralEngineError(): Action {
  return {
    type: 'UPDATE_REFERRAL_CODE_INFO',
    referralInfo: { referralCodeError: undefined },
  };
}
