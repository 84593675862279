import cloneDeep from 'lodash.clonedeep';

export function pickProperties<T, K extends keyof T>(
  obj: T,
  ...props: K[]
): Pick<T, K> {
  const copy = cloneDeep(obj);
  const keys = Object.keys(copy) as K[];
  const keysToDelete = keys.filter((key) => props.includes(key) === false);
  keysToDelete.forEach((key) => {
    delete copy[key];
  });
  return copy;
}
