import React from 'react';

import styles from './style.module.scss';

export default ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => <div className={`${styles.container} ${className}`}>{children}</div>;
