import { insurance, email } from '@getpopsure/private-constants';
import { getFixedT } from '@getpopsure/i18n-react';

import {
  QuestionIdInSectionId,
  GeneralSectionId,
} from 'models/questions/general';

export const reviewLegalText = (() => {
  const t = getFixedT();
  return t(
    'questions.default.legal.text',
    'By selecting "Continue", I confirm to have answered all questions truthfully. Knowingly omitting any relevant details entitles the insurer to cancel the contract—either retroactively or from the date the omission is discovered—or change the contract in accordance with **§ 19 Abs. 5 VVG** [(Information on the consequences of the violation of the disclosure obligation)]({{disclosureObligation}}).',
    { disclosureObligation: insurance.privateHealth.disclosureObligation }
  );
})();

export const questionnaireLegalText: {
  [T in GeneralSectionId]: { [U in QuestionIdInSectionId<T>]?: string };
} = (() => {
  const t = getFixedT();
  return {
    personalInfo: {
      phoneNumber: t(
        'questions.phonenumber.legal.text',
        'By providing my phone number and clicking “Continue” I allow Feather and the underwriting insurer Hallesche Krankenversicherung a.G. to use my contact info (phone & email) to get in touch with me. Feather can still contact me even if I don’t actually sign up for health insurance, however, I understand that I can withdraw my consent to be contacted easily at any time and that giving my consent is voluntary. If you would like to signup without providing a phone number please contact [hello@feather-insurance.com](mailto:{{helloEmail}}) so that we can contact you.',
        { helloEmail: email.hello }
      ),
      personalInfoReview: reviewLegalText,
    },
    financialHistory: {
      hasGermanTaxId: t(
        'questions.hasgermantaxid.legal.text',
        'Your tax ID (steuerliche Identifikationsnummer or Steuer-ID) is a unique number automatically assigned and sent to you when you first register in Germany. This is not your tax number (Steuernummer).\n- Hallesche Krankenversicherung a.G. will pass on to the tax authorities the amounts of the tax reducing health and compulsory nursing care premiums as well as the necessary personal data of you.\n- We ask you to give us the tax ID in order to pass on this data. If you do not provide your tax ID, we are entitled to obtain it from the Federal Central Tax office.'
      ),
      germanTaxId: t(
        'questions.germantaxid.legal.text',
        'Health insurance cost is partially tax deductible. To make it easy to claim those tax deductions we automatically pass on all necessary data to the tax authorities. This includes the amounts of the tax reducing health and compulsory nursing care premiums, personal data and data about any other persons insured (e.g. children, spouse). If you do not provide your tax ID, we are entitled to obtain it from the federal central tax office.'
      ),
      financialHistoryReview: reviewLegalText,
    },
    insuranceHistory: {
      insuranceHistoryV2Review: reviewLegalText,
    },
    medicalHistory: {
      insuranceLawDisclaimer: t(
        'questions.insurancelawdisclaimer.legal.text',
        'By selecting “Continue”, I confirm and understand according to § 19 Abs. 5 VVG (insurance law) it is important to answer the next set of questions truthfully and not to leave out any information. I understand omitting any relevant detail shall leave the insurer entitled to rescind the contract or to allow for the contract to be canceled retroactively. Additionally, I am aware that the terms of the contract may be adjusted if I was to provide false information. I have taken note of the [information regarding disclosure obligations]({{disclosureObligation}}).',
        { disclosureObligation: insurance.privateHealth.disclosureObligation }
      ),
      dataProcessingDisclaimer: t(
        'questions.dataprocessing.legal.text',
        'By selecting “Continue”, I agree my health data may be processed, transferred, and used by Feather and the underwriting provider Hallesche Krankenversicherung a.G. according to their [health data protection policies]({{dataProtection}}). I agree to the terms about the release from confidentiality to both Feather and the underwriting provider Hallesche Krankenversicherung a.G. My health data may be collected, stored, and shared with third parties only where absolutely necessary by Feather and Hallesche Krankenversicherung a.G.',
        { dataProtection: insurance.privateHealth.dataProtection }
      ),
      medicalHistoryReview: reviewLegalText,
    },
  };
})();
