import React, { useEffect } from 'react';
import { captureException } from '@sentry/browser';
import { useTranslation } from '@getpopsure/i18n-react';

import Center from 'components/center';
import { formatErrorMessageFromError } from 'models/error';

import styles from './style.module.scss';

export default ({ error }: { error: any }) => {
  const { t } = useTranslation();
  useEffect(() => {
    captureException(error);
  }, [error]);

  return (
    <Center>
      <div className={`d-block ml-auto mr-auto ${styles.image}`} />
      <h1 className="p-h1 d-block ta-center mt24">
        {formatErrorMessageFromError(error)}
      </h1>
      <button
        className="p-btn--primary mt32 wmn3 d-block mr-auto ml-auto"
        onClick={() => {
          window.location.reload(true);
        }}
      >
        {t('component.genericerror.refresh.action', 'Refresh')}
      </button>
    </Center>
  );
};
