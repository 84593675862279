import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from '@getpopsure/i18n-react';

import { ViewProps } from '..';
import { SpecifyQuestionnaireModel } from 'models/questions/specify';
import {
  generateRadioInput,
  YesNoQuestion,
} from 'pages/questions/generateInput';
import Center from 'components/center';
import Form from 'components/form';
import { UploadedFile } from 'models';

import styles from './style.module.scss';
import { trueValidator, nonEmptyValidator } from 'validators';
import { UploadDocComponent } from '../components';

export default <
  S extends keyof SpecifyQuestionnaireModel,
  Q extends keyof SpecifyQuestionnaireModel[S]
>({
  treeId,
  specifyQuestionId: questionId,
  answer,
  onAnswerQuestion,
  storeAnsweredQuestion,
  title,
  questionType,
  conditionName,
}: ViewProps<S, Q>) => {
  const [value, setValue] = useState(answer);
  const [isFileSubmittable, setIsFileSubmittable] = useState(false);

  const uploadFileValidator = () => isFileSubmittable;

  const validator = (() => {
    switch (questionType.type) {
      case 'legal':
        return trueValidator;
      case 'file':
        return uploadFileValidator;
      default:
        return nonEmptyValidator;
    }
  })();

  const valid = validator(value as any);

  useEffect(() => {
    setValue(answer);
  }, [answer]);

  const { t } = useTranslation();

  return (
    <Center>
      <h4 className="p-p tc-primary-500 fw-bold">{conditionName}</h4>
      <h1 className="p-h1 mt8">{title}</h1>
      <div className="mt16">
        <Form valid={valid} value={value} onAnswerQuestion={onAnswerQuestion}>
          {(() => {
            switch (questionType.type) {
              case 'enum':
                return generateRadioInput(
                  questionId as string,
                  value as any,
                  questionType.mapping,
                  setValue as any
                );
              case 'boolean':
                return (
                  <YesNoQuestion
                    name={questionId as string}
                    value={value as any}
                    setValue={setValue as any}
                  />
                );
              case 'file': {
                const submittableQuestionId = `${treeId}-${String(questionId)}`;
                const setUploadedFiles = (uploadedFiles: UploadedFile[]) => {
                  setValue(uploadedFiles as any);
                };

                return (
                  <div className="mt8">
                    <UploadDocComponent
                      value={((value as unknown) as UploadedFile[]) ?? []}
                      questionId={submittableQuestionId}
                      setUploadedFiles={setUploadedFiles}
                      validator={setIsFileSubmittable}
                      storeAnsweredQuestion={storeAnsweredQuestion}
                    />
                  </div>
                );
              }
              case 'legal':
                return (
                  <>
                    <p className="p-p mt8">
                      {t(
                        'questions.specific.legal.title',
                        'It is important that you are aware of the following:'
                      )}
                    </p>
                    <div
                      className={`mt16 wmx5 p-p p-p--small ${styles['text-container']}`}
                    >
                      <ReactMarkdown
                        linkTarget="_blank"
                        source={questionType.text}
                      />
                    </div>
                    <div className="p-label-container mt24">
                      <input
                        id="terms-checkbox"
                        className="p-checkbox"
                        type="checkbox"
                        checked={value as any}
                        onChange={() => setValue(!value as any)}
                        onClick={() => setValue(!value as any)}
                      />
                      <label
                        style={{ minHeight: 0 }} // TODO: Issue with dirtyswan here
                        className="p-label"
                        htmlFor="terms-checkbox"
                        data-cy="specify-legal-checkbox"
                      >
                        {t(
                          'questions.specific.legal.agree.action',
                          'I have read and agree to the terms above'
                        )}
                      </label>
                    </div>
                  </>
                );
            }
          })()}

          <button
            data-cy="specify-condition-continue"
            className="p-btn--primary mt24 wmn3"
            type="submit"
            disabled={!valid}
          >
            {t('questions.specific.legal.continue.action', 'Continue')}
          </button>
        </Form>
      </div>
    </Center>
  );
};
