import React from 'react';
import { CurrencyInput } from '@popsure/dirty-swan';
import { useTranslation } from '@getpopsure/i18n-react';

import { WrappedForm } from 'pages/questions/formWrapper';
import { nonEmptyValidator } from 'validators';
import Center from 'components/center';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';

export default () => {
  const { t } = useTranslation();

  return (
    <Center>
      <h1 className="p-h1">{generalQuestionsTitleMapping.income?.(t)}</h1>
      <WrappedForm
        validator={nonEmptyValidator}
        sectionId="financialHistory"
        questionId="income"
      >
        {({ value, setValue }) => (
          <div className="mt8">
            <CurrencyInput
              data-cy="questionnaire-income"
              className="w100 wmx5 mt8"
              placeholder={t(
                'questions.income.input.placeholder',
                'Annual Income'
              )}
              value={value}
              onChange={(v) => {
                setValue(v);
              }}
              required={true}
            />
          </div>
        )}
      </WrappedForm>
    </Center>
  );
};
