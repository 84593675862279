import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useUnleashClient, useFlagsStatus } from '@unleash/proxy-client-react';
import { updateFeatureFlags } from 'actions';

export const FeatureFlagsLoader = ({
  featureFlagsToFetch,
}: {
  featureFlagsToFetch: string[];
}) => {
  const { flagsReady } = useFlagsStatus();
  const unleashClient = useUnleashClient();
  const dispatch = useDispatch();

  useEffect(() => {
    if (flagsReady) {
      const featureFlags: Record<string, boolean> = featureFlagsToFetch.reduce(
        (flags, flag) => ({
          ...flags,
          [flag]: unleashClient.isEnabled(flag),
        }),
        {}
      );

      dispatch(updateFeatureFlags(featureFlags));
    }
  }, [flagsReady, featureFlagsToFetch, dispatch, unleashClient]);

  return null;
};
