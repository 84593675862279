import { useEffect, useRef, useState } from 'react';

export type Status = 'INITIAL' | 'LOADING' | 'OK' | 'ERROR';

export const TIMEOUT_DURATION = 8_000;

export interface UseWidgetTimeoutOptions {
  onLoadStarted?: (stopTimeout: () => void) => void;
  onError?: () => void;
}

export const useWidgetTimeout = (options: UseWidgetTimeoutOptions) => {
  const [status, setStatus] = useState<Status>('INITIAL');
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const cleanTimeoutRef = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  useEffect(() => {
    /**
     * Clear timeout on unmount
     */
    return () => {
      cleanTimeoutRef();
      setStatus('INITIAL');
    };
  }, []);

  const stopTimeout = () => {
    cleanTimeoutRef();
    setStatus('OK');
  };

  const resetTimeout = () => {
    cleanTimeoutRef();
    setStatus('INITIAL');
  };

  const setWidgetTimeout = async () => {
    if (timeoutRef.current) return;
    setStatus('LOADING');

    options.onLoadStarted && options.onLoadStarted(stopTimeout);

    timeoutRef.current = setTimeout(() => {
      setStatus('ERROR');
      options.onError && options.onError();
    }, TIMEOUT_DURATION);
  };

  /**
   * Expose _stopTimeout internal to make it easier to test
   * recovering from error states
   */
  return { setWidgetTimeout, status, resetTimeout, _stopTimeout: stopTimeout };
};
