import React from 'react';
import { connect } from 'react-redux';

import { useParams } from 'react-router-dom';

import View from './view';
import { AppState } from 'reducers';
import { answeredSpecifyQuestion } from 'actions';
import { conditionNameFromConditionId } from 'models/medicalConditions';
import { GeneralSectionId } from 'models/questions/general';
import {
  sectionIdFromKebabCaseSectionId,
  questionIdFromKebabCaseQuestionId,
} from 'routes';
import { MedicalHistoryQuestion } from 'models/questions/general/medicalHistory';
import { connectedRedirect } from 'components/connectedRedirect';

interface StateProps {
  conditionName: string;
  answer?: string;
}

interface DispatchProps {
  onAnswerQuestion: (answer: string) => void;
}

type OwnProps = {
  conditionId: number;
  questionId: MedicalHistoryQuestion['id'];
  sectionId: GeneralSectionId;
};

export type ViewProps = StateProps & DispatchProps;

const mapStateToProps = (
  state: AppState,
  { conditionId, questionId }: OwnProps
): StateProps => ({
  answer: state.specify[questionId]?.generic?.[conditionId]?.description,
  conditionName: conditionNameFromConditionId(conditionId),
});

const mapDispatchToProps = (
  dispatch: any,
  { conditionId, questionId, sectionId }: OwnProps
): DispatchProps => ({
  onAnswerQuestion: (answer: string) =>
    dispatch(
      answeredSpecifyQuestion(
        {
          type: 'specify',
          sectionId,
          questionId,
          conditionId,
        },
        answer
      )
    ),
});

export default () => {
  // TODO: Move this into a custom hook

  const {
    sectionId: kebabCaseSectionId,
    questionId: kebabCaseQuestionId,
    conditionId,
  } = useParams();

  const sectionId = sectionIdFromKebabCaseSectionId(kebabCaseSectionId);
  if (!sectionId) {
    throw new Error(
      `Couldn’t find corresponding section id for: :${kebabCaseSectionId}`
    );
  }

  const questionId = questionIdFromKebabCaseQuestionId(
    sectionId,
    kebabCaseQuestionId
  ) as MedicalHistoryQuestion['id'];

  if (!questionId) {
    throw new Error(
      `Couldn’t find corresponding question id for:${kebabCaseQuestionId}`
    );
  }

  const ConnectedView = connectedRedirect(
    { type: 'specify', sectionId, questionId, conditionId },
    connect<StateProps, DispatchProps, OwnProps, AppState>(
      mapStateToProps,
      mapDispatchToProps
    )(View)
  );
  return (
    <ConnectedView
      conditionId={conditionId}
      questionId={questionId}
      sectionId={sectionId}
    />
  );
};
