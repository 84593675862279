import React, { useState } from 'react';
import { useTranslation } from '@getpopsure/i18n-react';
import { Trans } from '@getpopsure/i18n-react';

import Center from 'components/center';
import styles from './style.module.scss';
import { InfoHintContainer, InfoHint } from 'components/infoHint';
import { ViewProps } from '..';

export default ({
  answer: previousAnswer,
  onAnswerQuestion,
  conditionName,
}: ViewProps) => {
  const [answer, setAnswer] = useState(previousAnswer ?? '');
  const { t } = useTranslation();

  const valid = answer.length > 0;
  return (
    <Center>
      <h4 className="p-p tc-primary-500 fw-bold">{conditionName}</h4>
      <h1 className="p-h1">
        {t(
          'questions.health.generic.title',
          'Would you explain this to us in a little more detail?'
        )}
      </h1>
      <InfoHintContainer className="mt8">
        <>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onAnswerQuestion(answer);
            }}
          >
            <textarea
              data-cy="specify-textarea"
              value={answer}
              className={`wmn5 ${styles.textarea}`}
              onChange={(e) => setAnswer(e.target.value)}
            />
            <div className="mt24">
              <button
                data-cy="specify-generic-condition-continue"
                className="p-btn--primary wmn3"
                type="submit"
                disabled={!valid}
              >
                {t('questions.health.generic.continue.action', 'Continue')}
              </button>
              <button
                type="button"
                onClick={() => {
                  onAnswerQuestion('');
                }}
                className={`p-btn--secondary wmn2 ${styles.skip}`}
              >
                {t('questions.health.generic.skip.action', 'Skip')}
              </button>
            </div>
          </form>
          <InfoHint
            title={t(
              'questions.health.generic.hint.title',
              'Here are some questions that might help you to explain'
            )}
          >
            <ul className={styles.list}>
              <Trans i18nKey="questions.health.generic.hint.description">
                <li>Did you receive treatment for this condition?</li>
                <li>How often does this occur?</li>
                <li>Is it completely healed? If yes, since when?</li>
                <li>Where you ever unable to work due to this?</li>
              </Trans>
            </ul>
          </InfoHint>
        </>
      </InfoHintContainer>
    </Center>
  );
};
