import React from 'react';
import { useTranslation } from '@getpopsure/i18n-react';
import { useHistory } from 'react-router-dom';

import Center from 'components/center';
import { Session } from 'utils/session';

import { ViewProps } from '..';

import styles from './styles.module.scss';

export default ({
  name,
  lastAnsweredQuestionUrl,
  onRestartQuestionnaire,
  children,
}: ViewProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const newSession = Session.isNewSession;

  const handleContinueClick = () => {
    Session.setSessionHasStarted();
    history.push(lastAnsweredQuestionUrl);
  };

  const handleRestartClick = () => {
    Session.setSessionHasStarted();
    onRestartQuestionnaire();
  };

  // If name is not available, the user has not answered any questions, so set "newSession" to false via setSessionHasStarted to make sure we don't show the
  // welcome back screen once they enter their name.
  if (!name) {
    Session.setSessionHasStarted();
  }

  if (name && newSession) {
    const { firstName, lastName } = name;

    return (
      <Center>
        <span className={styles.emoji} role="img" aria-label="">
          👋
        </span>
        <h1 className="p-h1 mt24">
          {t(
            'page.returninguser.title',
            'Welcome back, {{firstName}} {{lastName}}!',
            {
              firstName,
              lastName,
            }
          )}
        </h1>
        <p className="p-p mt8">
          {t(
            'page.returninguser.description',
            'Would you like to continue your previous application?'
          )}
        </p>
        <div className={`${styles['button-container']} mt24`}>
          <button
            data-cy="return-user-prompt-continue"
            className="p-btn--primary wmn3"
            onClick={handleContinueClick}
          >
            {t('page.returninguser.continue.action', 'Continue my application')}
          </button>
          <button
            data-cy="return-user-prompt-restart"
            className="p-btn p-btn--secondary wmn3"
            onClick={handleRestartClick}
          >
            {t('page.returninguser.reset.action', 'Begin new application')}
          </button>
        </div>
      </Center>
    );
  }

  return children;
};
