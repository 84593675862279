import { connect } from 'react-redux';

import { AppState } from 'reducers';
import View from './view';
import { getEmail } from 'selectors';
import Session from '@getpopsure/session';

interface StateProps {
  questionnaireId?: string;
  downloading: boolean;
  email?: string;
  fullName: string;
  authenticated: boolean;
}

const getFullName = (state: AppState) => {
  if (state.questionnaire.personalInfo?.name) {
    return `${state.questionnaire.personalInfo.name.firstName} ${state.questionnaire.personalInfo.name.lastName}`;
  }
  return `${state.remoteData.user?.firstName} ${state.remoteData.user?.lastName}`;
};

export type ViewProps = StateProps;

export const mapStateToProps = (state: AppState): StateProps => {
  const authenticated = Session.isAuthenticated;
  const email = getEmail(state);
  const fullName = getFullName(state);

  return {
    fullName,
    email,
    questionnaireId: state.remoteData.questionnaire?.id,
    downloading: false,
    authenticated,
  };
};

export default connect<StateProps, null, unknown, AppState>(mapStateToProps)(
  View
);
