import {
  DeductibleForTariff,
  Tariff,
} from '@getpopsure/private-health-insurance-pricing-engine';

import { AppState } from 'reducers';

import { Condition, treeIdFromConditionId } from 'models/medicalConditions';
import { Tree } from 'models/questions/specify';
import { treeLogicArray } from './treeLogic';
import { getTariffAndDeductible } from 'selectors/tariff';
import { noEmpty } from 'utils/types';
import {
  getQuestionnaireWithoutUnreachableQuestions,
  getQuestionsNeedInvestigation,
} from 'selectors';
import { getBMI } from 'selectors/blocker';
import { Question } from 'models/questions';
import { MedicalHistoryQuestion } from 'models/questions/general/medicalHistory';
import { RiskLevel } from 'models/price';
import day from 'dayjs';

import { sliceAtElement } from 'utils/sliceAtElement';
import { pickProperties } from 'utils/pickProperties';
import { SurchargeMatrix } from 'models/surcharge';

export const getInvestigatedConditions = (
  state: AppState
): { [x in MedicalHistoryQuestion['id']]?: Condition[] } => {
  const investigatedConditions = getQuestionsNeedInvestigation(
    state
  ) as string[];

  return Object.entries(state.investigation || {})
    .filter(([questionId]) => investigatedConditions.includes(questionId))
    .filter(noEmpty)
    .reduce(
      (a, [questionId, condition]) => ({ ...a, [questionId]: condition }),
      {} as { [x in MedicalHistoryQuestion['id']]: Condition[] }
    );
};

export const getSpecifyQuestionOrderForQuestionId = (
  state: AppState,
  questionId: MedicalHistoryQuestion['id']
): Question[] => {
  const investigatedConditions = getInvestigatedConditions(state)[questionId];
  const result: Question[] = [];

  investigatedConditions?.forEach(({ treeId, id: conditionId }) => {
    if (treeId === 'ZZ') {
      return;
    }

    if (treeId === null) {
      result.push({
        type: 'specify',
        sectionId: 'medicalHistory',
        questionId,
        conditionId,
      });
    } else {
      const answeredQuestions = getAnsweredQuestionsForCondition(
        state,
        questionId,
        conditionId
      );
      const treeLogic = treeLogicArray[treeId](state);
      const specifyQuestionIdOrder = treeLogic.getQuestionOrder(
        answeredQuestions
      ) as Tree['question']['id'][];

      for (const specifyQuestionId of specifyQuestionIdOrder) {
        result.push({
          type: 'specify',
          sectionId: 'medicalHistory',
          questionId,
          conditionId,
          specifyQuestionId,
        });
        const answeredQuestionUntilSpecifyQuestionId = pickProperties(
          answeredQuestions,
          ...sliceAtElement(specifyQuestionIdOrder, specifyQuestionId)
        );

        if (
          treeLogic.getAssess(answeredQuestionUntilSpecifyQuestionId) === true
        ) {
          break;
        }

        if (
          treeLogic.getRejection(answeredQuestionUntilSpecifyQuestionId) ===
          true
        ) {
          break;
        }
      }
    }
  });

  return result;
};

export const getAnsweredQuestionsForCondition = (
  state: AppState,
  questionId: MedicalHistoryQuestion['id'],
  conditionId: number
): any => {
  const treeId = treeIdFromConditionId(conditionId);
  if (treeId === null) {
    return state.specify[questionId]?.generic?.[conditionId];
  }

  if (treeId === 'ZZ') {
    return undefined;
  }

  const treeLogic = treeLogicArray[treeId](state);
  const treeQuestion = state.specify[questionId]?.[treeId]?.[conditionId] ?? {};
  const specifyQuestionOrder = treeLogic.getQuestionOrder(treeQuestion);

  let result = {};

  for (const specifyQuestionId of specifyQuestionOrder) {
    const answer = (treeQuestion as any)[specifyQuestionId];
    if (answer === undefined) {
      break;
    }

    result = { ...result, [specifyQuestionId]: answer };

    const answeredQuestionUntilSpecifyQuestionId = pickProperties(
      result as any,
      ...sliceAtElement(specifyQuestionOrder, specifyQuestionId)
    );

    if (treeLogic.getAssess(answeredQuestionUntilSpecifyQuestionId) === true) {
      break;
    }

    if (
      treeLogic.getRejection(answeredQuestionUntilSpecifyQuestionId) === true
    ) {
      break;
    }
  }

  return result;
};

export const getHasTextInvestigation = (
  state: AppState,
  investigatedConditions = getInvestigatedConditions(state),
  questionnaire = getQuestionnaireWithoutUnreachableQuestions(state)
): boolean => {
  if (
    questionnaire.medicalHistory?.planToUndergoMedicalTreatmentExplanation !==
      undefined ||
    questionnaire.medicalHistory?.missinBodyPartExplanation !== undefined ||
    questionnaire.medicalHistory?.sufferingFromConditionExplanation !==
      undefined ||
    questionnaire.medicalHistory?.hasTakenMedicationExplanation !== undefined
  ) {
    return true;
  }

  return (
    Object.values(investigatedConditions)
      .flatMap((c) => c)
      .find((c) => c?.treeId === null) !== undefined
  );
};

export const getRiskLevel = (
  state: AppState,
  conditions = Object.keys(getInvestigatedConditions(state)), // CHA-1341 https://linear.app/feather-insurance/issue/CHA-1341/display-whenever-a-customer-selects-a-condition
  conditionsWithSurcharge = getConditionsWithSurchargeForTariff(state),
  hasTreelessInvestigatedCondition = getHasTextInvestigation(state)
): { level: RiskLevel; reason?: string } => {
  const isStandardLight = state?.tariff?.tariff === 'HiMedical';
  const dateOfBirth = state?.questionnaire?.personalInfo?.dateOfBirth;
  const isOver51 = dateOfBirth && day().diff(day(dateOfBirth), 'years') > 51;

  if (isStandardLight && isOver51) {
    // https://linear.app/feather-insurance/issue/EMU-3909/risk-surcharge-on-hi-germany-for-customers-over-52
    return { level: 'X' };
  }

  if (conditions.length > 0) {
    // CHA-1341 https://linear.app/feather-insurance/issue/CHA-1341/display-whenever-a-customer-selects-a-condition
    return { level: 'X' };
  }

  const conditionsWithNonNullSurcharge = conditionsWithSurcharge.filter(
    (c) => c.surcharge > 0
  );

  if (state.questionnaire.medicalHistory?.unableToProduceChildren === true) {
    return { level: 'X' };
  }

  if (hasTreelessInvestigatedCondition === true) {
    return { level: 'X' };
  }

  if (conditionsWithSurcharge.length === 0) {
    return { level: 'RG0' };
  }

  if (conditionsWithSurcharge.length === 1) {
    if (conditionsWithNonNullSurcharge.length === 1) {
      return {
        level: 'RG1',
        reason: conditionsWithNonNullSurcharge[0].conditionName,
      };
    }

    return { level: 'RG0' };
  }

  return { level: 'X' };
};

export const getRiskFactor = (
  state: AppState,
  riskLevel = getRiskLevel(state),
  conditionsWithSurcharge = getConditionsWithSurchargeForTariff(state)
): number => {
  if (riskLevel.level === 'X') {
    return 0;
  }

  return conditionsWithSurcharge.reduce(
    (sum, value) => value.surcharge + sum,
    0
  );
};

function surchargeForTariff<T extends Tariff, D extends DeductibleForTariff<T>>(
  tariff: T,
  deductible: D,
  matrix: SurchargeMatrix
): number {
  return (matrix[tariff] as any)[deductible];
}

export const getConditionsWithSurchargeForTariff = (
  state: AppState,
  tariffAndDeductible = getTariffAndDeductible(state),
  bmi = getBMI(state),
  answers = getQuestionnaireWithoutUnreachableQuestions(state)
): { conditionName: string; surcharge: number }[] => {
  if (!tariffAndDeductible) {
    throw new Error(
      'Can’t compute condition with surcharge without tariff and deductible'
    );
  }

  const { tariff, deductible } = tariffAndDeductible;

  const surchargeFromSpecifyQuestions: {
    conditionName: string;
    surcharge: number;
  }[] = [];

  Object.entries(getInvestigatedConditions(state)).forEach(
    ([questionId, conditions]) => {
      (conditions ?? []).forEach(({ treeId, id, name }) => {
        if (!treeId || treeId === null || treeId === 'ZZ') {
          return;
        }

        const riskSurchargeMatrix = treeLogicArray[treeId](
          state
        ).getRiskSurchargeMatrix(
          getAnsweredQuestionsForCondition(
            state,
            questionId as MedicalHistoryQuestion['id'],
            id
          )
        );

        const surcharge =
          riskSurchargeMatrix &&
          surchargeForTariff(tariff, deductible, riskSurchargeMatrix);

        surchargeFromSpecifyQuestions.push({
          conditionName: name,
          surcharge: surcharge ? surcharge / 100 : 0,
        });
      });
    }
  );

  const surchargeFromMedicalQuestionnaire: ReturnType<
    typeof getConditionsWithSurchargeForTariff
  > = [];

  if (answers.medicalHistory?.maximumEyeDiopters === '8-10') {
    const visionSurchargeMatrix: SurchargeMatrix = {
      NK: { '0': 12, '300': 13, '600': 14, '1200': 16, '3000': 24 },
      NKSelectS: { '0': 12, '600': 14, '1200': 16, '3000': 24 },
      NKSelectL: { '0': 12, '600': 14, '1200': 16, '3000': 24 },
      NKSelectXL: { '0': 12, '600': 14, '1200': 16, '3000': 24 },
      KS: { '0': 14, '300': 13, '600': 15, '1200': 21 },
      Primo: { '0': 15, '300': 15, '600': 16, '1200': 20 },
      PrimoPlus: { '0': 12, '300': 12, '600': 13, '1200': 16 },
      HiMedical: { '1200': 62, '500': 35 },
      HiMedicalPlus: { '1200': 36, '500': 25 },
    };

    const surcharge = surchargeForTariff(
      tariff,
      deductible,
      visionSurchargeMatrix
    );

    surchargeFromMedicalQuestionnaire.push({
      conditionName: 'Vision',
      surcharge: surcharge / 100,
    });
  }

  if (bmi && bmi >= 30 && bmi < 32) {
    const bmiSurchargeMatrix: SurchargeMatrix = {
      NK: { '0': 19, '300': 21, '600': 23, '1200': 25, '3000': 37 },
      NKSelectS: { '0': 22, '600': 27, '1200': 29, '3000': 33 },
      NKSelectL: { '0': 19, '600': 21, '1200': 23, '3000': 27 },
      NKSelectXL: { '0': 19, '600': 21, '1200': 23, '3000': 27 },
      KS: { '0': 20, '300': 18, '600': 20, '1200': 28 },
      Primo: { '0': 22, '300': 22, '600': 23, '1200': 28 },
      PrimoPlus: { '0': 20, '300': 20, '600': 21, '1200': 26 },
      HiMedical: { '1200': 94, '500': 53 },
      HiMedicalPlus: { '1200': 57, '500': 40 },
    };

    const surcharge = surchargeForTariff(
      tariff,
      deductible,
      bmiSurchargeMatrix
    );

    surchargeFromMedicalQuestionnaire.push({
      conditionName: 'Weight',
      surcharge: surcharge / 100,
    });
  }

  return [
    ...surchargeFromSpecifyQuestions,
    ...surchargeFromMedicalQuestionnaire,
  ];
};

export const getSpecifyQuestionnaire = (state: AppState) => {
  return state.specify;
};
