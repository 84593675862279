import React from 'react';

import styles from './style.module.scss';

export const containerId = 'sticky-container';

export const showFooter = () => {
  const footer = document.getElementById(containerId);
  footer?.classList.add(styles['footer--display']);
  footer?.classList.remove(styles['footer--hide']);
};

export const hideFooter = () => {
  const footer = document.getElementById(containerId);
  footer?.classList.add(styles['footer--hide']);
  footer?.classList.remove(styles['footer--display']);
};

export default () => (
  <div id={containerId} className={`${styles.footer} wmx8`} />
);
