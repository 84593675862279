import React from 'react';
import { useTranslation, TranslationFn } from '@getpopsure/i18n-react';

import { WrappedForm } from 'pages/questions/formWrapper';
import { nonEmptyValidator } from 'validators';
import Center from 'components/center';
import { generateRadioInput } from 'pages/questions/generateInput';
import { InfoHint, InfoHintContainer } from 'components/infoHint';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';
import type { ResidencePermitStatus } from 'models/questions/general/personalInfo';

const statusMapping = (
  t: TranslationFn
): { [K in ResidencePermitStatus]: string } => ({
  HAVE: t(
    'questions.residencePermitStatus.mapping.have',
    'I have a residence permit'
  ),
  APPLIED: t(
    'questions.residencePermitStatus.mapping.applied',
    'I have applied for a residence permit'
  ),
  WILL_APPLY: t(
    'questions.residencePermitStatus.mapping.willapply',
    'I will apply for a residence permit'
  ),
});

export default () => {
  const { t } = useTranslation();

  return (
    <Center>
      <h1 className="p-h1">
        {generalQuestionsTitleMapping.residencePermitStatus?.(t)}
      </h1>
      <InfoHintContainer className="mt8">
        <WrappedForm
          validator={nonEmptyValidator}
          sectionId="personalInfo"
          questionId="residencePermitStatus"
          label={statusMapping(t)}
        >
          {({ value, setValue }) => (
            <>
              {generateRadioInput(
                'residencePermitStatus',
                value,
                statusMapping(t),
                setValue
              )}
            </>
          )}
        </WrappedForm>
        <InfoHint
          title={t(
            'questions.residencepermitstatus.hint.title',
            'What is a residence permit?'
          )}
        >
          {t(
            'questions.residencepermitstatus.hint.description',
            'A resident permit (German: Aufenthaltstitel) is a document issued to non-EU citizens living in Germany. A tourist visa allowing a stay for up to 90 days is not a residence permit.'
          )}
        </InfoHint>
      </InfoHintContainer>
    </Center>
  );
};
