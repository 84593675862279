import React from 'react';
import { useParams } from 'react-router-dom';
import {
  insurance,
  faq,
  calendly,
  app,
  website,
} from '@getpopsure/private-constants';
import { useTranslation } from '@getpopsure/i18n-react';
import { Trans } from '@getpopsure/i18n-react';
import shieldIcon from '../img/shield.svg';
import Center from 'components/center';
import { FriendlyBlockerUrl } from 'models/blockers';
import { useChat } from 'react-live-chat-loader';

import styles from './style.module.scss';

export const BlockerIcon = () => (
  <svg
    width="136"
    height="112"
    viewBox="0 0 136 112"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.246109 43.9995C-3.4232 90.9163 34.8004 112 64.9369 112C95.0734 112 134.246 86.6587 134.246 59.6692C134.246 32.6796 127.053 15.8729 96.7376 5.46562C66.4226 -4.94168 3.91542 -2.91726 0.246109 43.9995Z"
      fill="#F9F9FF"
    />
    <g clipPath="url(#clip0)">
      <path
        d="M34 40.4167C34 32.5926 40.3426 26.25 48.1667 26.25H87.8333C95.6574 26.25 102 32.5926 102 40.4167V60.25C102 68.074 95.6574 74.4167 87.8333 74.4167H48.1667C40.3426 74.4167 34 68.074 34 60.25V40.4167Z"
        className={styles['fill-primary-100']}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.3886 69.4529C56.5583 67.1889 59.501 65.917 62.5694 65.917H79.0972C82.1656 65.917 85.1084 67.1889 87.278 69.4529C89.4477 71.717 90.6667 74.7876 90.6667 77.9895V82.3011C90.6667 84.206 89.1867 85.7503 87.3611 85.7503C86.0089 85.7503 55.6578 85.7503 54.3056 85.7503C52.4799 85.7503 51 84.206 51 82.3011V77.9895C51 74.7876 52.2189 71.717 54.3886 69.4529Z"
        className={styles['fill-primary-500']}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.5 51.7503C59.5 45.4911 64.5741 40.417 70.8333 40.417C77.0926 40.417 82.1667 45.4911 82.1667 51.7503C82.1667 58.0096 77.0926 63.0837 70.8333 63.0837C64.5741 63.0837 59.5 58.0096 59.5 51.7503Z"
        className={styles['fill-primary-500']}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="68"
          height="68"
          fill="white"
          transform="translate(34 22)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default () => {
  const { blockerId }: { blockerId: FriendlyBlockerUrl } = useParams();
  const { t } = useTranslation();
  const [, loadChat] = useChat();
  const handleSendUsAMessageClick = () => {
    loadChat({ open: true });
  };

  const content: {
    [K in FriendlyBlockerUrl]: {
      title: string;
      description: JSX.Element;
      actions?: JSX.Element;
      showShieldIcon?: boolean;
    };
  } = {
    generic: {
      title: t('page.blocker.genericBlocker.title', 'We’re sorry…'),
      description: (
        <p className="p-p mt16">
          <Trans i18nKey="page.blocker.genericBlocker.description">
            We are working hard to make sure everyone can be covered, but, at
            the moment, you are not eligible for private health insurance.
            <br />
            <br />
            We’d like to help you get another health insurance. As your
            eligibility for different types of insurances depends on quite a few
            factors, we would suggest that you use our{' '}
            <a
              className="p-a"
              target="_blank"
              rel="noopener noreferrer"
              href={app.healthTool}
            >
              Recommendation Tool
            </a>{' '}
            to help you figure out which health insurance options are best for
            you based on your situation.
          </Trans>
        </p>
      ),
      actions: (
        <a
          className="p-btn--primary mt24 wmn3"
          target="_blank"
          rel="noopener noreferrer"
          href={app.healthTool}
        >
          {t('page.blocker.genericBlocker.action', 'Find Out')}
        </a>
      ),
    },
    'employed-outside-germany': {
      title: t('page.blocker.employedoutsidegermany.title', 'Unfortunately...'),
      description: (
        <div>
          <p className="p-p mt16">
            {t(
              'page.blocker.employedoutsidegermany.description.1',
              'You aren’t eligible for private health insurance. We are working hard to make sure everyone can be covered.'
            )}
          </p>
          <p className="p-p mt16">
            {t(
              'page.blocker.employedoutsidegermany.description.2',
              'Still, we might have some other options for you. You can try out our digital recommendation tool to determine which health insurance is the best option for you.'
            )}
          </p>
        </div>
      ),
      actions: (
        <div className="mt24">
          <div>
            <a
              className="p-btn--primary"
              target="_blank"
              rel="noopener noreferrer"
              href={app.recommendationTool}
            >
              {t(
                'page.blocker.employedoutsidegermany.action.recommendationtool',
                'Get a recommendation'
              )}
            </a>
          </div>
          <div className="mt16">
            <a
              className="p-btn--secondary"
              target="_blank"
              rel="noopener noreferrer"
              href={website.support}
            >
              {t(
                'page.blocker.employedoutsidegermany.action.support',
                'Speak to us'
              )}
            </a>
          </div>
        </div>
      ),
    },
    'not-in-germany': {
      showShieldIcon: true,
      title: t('page.blocker.notInGermany.title', 'Unfortunately…'),
      description: (
        <p className="p-p mt16">
          <Trans i18nKey="page.blocker.notInGermany.description">
            To sign up for a private health insurance policy, you are usually
            required to have an established residence in Germany on or before
            your coverage start date.
            <br />
            <br />
            However, we can still likely help you get signed up before your move
            to Germany. Please message or book a call with us so we can help you
            proceed!
          </Trans>{' '}
        </p>
      ),
      actions: (
        <div className="d-flex fd-column ai-center mt24">
          <button
            className="p-btn--primary wmn3"
            onClick={handleSendUsAMessageClick}
          >
            {t(
              'page.blocker.notInGermany.action.intercom',
              'Send us a message'
            )}
          </button>
          <button
            className="p-btn--secondary bg-white mt24 wmn3"
            onClick={() => {
              window.Calendly.initPopupWidget({
                url: calendly.healthInsuranceAdvicePriority,
              });
            }}
          >
            {t('page.blocker.notInGermany.action.bookACall', 'Book a call')}
          </button>
        </div>
      ),
    },
    other: {
      title: t('page.blocker.otherBlocker.title', 'We’re sorry…'),
      description: (
        <p className="p-p mt16">
          <Trans i18nKey="page.blocker.otherBlocker.description">
            With your current employment status, we aren’t able to provide you
            with private health insurance.
            <br />
            <br />
            However, we’d like to help you get another health insurance. As your
            eligibility for different types of insurances depends on quite a few
            factors, we would suggest that you use our{' '}
            <a
              className="p-a"
              target="_blank"
              rel="noopener noreferrer"
              href={app.healthTool}
            >
              Recommendation Tool
            </a>{' '}
            to help you figure out which health insurance options are best for
            you based on your situation.
          </Trans>
        </p>
      ),
      actions: (
        <a
          className="p-btn--primary mt24 wmn3"
          target="_blank"
          rel="noopener noreferrer"
          href={app.healthTool}
        >
          {t('page.blocker.otherBlocker.action', 'Find Out')}
        </a>
      ),
    },
    'pre-existing-condition': {
      title: t('page.blocker.preexisitingConditions.title', 'We’re sorry…'),
      description: (
        <p className="p-p mt16">
          <Trans i18nKey="page.blocker.preexisitingConditions.description">
            Due to a preexisting medical condition, we cannot sign you up for
            private health insurance. You can read more about{' '}
            <a
              className="p-a"
              target="_blank"
              rel="noopener noreferrer"
              href={faq.privateHealthPreExistingConditions}
            >
              how pre-existing medical conditions affect your insurance choices
            </a>
            .
            <br />
            <br />
            We may, however, have other solutions for you. We’d suggest using
            our{' '}
            <a
              className="p-a"
              target="_blank"
              rel="noopener noreferrer"
              href={app.healthTool}
            >
              Recommendation Tool
            </a>{' '}
            to help you figure out which health insurance options are best for
            you based on your situation.
          </Trans>
        </p>
      ),
      actions: (
        <a
          className="p-btn--primary mt24 wmn3"
          target="_blank"
          rel="noopener noreferrer"
          href={app.healthTool}
        >
          {t('page.blocker.preexisitingConditions.action', 'Find Out')}
        </a>
      ),
    },
    'no-short-term-tariff': {
      title: t(
        'page.blocker.ineligibleForShortTerm.title',
        'Unfortunately, you’re not eligible for our short-term tariff'
      ),
      description: (
        <p className="p-p mt16">
          <Trans i18nKey="page.blocker.ineligibleForShortTerm.description">
            The short-term tariff is only available for non-EU/EEA citizens who
            lived in Germany for less than 5 years. Given your answers, we can
            only offer you the long-term tariff.
          </Trans>
        </p>
      ),
      actions: (
        <a
          className="p-btn--primary mt24 wmn3"
          target="_blank"
          rel="noopener noreferrer"
          href={`${insurance.privateHealth.preSignup}/signup/quote?eligibleForShortTerm=false`}
        >
          {t(
            'page.blocker.ineligibleForShortTerm.action',
            'See long-term tariff'
          )}
        </a>
      ),
    },
  };

  const renderActions = () => {
    if (content[blockerId].actions) return content[blockerId].actions;

    return (
      <button
        className="p-btn--primary mt24 wmn3"
        onClick={() => {
          window.Calendly.initPopupWidget({ url: calendly.genericHelp });
        }}
      >
        {t('page.blocker.contact.action', 'Speak to us')}
      </button>
    );
  };

  return (
    <Center>
      <div className={`wmx8 ${styles.container}`}>
        {!content[blockerId].showShieldIcon ? (
          <BlockerIcon />
        ) : (
          <img src={shieldIcon} alt="shield icon" />
        )}
        <h1 className="p-h1 mt16">{content[blockerId].title}</h1>
        {content[blockerId].description}
        {renderActions()}
      </div>
    </Center>
  );
};
