import React from 'react';
import { useTranslation } from '@getpopsure/i18n-react';

import { WrappedForm } from 'pages/questions/formWrapper';
import { generateCheckboxInput } from 'pages/questions/generateInput';
import { nonEmptyArrayValidator } from 'validators';
import Center from 'components/center';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';
import {
  SufferingMedicalCondition,
  sufferingMedicalConditionMapping,
} from 'models/questions/general/medicalHistory';

export default () => {
  const { t } = useTranslation();

  return (
    <Center>
      <WrappedForm
        validator={nonEmptyArrayValidator}
        sectionId="medicalHistory"
        questionId="sufferingFromCondition"
        label={sufferingMedicalConditionMapping(t)}
      >
        {({ value, setValue }) => (
          <>
            <h1 className="p-h1">
              {generalQuestionsTitleMapping.sufferingFromCondition?.(t)}
            </h1>
            <div className="mt8">
              {generateCheckboxInput(
                'sufferingFromCondition',
                value,
                sufferingMedicalConditionMapping(t),
                (updated: SufferingMedicalCondition[]) => {
                  const diff = updated.filter((x) => !value?.includes(x));
                  let newValue: SufferingMedicalCondition[];
                  if (diff.includes('NONE')) {
                    newValue = ['NONE'];
                  } else {
                    newValue = updated.filter((x) => x !== 'NONE');
                  }

                  setValue(newValue);
                }
              )}
            </div>
          </>
        )}
      </WrappedForm>
    </Center>
  );
};
