import React from 'react';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';

import './style.scss';
const GERMAN_PHONE_NO_MASK = '... ... ..........';

const PhoneInputComp = (props: PhoneInputProps) => (
  <PhoneInput
    {...props}
    enableLongNumbers={true}
    defaultMask={GERMAN_PHONE_NO_MASK}
    alwaysDefaultMask
    placeholder="49 211 5684962"
  />
);

export default PhoneInputComp;
