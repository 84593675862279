import React from 'react';

import { WrappedForm } from 'pages/questions/formWrapper';
import { nonEmptyStringValidator } from 'validators';
import Center from 'components/center';
import { QuestionIdInSectionId } from 'models/questions/general';

import styles from './style.module.scss';

export default ({
  title,
  questionId,
  explanationFor,
}: {
  title: string;
  questionId: QuestionIdInSectionId<'medicalHistory'>;
  explanationFor: string;
}) => (
  <Center>
    <WrappedForm
      validator={nonEmptyStringValidator as any}
      sectionId="medicalHistory"
      questionId={questionId}
    >
      {({ value, setValue }) => (
        <>
          <h4 className="p-p tc-primary-500 fw-bold">{explanationFor}</h4>
          <h1 className="p-h1 mt8">{title}</h1>
          <textarea
            data-cy="questionnaire-text-investigate"
            className={`mt8 wmn6 d-block ${styles['text-area']}`}
            value={value as string}
            onChange={(e) => setValue(e.target.value)}
            required={true}
          />
        </>
      )}
    </WrappedForm>
  </Center>
);
