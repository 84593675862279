import {
  SpecifyQuestionnaireModel,
  QuestionType,
} from 'models/questions/specify';
import { StringOrNever } from 'utils/types';

const careEnumMapping = {
  IN_PATIENT: 'In patient',
  OUT_PATIENT: 'Out patient',
  NO_TREATMENT_REQUIRED: 'No treatment required',
};

const uploadMedicalDischarge: {
  title: string;
  questionType: QuestionType<never>;
} = {
  title: 'Please submit a hospital discharge report from the hospital',
  questionType: { type: 'file' },
};

export const specifyQuestionsTitleMapping: {
  [T in keyof SpecifyQuestionnaireModel]: {
    [U in keyof SpecifyQuestionnaireModel[T]]: {
      title: string;
      questionType: QuestionType<
        StringOrNever<SpecifyQuestionnaireModel[T][U]>
      >;
    };
  };
} = {
  A: {
    whatKindOfCareRequired: {
      title: 'What kind of care did you require for this condition?',
      questionType: {
        type: 'enum',
        mapping: careEnumMapping,
      },
    },
    hasBeenDeemedUnfitToWork: {
      title:
        'Have you ever been deemed unfit to work for more than 7 days due to this condition?',
      questionType: { type: 'boolean' },
    },
    uploadMedicalDischarge,
    exclusionOfCoverage: {
      title: 'Before we continue…',
      questionType: {
        type: 'legal',
        text:
          'By selecting "Continue", I agree there is no coverage for certain treatments related to allergies. Specifically, allergen immunotherapy treatments (hyposensitization, desensitization, induction of hyposensitivity), specific immunotherapy (SIT), sublingual immunotherapy (SLIT), or other similar treatments (e.g. grass immunology tablets). All other forms of treatment are covered. Additionally, I confirm to have never suffered from bronchial asthma.',
      },
    },
  },
  B: {
    whatKindOfCareRequired: {
      title: 'What kind of care did you require for this condition?',
      questionType: {
        type: 'enum',
        mapping: careEnumMapping,
      },
    },
    hasBeenDeemedUnfitToWork: {
      title:
        'Have you ever been deemed unfit to work for more than 7 days due to this condition?',
      questionType: { type: 'boolean' },
    },
    uploadMedicalDischarge,
  },
  C: {
    whatKindOfCareRequired: {
      title: 'What kind of care did you require for this condition?',
      questionType: {
        type: 'enum',
        mapping: careEnumMapping,
      },
    },
    uploadMedicalDischarge,
  },
  D: {
    requiredSurgicalIntervention: {
      title: 'Did you require a surgical intervention for this condition?',
      questionType: { type: 'boolean' },
    },
    recoveryPeriodLastedLongerThan28days: {
      title: 'Did your recovery period last longer than 28 days?',
      questionType: { type: 'boolean' },
    },
    hasFullyRecovered: {
      title: 'Has this disease completely healed?',
      questionType: { type: 'boolean' },
    },
    howManyTimeRequiredRecoveryTime: {
      title:
        'In the last three years, how many times have you experienced this issue?',
      questionType: {
        type: 'enum',
        mapping: {
          ONCE: 'Once',
          TWICE: 'Twice',
          THREE_OR_MORE: 'Three times or more',
          NONE: 'None',
        },
      },
    },
    recoveryLastedMoreThan14days: {
      title: 'Did your recovery period last longer than 14 days?',
      questionType: { type: 'boolean' },
    },
    recoveryLastedMoreThan28days: {
      title: 'Did your recovery period last longer than 28 days?',
      questionType: { type: 'boolean' },
    },
  },
  E: {
    whatKindOfCareRequired: {
      title: 'What kind of care did you require for this condition?',
      questionType: {
        type: 'enum',
        mapping: careEnumMapping,
      },
    },
    hasFullyRecovered: {
      title: 'Have you fully recovered from this condition?',
      questionType: { type: 'boolean' },
    },
    uploadMedicalDischarge,
    hasBeenDeemedUnfitToCarryOutDailyActivities: {
      title:
        'Have you been deemed unfit to carry out daily activities (e.g. attend school) for more than 28 days at a time?',
      questionType: { type: 'boolean' },
    },
    howManyTimesHasFallenIllWithBronchitisOlderThan8: {
      title:
        'In the last three years, how many times have you fallen ill with this condition?',
      questionType: {
        type: 'enum',
        mapping: {
          ONE_TIME: '1 time',
          TWO_TIMES: '2 times',
          THREE_TIMES: '3 times',
          FOUR_TIMES_OR_MORE: '4 times or more',
        },
      },
    },
    howManyTimesHasFallenIllWithBronchitisYoungerThan8: {
      title:
        'In the last three years, how many times have you fallen ill with this condition?',
      questionType: {
        type: 'enum',
        mapping: {
          ONE_TIME: '1 time',
          TWO_TIMES: '2 times',
          THREE_FOUR_TIMES: '3 or 4 times',
          FIVE_TIMES_OR_MORE: '5 times or more',
        },
      },
    },
  },
  FGH: {
    hasBeenTreatedForWeightRelatedIssues: {
      title: 'Have you been treated for weight-related issues?',
      questionType: {
        type: 'enum',
        mapping: careEnumMapping,
      },
    },
    uploadMedicalDischarge,
    hasThereBeenADiagnosis: {
      title: 'Have you received a diagnosis of being over- or underweight?',
      questionType: { type: 'boolean' },
    },
  },
  I: {
    hasReceivedTreatmentForFluLikeIllness: {
      title:
        'Have you ever received treatment for a flu-like illness? If so, what type of treatment?',
      questionType: {
        type: 'enum',
        mapping: careEnumMapping,
      },
    },
    uploadMedicalDischarge,
    hasFullyRecovered: {
      title: 'Have you fully recovered from this condition? ',
      questionType: { type: 'boolean' },
    },
    hasBeenDeemedUnfitToWork: {
      title:
        'In the last three years, have you been deemed unfit to work for more than a period of 42 days due to this condition?',
      questionType: { type: 'boolean' },
    },
  },
  J: {
    hasReceivedTreatmentForInfluenzaLikeIllness: {
      title:
        'Have you ever received treatment for this condition? If so, what type of treatment?',
      questionType: {
        type: 'enum',
        mapping: careEnumMapping,
      },
    },
    uploadMedicalDischarge,
    hasFullyRecovered: {
      title: 'Have you fully recovered from this condition? ',
      questionType: { type: 'boolean' },
    },
    hasBeenDeemedUnfitToWork: {
      title:
        'In the last three years, have you been deemed unfit to work for more than a period of 21 days due to this condition?',
      questionType: { type: 'boolean' },
    },
  },
  K: {
    hasReceivedTreatmentForSoreThroat: {
      title:
        'Have you ever received treatment for a sore throat? If so, what type of treatment?',
      questionType: {
        type: 'enum',
        mapping: careEnumMapping,
      },
    },
    hasFullyRecovered: {
      title: 'Have you fully recovered from this condition?',
      questionType: { type: 'boolean' },
    },
    howManyTimesHasReceivedTreatment: {
      title:
        'In the last three years, how often have you received treatment for this condition?',
      questionType: {
        type: 'enum',
        mapping: {
          ONCE: 'Once',
          TWICE: 'Twice',
          THREE_TIMES_OR_MORE: 'Three times or more',
        },
      },
    },
    hasBeenDeemedUnfitToWorkForMoreThan7Days: {
      title:
        'Have you been deemed unfit to work for a period of longer than 7 days due to this condition?',
      questionType: { type: 'boolean' },
    },
    howManyTimesHasReceivedTreatmentForSoreThroat: {
      title:
        'In the last three years, how often have you received treatment for this condition?',
      questionType: {
        type: 'enum',
        mapping: {
          LESS_THAN_THREE_TIMES: 'Less than three times',
          FOUR_TIMES_OR_MORE: 'Four times or more',
        },
      },
    },
    uploadMedicalDischarge,
  },
  L: {
    hasReceivedTreatmentForEarCanalInfection: {
      title:
        'In the last three years, have you received treatment for this condition? If so, what kind?',
      questionType: {
        type: 'enum',
        mapping: careEnumMapping,
      },
    },
    uploadMedicalDischarge,
    typeOfIllness: {
      title: 'Was this a chronic illness or an acute illness?',
      questionType: {
        type: 'enum',
        mapping: { ACUTE: 'Acute illness', CHRONIC: 'Chronic illness' },
      },
    },
    howManyTimesHasSufferedFromEarCanalInfection: {
      title:
        'In the last three years, how many times have you suffered from this condition? ',
      questionType: {
        type: 'enum',
        mapping: {
          ONCE: 'Once',
          TWICE: 'Twice',
          THREE_TIMES_OR_MORE: 'Three times or more',
        },
      },
    },
    hasBeenCuredOfTheInfection: {
      title: 'Have you been cured of the infection?',
      questionType: { type: 'boolean' },
    },
    hasBeenDeemedUnfitToWorkForMoreThan28Days: {
      title:
        'In the last three years, have you been deemed unfit to work for longer than a period of 28 days due to this condition?',
      questionType: { type: 'boolean' },
    },
    hasBeenDeemedUnfitToWorkForMoreThan42Days: {
      title:
        'In the last three years, have you been deemed unfit to work for longer than a period of 42 days due to this condition?',
      questionType: { type: 'boolean' },
    },
  },
  M: {
    hasReceivedTreatmentForSinusInfection: {
      title:
        'In the last three years, have you received treatment for this condition? If so, what kind?',
      questionType: { type: 'enum', mapping: careEnumMapping },
    },
    typeOfIllness: {
      title: 'Was this a chronic illness or an acute illness?',
      questionType: {
        type: 'enum',
        mapping: { ACUTE: 'Acute illness', CHRONIC: 'Chronic illness' },
      },
    },
    howManyTimeHasSufferedFromEarCanalInfection: {
      title:
        'In the last three years, how many times have you suffered from this condition? ',
      questionType: {
        type: 'enum',
        mapping: {
          ONCE: 'Once',
          TWICE: 'Twice',
          THREE_TIMES_OR_MORE: 'Three times or more',
        },
      },
    },
    hasBeenCuredOfTheInfection: {
      title: 'Have you been cured of the infection?',
      questionType: { type: 'boolean' },
    },
    hasBeenDeemedUnfitToWorkMoreThan35Days: {
      title:
        'In the last three years, have you been deemed unfit to work for longer than a period of 35 days due to this condition?',
      questionType: { type: 'boolean' },
    },
    hasBeenDeemedUnfitToWorkMoreThan21Days: {
      title:
        'In the last three years, have you been deemed unfit to work for longer than a period of 21 days due to this condition?',
      questionType: { type: 'boolean' },
    },
    uploadMedicalDischarge,
  },
  N: {
    hasReceivedTreatmentForHypotension: {
      title:
        'In the last three years, have you received treatment for this condition? If so, what type of treatment?',
      questionType: { type: 'enum', mapping: careEnumMapping },
    },
    hasBeenCuredOfCondition: {
      title: 'Have you been cured of this condition?',
      questionType: { type: 'boolean' },
    },
    howOftenHasSufferedFromHypotension: {
      title:
        'In the last three years, how often have you suffered from this condition? ',
      questionType: {
        type: 'enum',
        mapping: {
          ONCE: 'Once',
          TWICE: 'Twice',
          THREE_TIMES: 'Three times',
          FOUR_TIMES_OR_MORE: 'Four times or more',
        },
      },
    },
    hasBeenDeemedUnfitToWorkMoreThan7Days: {
      title:
        'In the last three years, have you been declared unfit to work for more than a period of 7 days due to this condition?',
      questionType: { type: 'boolean' },
    },
    hasBeenDeemedUnfitToWorkMoreThan12Days: {
      title:
        'In the last three years, have you been declared unfit to work for more than a period of 12 days due to this condition?',
      questionType: { type: 'boolean' },
    },
    hasBeenDeemedUnfitToWorkMoreThan18Days: {
      title:
        'In the last three years, have you been declared unfit to work for more than a period of 18 days due to this condition?',
      questionType: { type: 'boolean' },
    },
    hasBeenDeemedUnfitToWorkMoreThan25Days: {
      title:
        'In the last three years, have you been declared unfit to work for more than a period of 25 days due to this condition?',
      questionType: { type: 'boolean' },
    },
    uploadMedicalDischarge,
  },
  O: {
    whichCruciateLigamentHasBeenInjured: {
      title: 'Which cruciate ligaments have been injured?',
      questionType: {
        type: 'enum',
        mapping: {
          ANTERIOR_AND_POSTERIOR: 'Anterior and posterior',
          ANTERIOR: 'Anterior',
          POSTERIOR: 'Posterior',
        },
      },
    },
    hasFullyHealed: {
      title: 'Has the injury fully healed?',
      questionType: {
        type: 'boolean',
      },
    },
    recoveryTime: {
      title: 'Recovery time',
      questionType: {
        type: 'enum',
        mapping: {
          MORE_THAN_6_MONTHS: 'More than 6 months',
          '6_MONTHS_OR_LESS': '6 months or less',
        },
      },
    },
    requiredAnOperation: {
      title: 'Did you require an operation?',
      questionType: { type: 'boolean' },
    },
    sufferedFromMeniscusDamage: {
      title: 'Did you suffer from meniscus damage?',
      questionType: { type: 'boolean' },
    },
    deemedUnfitToWorkForLongerThan180Days: {
      title:
        'In the past 3 years, were you deemed unfit to work for longer than 180 days due to this injury?',
      questionType: { type: 'boolean' },
    },
  },
  P: {
    whatKindOfCareRequired: {
      title:
        'In the last three years, have you required medical care for this condition? If so, what kind?',
      questionType: { type: 'enum', mapping: careEnumMapping },
    },
    hasFullyRecovered: {
      title: 'Have you fully healed from this illness?',
      questionType: { type: 'boolean' },
    },
    hasBeenDeclaredUnfitToWorkLongerThan28Days: {
      title:
        'In the last three years, have you been declared unfit to work for more than 28 days due to this illness?',
      questionType: { type: 'boolean' },
    },
    uploadMedicalDischarge,
  },
  Q: {
    hasSufferedFromDegenerativeJoinDiseaseInTheKnee: {
      title:
        'In the last three years, have you suffered from degenerative joint disease in the knee? (Knee osteoarthritis)',
      questionType: { type: 'boolean' },
    },
    isPerformingActivitiesThatPlaceStressOnKnee: {
      title:
        'Do you perform activities (work-related or otherwise) that place stress on the knee? ',
      questionType: { type: 'boolean' },
    },
    isOverweight: {
      title: 'Are you overweight?',
      questionType: { type: 'boolean' },
    },
    isFullyHealed: {
      title: 'Have you fully healed?',
      questionType: { type: 'boolean' },
    },
    isConditionAffectingBothKnees: {
      title: 'Does this condition affect both knees?',
      questionType: { type: 'boolean' },
    },
    treatmentRequired: {
      title: 'What kind of treatment was required?',
      questionType: {
        type: 'enum',
        mapping: {
          MENISCAL_SUTURE: 'Meniscal suture',
          PARTIAL_REMOVAL_OF_MENISCUS: 'Partial removal of meniscus',
          FULL_REMOVAL_OF_MENISCUS: 'Full removal of meniscus',
          OTHER: 'Other procedure',
          NO_TREATMENT: 'No treatment',
        },
      },
    },
    hasBeenDeemedUnfitToWorkMoreThan56Days: {
      title:
        'In the last three years, have you been deemed unfit to work for more than 56 days due to this condition?',
      questionType: { type: 'boolean' },
    },
  },
  R: {
    hasBeenTreatedForPharyngitis: {
      title:
        'In the last three years, have you been treated for this condition? if so, what kind of treatment?',
      questionType: { type: 'enum', mapping: careEnumMapping },
    },
    hasFullyRecovered: {
      title: 'Have you fully recovered?',
      questionType: { type: 'boolean' },
    },
    howManyTimesHasSufferedFromPharyngitis: {
      title:
        'In the last three years, how many times have you suffered from this condition?',
      questionType: {
        type: 'enum',
        mapping: {
          ONCE: 'Once',
          TWICE: 'Twice',
          THREE_TIMES: 'Three times',
          FOUR_TIMES_OR_MORE: 'Four times or more',
        },
      },
    },
    hasBeenDeemedUnfitToWorkForLongerThan14Days: {
      title:
        'In the last three years, have you been declared unfit to work for more than 14 days due to this condition?',
      questionType: { type: 'boolean' },
    },
    hasBeenDeemedUnfitToWorkForLongerThan28Days: {
      title:
        'In the last three years, have you been declared unfit to work for more than 28 days due to this condition?',
      questionType: { type: 'boolean' },
    },
    uploadMedicalDischarge,
  },
  S: {
    hasReceivedTreatmentForHypothyroidism: {
      title:
        'In the last 3 years, have you received treatment for this condition. If so, what kind of treatment?',
      questionType: { type: 'enum', mapping: careEnumMapping },
    },
    hasHadOperation: {
      title: 'Have you had an operation on your thyroid?',
      questionType: { type: 'boolean' },
    },
    hasSufferedFromEnlargedThyroid: {
      title: 'Have you suffered from an enlarged thyroid? (e.g. goiter)',
      questionType: { type: 'boolean' },
    },
    hasSufferedFromThyroidNodules: {
      title: 'Have you had thyroid nodules?',
      questionType: { type: 'boolean' },
    },
    hasHadThyroidCysts: {
      title: 'Have you had thyroid cysts?',
      questionType: { type: 'boolean' },
    },
    hasBeenDeclaredUnfitToWork: {
      title:
        'In the last three years, have you been declared unfit to work for more than a period of 4 days due to this condition?',
      questionType: { type: 'boolean' },
    },
    uploadMedicalDischarge,
  },
  T: {
    hasReceivedTreatmentForHypothyroidism: {
      title:
        'In the last 3 years, have you received treatment for this condition. If so, what kind of treatment?',
      questionType: { type: 'enum', mapping: careEnumMapping },
    },
    hasHadOperation: {
      title: 'Have you had an operation on your thyroid?',
      questionType: { type: 'boolean' },
    },
    hasSufferedFromEnlargedThyroid: {
      title: 'Have you suffered from an enlarged thyroid? (e.g. goiter)',
      questionType: { type: 'boolean' },
    },
    hasSufferedFromThyroidNodules: {
      title: 'Have you had thyroid nodules?',
      questionType: { type: 'boolean' },
    },
    hasHadThyroidCysts: {
      title: 'Have you had thyroid cysts?',
      questionType: { type: 'boolean' },
    },
    hasBeenDeclaredUnfitToWork: {
      title:
        'In the last three years, have you been declared unfit to work for more than a period of 7 days due to this condition?',
      questionType: { type: 'boolean' },
    },
    uploadMedicalDischarge,
  },
  U: {
    hadReceivedVisionCare: {
      title:
        'In the last 3 years, have you received any kind of vision care? If so, what kind?',
      questionType: { type: 'enum', mapping: careEnumMapping },
    },
    hadOperationRelatedToVision: {
      title: 'Have you had an operation related to your vision?',
      questionType: { type: 'boolean' },
    },
    hasDiopterBetween10And15: {
      title: 'Do you have a diopter >+/- 10.00 ≤ +/-15.00',
      questionType: { type: 'boolean' },
    },
    hasDiopterBetween8And10: {
      title: 'Do you have a diopter ≥+/- 8.00 ≤ +/-10.00',
      questionType: { type: 'boolean' },
    },
    hasDiopterGreaterThan15: {
      title:
        'Do you have a diopter of greater than +/- 15.00 in either your left or right eye?',
      questionType: { type: 'boolean' },
    },
    uploadMedicalDischarge,
  },
  V: {
    hasReceivedTreatmentForTear: {
      title: 'In the last 3 years, have you been treated for this injury? ',
      questionType: { type: 'enum', mapping: careEnumMapping },
    },
    hasFullyRecovered: {
      title: 'Have you fully recovered from this injury?',
      questionType: { type: 'boolean' },
    },
    hasBeenDeclaredUnfitToWorkForMoreThan180Days: {
      title:
        'Were you declared unfit to work for more than a period of 180 days due to this injury?',
      questionType: { type: 'boolean' },
    },
    uploadMedicalDischarge,
  },
  W: {
    hasReceivedTreatmetnForSprainOrBruisingInjury: {
      title:
        'In the last 3 years, have you received treatment for an injury involving sprain/bruising? If so, what kind?',
      questionType: { type: 'enum', mapping: careEnumMapping },
    },
    hasFullyRecovered: {
      title: 'Have you fully recovered from this injury?',
      questionType: { type: 'boolean' },
    },
    hasBeenDeclaredUnfitToWorkForMoreThan28Days: {
      title:
        'In the last three years, have you been declared unfit to work for more than 28 days due to this injury? ',
      questionType: { type: 'boolean' },
    },
    uploadMedicalDischarge,
  },
  X: {
    hasReceivedTreatmentForSprainOrBruisingInjuryToSpine: {
      title:
        'In the last 3 years, have you received treatment for this injury?',
      questionType: { type: 'enum', mapping: careEnumMapping },
    },
    hasFullyRecovered: {
      title: 'Have you fully recovered from this injury?',
      questionType: { type: 'boolean' },
    },
    hasBeenDeclaredUnfitToWorkForMoreThan28Days: {
      title:
        'In the last three years, have you been declared unfit to work for more than 28 days due to this injury? ',
      questionType: { type: 'boolean' },
    },
    uploadMedicalDischarge,
  },
  Y: {
    hasReceivedTreatmetnForSprainOrBruisingInjuryToKnee: {
      title:
        'In the last 3 years, have you received treatment for this injury?',
      questionType: { type: 'enum', mapping: careEnumMapping },
    },
    hasFullyRecovered: {
      title: 'Have you fully recovered from this injury?',
      questionType: { type: 'boolean' },
    },
    hasBeenDeclaredUnfitToWorkForMoreThan28Days: {
      title:
        'In the last three years, have you been declared unfit to work for more than 28 days due to this injury? ',
      questionType: { type: 'boolean' },
    },
    uploadMedicalDischarge,
  },
  Z: {
    hadPreventiveCareAppointmentMoreThanTwiceAYear: {
      title:
        'In the last three years, have you had preventive care appointments more than twice a year?',
      questionType: { type: 'boolean' },
    },
    appointmentHadRaisedFindings: {
      title:
        'Have your preventive care appointments raised any unusual findings?',
      questionType: { type: 'boolean' },
    },
    examinationReports: {
      title: 'Please submit an examination report',
      questionType: { type: 'file' },
    },
  },
  AA: {
    hasRequiredPreventiveEyeCare: {
      title:
        'In the last three years, have you required preventive eye care more than twice a year?',
      questionType: { type: 'boolean' },
    },
    preventiveCareRaisedFindings: {
      title:
        'Have your preventive care appointments raised any unusual findings?',
      questionType: { type: 'boolean' },
    },
    examinationReports: {
      title: 'Please submit an examination report',
      questionType: { type: 'file' },
    },
  },
  AB: {
    hasRequiredPreventiveGynecologicalCare: {
      title:
        'In the last three years, have you required preventive gynecological care more than twice a year?',
      questionType: { type: 'boolean' },
    },
    preventiveCareRaisedFindings: {
      title:
        'Have your preventive care appointments raised any unusual findings?',
      questionType: { type: 'boolean' },
    },
    examinationReports: {
      title: 'Please submit an examination report',
      questionType: { type: 'file' },
    },
  },
  AC: {
    hasRequiredPreventiveSkinCare: {
      title:
        'In the last three years, have you required preventive skin care more than twice a year?',
      questionType: { type: 'boolean' },
    },
    preventiveCareRaisedFindings: {
      title:
        'Have your preventive care appointments raised any unusual findings?',
      questionType: { type: 'boolean' },
    },
    examinationReports: {
      title: 'Please submit an examination report',
      questionType: { type: 'file' },
    },
  },
  AD: {
    hasRoutineDentalCheckUpRaisedFindings: {
      title: 'Have your routine dental check-ups raised any unusual findings?',
      questionType: { type: 'boolean' },
    },
    examinationReports: {
      title: 'Please submit an examination report',
      questionType: { type: 'file' },
    },
  },
  AE: {
    hasEmploymentExaminationRaisedFindings: {
      title:
        'Have your employment physical examinations raised any unusual findings?',
      questionType: { type: 'boolean' },
    },
    examinationReports: {
      title: 'Please submit an examination report',
      questionType: { type: 'file' },
    },
  },
  AF: {
    hasCheckUpRaisedFindings: {
      title: 'Has a check-up raised any unusual findings?',
      questionType: { type: 'boolean' },
    },
    examinationReports: {
      title: 'Please submit an examination report',
      questionType: { type: 'file' },
    },
  },
  AG: {
    hasBeenTreatedForSpineIssues: {
      title:
        'In the last three years, have you been treated for any upper or lower spine issues? If so, what kind of treatment?',
      questionType: {
        type: 'enum',
        mapping: {
          IN_PATIENT: 'In patient',
          OUT_PATIENT: 'Out patient',
        },
      },
    },
    hasFullyHealed: {
      title: 'Have you fully healed from this condition?',
      questionType: { type: 'boolean' },
    },
    sinceWhenIsFullyHealed: {
      title: 'Since when is this condition fully healed?',
      questionType: {
        type: 'enum',
        mapping: {
          '12_MONTHS_OR_LESS': '12 months or less',
          MORE_THAN_12_MONTHS: 'More than 12 months',
        },
      },
    },
    howManyTimesHasExperiencedThisIssue: {
      title:
        'In the last three years, how many times have you experienced this issue?',
      questionType: {
        type: 'enum',
        mapping: {
          '1_TIME': 'Once',
          '2_TIMES': 'Twice',
          '3_TIMES_OR_MORE': 'Three times or more',
        },
      },
    },
    hasBeenDeclaredUnfitToWorkForMoreThan14Days: {
      title:
        'In the last three years, have you been declared unfit to work for more than a period of 14 days? ',
      questionType: { type: 'boolean' },
    },
    hasBeenDeclaredUnfitToWorkForMoreThan28Days: {
      title:
        'In the last three years, have you been declared unfit to work for more than a period of 28 days?',
      questionType: { type: 'boolean' },
    },
    examinationReports: {
      title: 'Please submit an examination report',
      questionType: { type: 'file' },
    },
  },
};
