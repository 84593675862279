import classNames from 'classnames';
import React, { useState } from 'react';

import { CategoryName, Condition } from 'models/medicalConditions';
import ConditionTablet from '../conditionTablet';
import styles from './style.module.scss';

const CategoryFolder = ({
  name,
  children,
  startsExpanded,
}: {
  name: CategoryName;
  startsExpanded: boolean;
  children?: React.ReactNode;
}) => {
  const [isExpanded, setIsExpanded] = useState(startsExpanded);

  return (
    <div
      data-cy={`select-condition-category-accordion-${name.toLowerCase()}`}
      className={styles.category}
    >
      <div
        className={classNames(styles.header, {
          [styles['header--expanded']]: isExpanded,
        })}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <span className={styles['category-name']}>{name}</span>
      </div>
      <div
        className={classNames('mt16', styles.conditions, {
          [styles['conditions--expanded']]: isExpanded,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default ({
  selectedConditions,
  onSelect,
  onDeselect,
  categories,
}: {
  selectedConditions: Condition[];
  onSelect: (condition: Condition) => void;
  onDeselect: (condition: Condition) => void;
  categories: { [K in CategoryName]: Condition[] };
}) => {
  const entries = Object.entries(categories);
  const middle = Math.ceil(entries.length / 2);
  const columns = [entries.slice(0, middle), entries.slice(middle)];

  return (
    <div className={styles.container}>
      {columns.map((column, index) => (
        <div key={index} className={styles.column}>
          {column.map(([categoryName, conditions]) => (
            <CategoryFolder
              key={categoryName}
              name={categoryName}
              startsExpanded={false}
            >
              {conditions.map((condition) => (
                <ConditionTablet
                  key={condition.id}
                  condition={condition}
                  isSelected={selectedConditions.includes(condition)}
                  onSelect={onSelect}
                  onDeselect={onDeselect}
                />
              ))}
            </CategoryFolder>
          ))}
        </div>
      ))}
    </div>
  );
};
