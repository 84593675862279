import React, { useState } from 'react';
import { BottomOrRegularModal } from '@popsure/dirty-swan';

import styles from './style.module.scss';

export default ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  const [bottomModalOpen, setBottomModalOpen] = useState(false);
  return (
    <div data-cy="info-hint">
      <div
        className={styles.container}
        onClick={() => {
          setBottomModalOpen(true);
        }}
      >
        <div>
          <div className={styles['icon-title-container']}>
            <div className={styles['info-icon']} />
            <div className={styles.title}>{title}</div>
          </div>
          <div className={styles.content}>{children}</div>
        </div>
      </div>
      <BottomOrRegularModal
        title={title}
        isOpen={bottomModalOpen}
        onClose={() => setBottomModalOpen(false)}
      >
        <div className={styles['content-mobile']}>{children}</div>
      </BottomOrRegularModal>
    </div>
  );
};
