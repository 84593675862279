import moment from 'moment';
import { GeneralQuestionnaireModel } from 'models/questions/general';
import { StartDateContentVariation } from 'models/questions/general/insuranceHistoryV2';

export const possibleCoverageStartDateFromTodaysDate = () =>
  Array.from(Array(8).keys()).map((offset) =>
    moment(moment().add(offset - 1, 'month'))
      .startOf('month')
      .format('YYYY-MM-DD')
  );

export const getStartDateContentVariationFromAnswers = (
  questionnaireAnswers: GeneralQuestionnaireModel
): StartDateContentVariation => {
  const employmentStartDate =
    questionnaireAnswers.financialHistory?.employmentStartDate;
  const employmentStatus =
    questionnaireAnswers.financialHistory?.employmentStatus;
  const employedOutsideGermany =
    questionnaireAnswers.financialHistory?.employedOutsideGermany;
  const mostRecentHealthInsurance =
    questionnaireAnswers.insuranceHistory?.mostRecentHealthInsurance || '';
  const alreadyOnPublicViaEmployer =
    questionnaireAnswers.insuranceHistory?.alreadyOnPublicViaEmployer;

  const hasGermanEmployer =
    employmentStatus === 'EMPLOYED' && !employedOutsideGermany;

  let monthsSinceEmploymentStartDate = 0;

  const todayDate = moment();
  monthsSinceEmploymentStartDate = todayDate.diff(
    moment(employmentStartDate),
    'months'
  );

  if (
    (mostRecentHealthInsurance === 'PUBLIC_INSURANCE' && hasGermanEmployer) ||
    alreadyOnPublicViaEmployer
  ) {
    if (monthsSinceEmploymentStartDate < 2) {
      return 'ON_PUBLIC_IN_DE_AND_EMPLOYED_AND_EMPLOYMENT_START_UNDER_2_MONTHS';
    }
    return 'ON_PUBLIC_IN_DE_AND_EMPLOYED_AND_EMPLOYMENT_START_2_MONTHS_AND_OVER';
  }

  if (
    mostRecentHealthInsurance === 'PUBLIC_INSURANCE' &&
    employmentStatus === 'SELF_EMPLOYED'
  ) {
    if (monthsSinceEmploymentStartDate < 2) {
      return 'ON_PUBLIC_IN_DE_AND_SELF_EMPLOYED_AND_SELF_EMPLOYMENT_START_UNDER_2_MONTHS';
    }
    return 'ON_PUBLIC_IN_DE_AND_SELF_EMPLOYED_AND_SELF_EMPLOYMENT_START_2_MONTHS_AND_OVER';
  }

  if (
    !['PUBLIC_INSURANCE', 'PRIVATE_INSURANCE'].includes(
      mostRecentHealthInsurance
    )
  ) {
    if (employmentStatus === 'EMPLOYED') {
      return 'NOT_ON_PUBLIC_OR_PRIVATE_IN_DE_AND_EMPLOYED';
    }
    return 'NOT_ON_PUBLIC_OR_PRIVATE_IN_DE_AND_NOT_EMPLOYED';
  }
  return 'GENERIC';
};
