import { getAllCountries } from '@getpopsure/public-models';
import {
  isDeductible,
  isEmploymentStatus,
  isResidencePermitType,
  isTariff,
  isValidISODate,
  rawQueryParametersAddOnstoAddOn,
} from 'pages/welcome/component/util';
import { setTariffAndDeductible, setAddOns, setSickDayPayout } from 'actions';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTariffAndDeductible } from 'selectors/tariff';
import { AppState } from 'reducers';

import {
  setDateOfBirth,
  setEmail,
  setEmployedOutsideGermany,
  setEmploymentStatus,
  setIncome,
  setName,
  setNationality,
  setResidencePermitType,
} from './actions';

import MissingInfoError from '../missingInfoError';
import { useLocation } from 'react-router-dom';

/**
 * WithTariff wrapper
 *
 * This wrapper component makes sure that the tariff & deductible
 * variables are correctly set on every question of the flow,
 * either in the store or in the URL params and returns an error
 * if these values are missing.
 *
 * @param children React.Element
 *
 * @return React.Element Children | MissingInfoError component
 *
 */
const WithTariff = ({ children }: { children: React.ReactElement }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const deductible = params.get('deductible');
  const tariff = params.get('tariff');
  const sickDayPayout = params.get('sickDayPayout');
  const dateOfBirth = params.get('dateOfBirth');
  const employmentStatus = params.get('employmentStatus');
  const income = params.get('income');
  const addons = params.get('addOns');
  const firstName = params.get('firstName');
  const lastName = params.get('lastName');
  const email = params.get('email');
  const citizenship = params.get('citizenship');
  const residencyPermitType = params.get('residencyPermitType');
  const employedOutsideGermany = params.get('employedOutsideGermany');

  const storedTariff = useSelector((state: AppState) =>
    getTariffAndDeductible(state)
  );

  const dispatch = useDispatch();
  const isTariffInTheStore = storedTariff?.deductible && storedTariff.tariff;
  const isTariffInQueryParams = isDeductible(deductible) && isTariff(tariff);

  useEffect(() => {
    const addOns = rawQueryParametersAddOnstoAddOn(addons);

    if (isDeductible(deductible) && isTariff(tariff)) {
      dispatch(setTariffAndDeductible(tariff, deductible));
    }

    if (addons !== null && addOns) {
      dispatch(setAddOns(addOns));
    }

    if (sickDayPayout && !isNaN(Number(sickDayPayout))) {
      dispatch(setSickDayPayout(Number(sickDayPayout)));
    }

    if (income && !isNaN(Number(income))) {
      dispatch(setIncome(Number(income)));
    }

    if (isValidISODate(dateOfBirth)) {
      dispatch(setDateOfBirth(dateOfBirth));
    }

    if (isEmploymentStatus(employmentStatus)) {
      dispatch(setEmploymentStatus(employmentStatus));
    }

    if (firstName && lastName) {
      dispatch(setName({ firstName, lastName }));
    }

    if (email) {
      dispatch(setEmail(email));
    }

    if (isResidencePermitType(residencyPermitType)) {
      dispatch(setResidencePermitType(residencyPermitType));
    }

    if (employedOutsideGermany) {
      if (employedOutsideGermany === 'true') {
        dispatch(setEmployedOutsideGermany(true));
      }

      if (employedOutsideGermany === 'false') {
        dispatch(setEmployedOutsideGermany(false));
      }
    }

    if (citizenship) {
      const countryCodes = (citizenship ?? '').split(',');

      const allCountries = getAllCountries('en');
      const selectedCountries = allCountries.flatMap(({ code, name }) =>
        countryCodes.includes(code) ? [{ code, name }] : []
      );
      dispatch(setNationality(selectedCountries));
    }

    // eslint-disable-next-line
  }, []);

  if (isTariffInQueryParams || isTariffInTheStore) {
    return children;
  }

  return <MissingInfoError />;
};

export default WithTariff;
