import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PhoneNumber from './view/phoneNumber';
import PhoneVerification from './view/phoneVerification';
import { AppState } from 'reducers';
import {
  getAsyncOperationInProgress,
  getAsyncOperationErrorMessage,
} from 'selectors/asyncOperation';
import { answeredQuestion } from 'actions';
import { useTranslation } from '@getpopsure/i18n-react';
import Session from '@getpopsure/session';
import { signOut } from 'actions/session';
import { getUser } from 'selectors/remoteData';

const BrokerMandate = () => {
  const { t } = useTranslation();
  const savedPhoneNumber = useSelector(
    (state: AppState) => state.questionnaire.personalInfo?.phoneNumber
  );
  const user = useSelector(getUser);
  const existingCustomer = useSelector(
    (state: AppState) => state.session.existingCustomer
  );

  const [phoneNumber, setPhoneNumber] = useState<string | null>(
    savedPhoneNumber ?? null
  );
  const [showPhoneVerify, setShowPhoneVerify] = useState<boolean>(false);
  const isAuthenticated = Session.isAuthenticated;
  const isExistingCustomer = useSelector(
    (state: AppState) => state.session.existingCustomer
  );
  const loading = useSelector((state: AppState) =>
    getAsyncOperationInProgress(state, 'post.questionnaire')
  );
  const error = useSelector((state: AppState) =>
    getAsyncOperationErrorMessage(
      state,
      'post.phone.confirm',
      'post.phone.verify'
    )
  );
  const dispatch = useDispatch();
  const handleAnswerQuestion = (answer: string) => {
    if ((existingCustomer || user) && !Session.isAuthenticated) {
      dispatch(signOut());
      return;
    }

    dispatch(
      answeredQuestion(
        {
          type: 'general',
          sectionId: 'personalInfo',
          questionId: 'phoneNumber',
        },
        answer
      )
    );
    setPhoneNumber(answer);
    setShowPhoneVerify(true);
  };

  if (isExistingCustomer && !isAuthenticated) {
    return <h2 className="p-h2 ta-center">Please first login to continue</h2>;
  }

  return (
    <div className="p-body">
      <div className="p-h4 tc-primary-500 ta-center mt16">
        {t('page.phonenumber.step.title', 'Step 1')}
      </div>
      {showPhoneVerify && phoneNumber ? (
        <PhoneVerification
          setShowPhoneVerify={setShowPhoneVerify}
          phoneNumber={phoneNumber}
          dispatch={dispatch}
          loading={loading}
          error={error}
        />
      ) : (
        <PhoneNumber
          answer={phoneNumber}
          onAnswerQuestion={handleAnswerQuestion}
        />
      )}
    </div>
  );
};

export default BrokerMandate;
