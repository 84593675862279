import React from 'react';
import { useTranslation } from '@getpopsure/i18n-react';

import { InfoHint, InfoHintContainer } from 'components/infoHint';
import { WrappedForm } from 'pages/questions/formWrapper';
import { nonEmptyValidator } from 'validators';
import { YesNoQuestion } from 'pages/questions/generateInput';
import Center from 'components/center';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';

export default () => {
  const { t } = useTranslation();

  return (
    <Center>
      <h1 className="p-h1">
        {generalQuestionsTitleMapping.diagnosedTreatedOrGivenMedicalAdvice!(t)}
      </h1>
      <WrappedForm
        validator={nonEmptyValidator}
        sectionId="medicalHistory"
        questionId="diagnosedTreatedOrGivenMedicalAdvice"
      >
        {({ value, setValue }) => (
          <>
            <InfoHintContainer className="mt8">
              <YesNoQuestion
                name="diagnosedTreatedOrGivenMedicalAdvice"
                value={value as boolean}
                setValue={setValue}
              />
              <InfoHint
                title={t(
                  'questions.diagnosedtreatedorgivenmedicaladvice.honesty.hint.title',
                  'Honesty Pledge'
                )}
              >
                {t(
                  'questions.diagnosedtreatedorgivenmedicaladvice.honesty.hint.description',
                  'Please make sure to provide honest answers. Any purely preventive treatments, checkups related to existing conditions, developmental disorders, or chronic diseases also need to be mentioned.'
                )}
              </InfoHint>
            </InfoHintContainer>
          </>
        )}
      </WrappedForm>
    </Center>
  );
};
