import { connect } from 'react-redux';

import View from './view';
import { connectedRedirect } from 'components/connectedRedirect';
import connectedAnswer, {
  ViewProps as ConnectedViewProps,
} from 'pages/questions/connectedAnswer';
import { AppState } from 'reducers';
import { getUser } from 'selectors/remoteData';
import { clearAllRemoteData } from 'actions';

export type ViewProps = ConnectedViewProps<'personalInfo', 'email'> &
  StateProps &
  DispatchProps;

interface StateProps {
  sessionEmail?: string;
}

interface DispatchProps {
  clearAllRemoteData: () => void;
}

const mapStateToProps = (state: AppState): StateProps => ({
  sessionEmail: getUser(state)?.email,
});

const mapDispatchToProps = {
  clearAllRemoteData,
};

export default connectedRedirect(
  { sectionId: 'personalInfo', questionId: 'email', type: 'general' },
  connectedAnswer(
    'personalInfo',
    'email'
  )(connect(mapStateToProps, mapDispatchToProps)(View))
);
