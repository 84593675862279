import { useTranslation } from '@getpopsure/i18n-react';
import React from 'react';

import Center from 'components/center';

import styles from './style.module.scss';
import success from './img/success.svg';

const ConfirmationScreen = () => {
  const { t } = useTranslation();
  return (
    <Center>
      <img src={success} className="d-block mr-auto ml-auto" alt="" />
      <h1
        data-cy="confirmation-screen"
        className={`p-h1 ta-center ${styles.title}`}
      >
        {t('page.confirmation.title', 'You’re all set!')}
      </h1>
      <p className="p-p mt8 wmx4 ml-auto mr-auto d-block ta-center">
        {t(
          'page.confirmation.broker.description',
          'Your application has been submitted. Your broker will be in touch.'
        )}
      </p>
    </Center>
  );
};

export default ConfirmationScreen;
