import React, { useState } from 'react';
import { useTranslation } from '@getpopsure/i18n-react';

import Form from 'components/form';
import connectedAnswer from 'pages/questions/connectedAnswer';
import { GeneralQuestionnaireModel } from 'models/questions/general';
import { ViewProps } from 'pages/questions/connectedAnswer';
import { connectedRedirect } from 'components/connectedRedirect';

import styles from './style.module.scss';
import { LabelType } from 'actions/type';

export const WrappedForm = <
  S extends keyof GeneralQuestionnaireModel,
  Q extends keyof Required<GeneralQuestionnaireModel>[S]
>({
  validator,
  children: Children,
  sectionId,
  questionId,
  label,
  skipButtonProps,
}: {
  validator: (input?: Required<GeneralQuestionnaireModel>[S][Q]) => boolean;
  children: React.ComponentType<{
    setValue: (answer: Required<GeneralQuestionnaireModel>[S][Q]) => void;
    value?: Required<GeneralQuestionnaireModel>[S][Q];
    questionnaireAnswers?: GeneralQuestionnaireModel;
  }>;
  questionId: Q;
  sectionId: S;
  label?: LabelType;
  skipButtonProps?: {
    skipValue: Required<GeneralQuestionnaireModel>[S][Q];
    caption?: string;
  };
}) => {
  const View = ({
    answer,
    onAnswerQuestion,
    questionnaireAnswers,
  }: ViewProps<S, Q>) => {
    const { t } = useTranslation();
    const [value, setValue] = useState(answer);
    const valid = validator(value);
    return (
      <Form
        valid={valid}
        value={value}
        onAnswerQuestion={() =>
          onAnswerQuestion(
            value as Required<GeneralQuestionnaireModel>[S][Q],
            label
          )
        }
      >
        <Children
          setValue={setValue}
          value={value}
          questionnaireAnswers={questionnaireAnswers}
        />
        <div className="d-flex gap16 mt24 f-wrap">
          <button
            data-cy="questionnaire-continue"
            className={`p-btn--primary ${styles.button}`}
            type="submit"
            disabled={!valid}
          >
            {t('questions.general.continue.action', 'Continue')}
          </button>
          {skipButtonProps && (
            <button
              data-cy="questionnaire-skip"
              className={`p-btn--secondary wmn2 ${styles.button}`}
              type="button"
              onClick={() => onAnswerQuestion(skipButtonProps.skipValue)}
            >
              {skipButtonProps.caption ||
                t('questions.general.skip.action', 'Provide later')}
            </button>
          )}
        </div>
      </Form>
    );
  };

  const ConnectedView = connectedRedirect(
    { type: 'general', sectionId, questionId: questionId as any },
    connectedAnswer(sectionId, questionId)(View)
  );
  return <ConnectedView />;
};
