import React, { useState } from 'react';
import { captureException, captureMessage } from '@sentry/browser';
import axios, { AxiosError } from 'axios';
import {
  BottomOrRegularModal,
  DownloadButton,
  DownloadStatus,
} from '@popsure/dirty-swan';
import { calendly } from '@getpopsure/private-constants';
import { useTranslation } from '@getpopsure/i18n-react';
import { Trans } from '@getpopsure/i18n-react';
import { trackStructuredEvent } from '@getpopsure/analytics';
import classNames from 'classnames';

import { useHistory } from 'react-router-dom';
import { ViewProps } from '..';
import { downloadTakeawayArchives } from 'network/api';
import { formatErrorMessageFromError } from 'models/error';
import { path, pathForQuestion } from 'routes/path';
import { downloadBlob } from 'utils/downloadBlob';
import { sleep } from 'utils/sleep';

import styles from './style.module.scss';
import { ReactComponent as DocumentIcon } from './img/download-document.svg';

interface FormValues {
  'downloaded-documents': boolean;
  'no-personal-advice': boolean;
}

export default ({ fullName, email, questionnaireId }: ViewProps) => {
  const { t } = useTranslation();
  const [downloadState, setDownloadState] = useState<DownloadStatus>('INITIAL');
  const [error, setError] = useState<string | undefined>(undefined);
  const [form, setForm] = useState<FormValues>({
    'downloaded-documents': false,
    'no-personal-advice': false,
  });
  const [adviceModal, setAdviceModal] = useState<boolean>(false);

  const history = useHistory();

  if (!email) {
    history.replace(
      pathForQuestion({
        type: 'general',
        sectionId: 'medicalHistory',
        questionId: 'finalSteps',
      })
    );
    return <></>;
  }

  if (!questionnaireId) {
    captureMessage(
      'This customer has encountered the "Questionnaire ID is not defined" issue on the downloads screen and cannot finish their private signup',
      (scope) => {
        scope.setUser({ username: fullName, email });
        return scope;
      }
    );
  }

  const handleOnClickDownloadDocuments = () => {
    const downloadFiles = async () => {
      let timeoutError: Error | undefined;
      const downloadArchiveTimeout = setTimeout(() => {
        timeoutError = new Error(
          'Timed out trying to download legal documents'
        );
        captureException(timeoutError);
        setError('Something went wrong.');
      }, 1000 * 180);

      setError(undefined);

      try {
        while (true) {
          const {
            data: { completed, downloadUrl },
          } = await downloadTakeawayArchives(questionnaireId || '');

          // Wait for 1 second to make the next call
          await sleep(1000);

          if (timeoutError) {
            break;
          }

          if (completed === 100) {
            const response = await axios.get(downloadUrl, {
              responseType: 'blob',
            });
            if (response.status >= 200 && response.status < 300) {
              downloadBlob(
                response.data,
                t(
                  'page.legaldocuments.filename',
                  'Private health documents.zip'
                )
              );
              setDownloadState('COMPLETED');
            } else {
              throw new Error(response.data);
            }
            break;
          }
        }
      } catch (error) {
        captureException(error);
        setError(formatErrorMessageFromError(error as AxiosError));
        setDownloadState('FAILED');
      }

      clearTimeout(downloadArchiveTimeout);
    };

    setDownloadState(() => {
      downloadFiles();
      return 'GENERATING';
    });
  };

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.target.id as keyof FormValues;
    setForm((form) => ({ ...form, [id]: !form[id] }));
  };

  const openAdviceModal = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setAdviceModal(true);
  };

  const handleReachOutButton = () => {
    setAdviceModal(false);
    trackStructuredEvent({
      category: 'PRIVATE_HEALTH',
      action: 'CLICKED_BOOK_AN_APPOINTMENT',
    });
    window.Calendly.initPopupWidget({
      url: calendly.healthInsuranceAdvicePriority,
      utm: {
        utmSource: 'privatesignuplegaldocuments',
      },
      prefill: {
        name: fullName,
        email: email,
      },
    });
  };

  const isFormDisabled =
    !form['downloaded-documents'] || !form['no-personal-advice'];

  return (
    <div className="p-body">
      <div className={styles.root}>
        <div className="p-h4 tc-primary-500 ta-center mt16">
          {t('page.legaldocuments.step.title', 'Step 2')}
        </div>
        <div className="mt24 ta-center">
          <DocumentIcon />
        </div>
        <h1 className={`p-h1 mt24 ${styles.title}`}>
          {t('page.legaldocuments.title', 'Review the terms')}
        </h1>
        <p className={`p-p mt8 ${styles.description}`}>
          <Trans i18nKey="page.legaldocuments.description">
            In them you will find all the details about what’s covered and what
            isn’t. They will also be available in your account.
          </Trans>
        </p>
        <div className="mt40">
          {!!questionnaireId ? (
            <DownloadButton
              downloadStatus={downloadState}
              onDownload={handleOnClickDownloadDocuments}
            />
          ) : (
            <p className="p-p ta-center">
              An error happened, we can&apos;t find your questionnaire ID.
              Please refresh the page and if you still see this error contact
              us.
            </p>
          )}
          {downloadState === 'GENERATING' && (
            <p className="mt16 ta-center">
              <Trans i18nKey="page.legaldocuments.generating">
                It can take up to a minute - it’s quite the stack
              </Trans>{' '}
              <span role="img" aria-label="pancake-emoji">
                🥞
              </span>
            </p>
          )}
        </div>
        {error && (
          <div className="p-notice--danger mt16 wmx6 ml-auto mr-auto">
            {error}
          </div>
        )}
        {downloadState === 'COMPLETED' && (
          <div className={`wmx7 ${styles['legal-container']}`}>
            <div className="p-label-container mt48" data-cy="legal-accept-tnc">
              <input
                id="downloaded-documents"
                className="p-checkbox"
                type="checkbox"
                checked={form['downloaded-documents']}
                onChange={handleFormChange}
              />
              <label className="p-label" htmlFor="downloaded-documents">
                {t(
                  'page.legaldocuments.legal.confirm.description',
                  'I hereby confirm to have received all documents and written terms related to the health insurance contract. I give my full consent to the terms of the insurance contract.'
                )}
              </label>
            </div>
            {
              <div
                className="p-label-container mt16"
                data-cy="legal-accept-advice"
              >
                <input
                  id="no-personal-advice"
                  className="p-checkbox"
                  type="checkbox"
                  checked={form['no-personal-advice']}
                  onChange={handleFormChange}
                />
                <label className="p-label" htmlFor="no-personal-advice">
                  <span>
                    {t(
                      'page.legaldocuments.broker.legal.confirm.description',
                      'I confirm I would like to apply for health insurance fully digital and without personal advice.'
                    )}{' '}
                    <button
                      className={`p-a p-h4 fw-bold c-pointer ${styles.linkButton}`}
                      onClick={openAdviceModal}
                    >
                      {t(
                        'page.legaldocuments.broker.legal.confirm.advice.open.action',
                        'Why am I asked this?'
                      )}
                    </button>
                  </span>
                </label>
              </div>
            }

            <button
              data-cy="legal-continue"
              className={`p-btn--primary wmn3 mt40 ${styles['continue-button']}`}
              disabled={isFormDisabled}
              onClick={() => {
                history.push(path.submitApplication);
              }}
            >
              {t('page.legaldocuments.continue.action', 'Continue')}
            </button>
          </div>
        )}
      </div>
      <BottomOrRegularModal
        title={t(
          'page.legaldocuments.modal.consent.title',
          'Why am I asked for consent?'
        )}
        isOpen={adviceModal}
        onClose={() => setAdviceModal(false)}
      >
        <div className={styles['modal-inner-container']}>
          <p className="p-p">
            {t(
              'page.legaldocuments.modal.consent.description.1',
              'Every insurance we offer has been very carefully selected and is of great value. Our fully digital model means we keep paperwork to a minimum and overhead costs down, passing those savings onto our customers.'
            )}
          </p>
          <p className="p-p mt8">
            {t(
              'page.legaldocuments.modal.consent.description.2',
              'However, regulators require us to offer you personal advice before applying for health insurance. Because of that we also need to let you know that it may be more difficult to assert claims for receiving wrongful advice if you continue without further consultation.'
            )}
          </p>
          <p className="p-p mt8">
            <Trans i18nKey="page.legaldocuments.modal.consent.advice">
              If you’d like some advice,
              <button
                className={classNames(
                  'p-a fw-bold c-pointer',
                  styles.linkButton,
                  styles.bookACallButton
                )}
                onClick={handleReachOutButton}
              >
                you can schedule a video call with us.
              </button>
            </Trans>
          </p>
        </div>
      </BottomOrRegularModal>
    </div>
  );
};
