import { SpecifyQuestionnaireModel } from 'models/questions/specify';
import { TreeLogic } from '..';
import {
  intermediateSurchargeMatrix,
  lowSurchargeMatrix,
} from 'models/surcharge';

export class A implements TreeLogic<'A'> {
  getRejection = () => {
    return false;
  };

  getAssess = ({
    whatKindOfCareRequired,
    hasBeenDeemedUnfitToWork,
    uploadMedicalDischarge,
  }: Partial<SpecifyQuestionnaireModel['A']>) => {
    if (
      (whatKindOfCareRequired === 'IN_PATIENT' ||
        hasBeenDeemedUnfitToWork === true) &&
      uploadMedicalDischarge !== undefined
    ) {
      return true;
    }

    return false;
  };

  getQuestionOrder({
    whatKindOfCareRequired,
  }: Partial<
    SpecifyQuestionnaireModel['A']
  >): (keyof SpecifyQuestionnaireModel['A'])[] {
    if (whatKindOfCareRequired === 'IN_PATIENT') {
      return ['whatKindOfCareRequired', 'uploadMedicalDischarge'];
    }

    return [
      'whatKindOfCareRequired',
      'hasBeenDeemedUnfitToWork',
      'exclusionOfCoverage',
    ];
  }

  getRiskSurchargeMatrix() {
    return null;
  }
}

export class B implements TreeLogic<'B'> {
  getRejection = () => {
    return false;
  };

  getAssess({
    uploadMedicalDischarge,
  }: Partial<SpecifyQuestionnaireModel['B']>): boolean {
    if (uploadMedicalDischarge !== undefined) {
      return true;
    }

    return false;
  }

  getQuestionOrder({
    whatKindOfCareRequired,
  }: Partial<
    SpecifyQuestionnaireModel['B']
  >): (keyof SpecifyQuestionnaireModel['B'])[] {
    if (whatKindOfCareRequired === 'IN_PATIENT') {
      return ['whatKindOfCareRequired', 'uploadMedicalDischarge'];
    }

    return ['whatKindOfCareRequired', 'hasBeenDeemedUnfitToWork'];
  }

  getRiskSurchargeMatrix() {
    return null;
  }
}
export class C implements TreeLogic<'C'> {
  getRejection = () => {
    return false;
  };

  getAssess(): boolean {
    return false;
  }

  getQuestionOrder({
    whatKindOfCareRequired,
  }: Partial<
    SpecifyQuestionnaireModel['C']
  >): (keyof SpecifyQuestionnaireModel['C'])[] {
    if (whatKindOfCareRequired === 'IN_PATIENT') {
      return ['whatKindOfCareRequired', 'uploadMedicalDischarge'];
    }

    return ['whatKindOfCareRequired'];
  }

  getRiskSurchargeMatrix() {
    return null;
  }
}
export class D implements TreeLogic<'D'> {
  private age: number;

  constructor(age: number) {
    this.age = age;
  }

  getRejection = (): boolean => {
    return false;
  };

  getAssess = ({
    requiredSurgicalIntervention,
    hasFullyRecovered,
    recoveryLastedMoreThan14days,
    recoveryLastedMoreThan28days,
  }: Partial<SpecifyQuestionnaireModel['D']>): boolean => {
    if (hasFullyRecovered === false) {
      return true;
    }

    if (requiredSurgicalIntervention === false) {
      if (recoveryLastedMoreThan14days === true) {
        return true;
      }

      if (recoveryLastedMoreThan28days === true) {
        return true;
      }
    }

    if (requiredSurgicalIntervention === true) {
      if (recoveryLastedMoreThan28days === true) {
        return true;
      }
    }

    return false;
  };

  getQuestionOrder({
    requiredSurgicalIntervention,
    howManyTimeRequiredRecoveryTime,
  }: SpecifyQuestionnaireModel['D']): (keyof SpecifyQuestionnaireModel['D'])[] {
    let base: (keyof SpecifyQuestionnaireModel['D'])[] = [
      'requiredSurgicalIntervention',
      'hasFullyRecovered',
    ];

    if (requiredSurgicalIntervention === true) {
      base = base.concat(['recoveryLastedMoreThan28days']);
    }

    if (requiredSurgicalIntervention === false) {
      base = base.concat(['howManyTimeRequiredRecoveryTime']);

      switch (howManyTimeRequiredRecoveryTime) {
        case 'NONE':
          break;
        case 'ONCE':
          base = base.concat(['recoveryLastedMoreThan14days']);
          break;
        case 'TWICE':
          base = base.concat(['recoveryLastedMoreThan28days']);
          break;
        case 'THREE_OR_MORE':
          base = base.concat(['recoveryLastedMoreThan28days']);
          break;
      }
    }

    return base;
  }

  getRiskSurchargeMatrix({
    requiredSurgicalIntervention,
    howManyTimeRequiredRecoveryTime,
    recoveryLastedMoreThan28days,
  }: Partial<SpecifyQuestionnaireModel['D']>) {
    if (
      requiredSurgicalIntervention === false &&
      howManyTimeRequiredRecoveryTime === 'THREE_OR_MORE' &&
      recoveryLastedMoreThan28days === false
    ) {
      return lowSurchargeMatrix(this.age);
    }

    return null;
  }
}

export class E implements TreeLogic<'E'> {
  private age: number;

  constructor(age: number) {
    this.age = age;
  }

  getRejection = () => {
    return false;
  };

  getAssess({
    whatKindOfCareRequired,
    hasFullyRecovered,
    uploadMedicalDischarge,
    hasBeenDeemedUnfitToCarryOutDailyActivities,
    howManyTimesHasFallenIllWithBronchitisYoungerThan8,
    howManyTimesHasFallenIllWithBronchitisOlderThan8,
  }: Partial<SpecifyQuestionnaireModel['E']>): boolean {
    if (whatKindOfCareRequired === 'NO_TREATMENT_REQUIRED') {
      return true;
    }

    if (uploadMedicalDischarge !== undefined) {
      return true;
    }

    if (hasFullyRecovered === false) {
      return true;
    }

    if (hasBeenDeemedUnfitToCarryOutDailyActivities === true) {
      return true;
    }

    if (this.age < 8) {
      if (
        howManyTimesHasFallenIllWithBronchitisYoungerThan8 ===
        'FIVE_TIMES_OR_MORE'
      ) {
        return true;
      }
    }

    if (
      howManyTimesHasFallenIllWithBronchitisOlderThan8 === 'FOUR_TIMES_OR_MORE'
    ) {
      return true;
    }

    return false;
  }

  getQuestionOrder({
    whatKindOfCareRequired,
  }: Partial<
    SpecifyQuestionnaireModel['E']
  >): (keyof SpecifyQuestionnaireModel['E'])[] {
    if (whatKindOfCareRequired === 'IN_PATIENT') {
      return ['whatKindOfCareRequired', 'uploadMedicalDischarge'];
    }

    if (this.age < 8) {
      return [
        'whatKindOfCareRequired',
        'hasFullyRecovered',
        'howManyTimesHasFallenIllWithBronchitisYoungerThan8',
        'hasBeenDeemedUnfitToCarryOutDailyActivities',
      ];
    }

    return [
      'whatKindOfCareRequired',
      'hasFullyRecovered',
      'howManyTimesHasFallenIllWithBronchitisOlderThan8',
      'hasBeenDeemedUnfitToCarryOutDailyActivities',
    ];
  }

  getRiskSurchargeMatrix({
    howManyTimesHasFallenIllWithBronchitisYoungerThan8,
    howManyTimesHasFallenIllWithBronchitisOlderThan8,
    hasBeenDeemedUnfitToCarryOutDailyActivities,
  }: Partial<SpecifyQuestionnaireModel['E']>) {
    if (
      howManyTimesHasFallenIllWithBronchitisOlderThan8 === 'THREE_TIMES' &&
      hasBeenDeemedUnfitToCarryOutDailyActivities === false
    ) {
      return intermediateSurchargeMatrix(this.age);
    }

    if (
      howManyTimesHasFallenIllWithBronchitisYoungerThan8 ===
        'THREE_FOUR_TIMES' &&
      hasBeenDeemedUnfitToCarryOutDailyActivities === false
    ) {
      return intermediateSurchargeMatrix(this.age);
    }

    return null;
  }
}
