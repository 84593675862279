import { getTrackingObject } from '@getpopsure/tracker';
import { getFixedT } from '@getpopsure/i18n-react';

import { isArrayOfUploadedFiles } from 'models';
import {
  GeneralQuestionnaireModel,
  GeneralQuestionId,
} from 'models/questions/general';
import { RecursivePartial } from 'utils/types';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';
import { questionnaireLegalText } from 'models/questions/text/legal';
import { specifyQuestionsTitleMapping } from 'models/questions/specify/mapping';
import { StoredSpecifyQuestionnaireModel } from 'reducers/specify';
import { TariffPayload } from 'models/price';
import { State as MetadataState } from 'reducers/metadata';
import { Question } from 'models/questions';
import { InvestigationQuestionnaireModel } from 'reducers/investigation';

export function extractQuestionnaireUploadTokens(
  questionnaire: GeneralQuestionnaireModel | StoredSpecifyQuestionnaireModel
): string[] {
  const arr: string[] = [];
  function eachRecursive(obj: any) {
    for (const v of Object.values(obj)) {
      if (v && isArrayOfUploadedFiles(v)) {
        arr.push(...(v.map(({ token }) => token) as string[]));
      } else if (typeof v === 'object' && v !== null && v !== undefined) {
        eachRecursive(v);
      }
    }
  }

  eachRecursive(questionnaire);
  return arr;
}

export function metadataFromSpecifyQuestionnaireModel(
  questionnaire: RecursivePartial<StoredSpecifyQuestionnaireModel> = {}
) {
  const duplicate = { ...questionnaire };
  const t = getFixedT();

  const result: { [x in any]: any } = {};
  // TODO: Make this mess a bit better… someday
  Object.entries(duplicate).forEach(([questionId, tree]) => {
    result[questionId] = {};
    if (tree) {
      Object.entries(tree).forEach(([treeId, condition]) => {
        if (treeId === 'generic') {
          return;
        }

        result[questionId][treeId] = {};
        if (condition) {
          result[questionId][treeId] = {};
          Object.values(condition).forEach((answer) => {
            Object.keys(answer).forEach((specifyQuestionId) => {
              result[questionId][treeId][
                specifyQuestionId
              ] = (specifyQuestionsTitleMapping as any)[treeId][
                specifyQuestionId
              ].title;
            });
          });
        }
      });
    }
  });

  result.generic = {
    description: t(
      'api.metadata.genericquestion.description',
      'Would you explain this to us in a little more detail?'
    ),
  };

  return result;
}

export function metadataFromQuestionnaireModel(
  questionnaire: GeneralQuestionnaireModel
) {
  const t = getFixedT();
  return Object.entries(questionnaire).reduce((a, [k, v]) => {
    if (v === undefined) {
      return a;
    }

    return {
      ...a,
      [k]: Object.keys(v).reduce((b, m) => {
        const questionTitle = generalQuestionsTitleMapping[
          m as GeneralQuestionId
        ]?.(t);

        if (questionTitle) {
          return {
            ...b,
            [m]: questionTitle,
          };
        }

        return b;
      }, {}),
    };
  }, {});
}

export function transformQuestionnaireModel(
  questionnaire: GeneralQuestionnaireModel & {
    personalInfo: { coverageStartDate?: string };
  },
  specify: StoredSpecifyQuestionnaireModel = {},
  investigation: InvestigationQuestionnaireModel,
  tariffInfo: TariffPayload,
  answersMetadata: MetadataState,
  questionOrder: Question[]
) {
  const uploadTokens = [
    ...extractQuestionnaireUploadTokens(questionnaire),
    ...extractQuestionnaireUploadTokens(specify),
  ];

  return {
    answers: {
      ...questionnaire,
      specify,
      tariffInfo,
      ...{ investigation: { medicalHistory: investigation } },
    },
    questionnaireType: 'PRIVATE_HEALTH',
    uploadTokens,
    metadata: {
      labels: {
        answers: answersMetadata,
        title: {
          specify: metadataFromSpecifyQuestionnaireModel(specify),
          ...metadataFromQuestionnaireModel(questionnaire),
        },
      },
      legal: questionnaireLegalText,
      questionOrder,
      source: getTrackingObject(),
    },
  };
}
