import { TreeLogic } from '..';
import { SpecifyQuestionnaireModel } from 'models/questions/specify';
import { highSurchargeMatrix } from 'models/surcharge';

export class FGH implements TreeLogic<'FGH'> {
  private bmi: number;
  private age: number;

  constructor(bmi: number, age: number) {
    this.bmi = bmi;
    this.age = age;
  }

  getRejection = (): boolean => {
    if (this.age >= 16) {
      return new F(this.bmi, this.age).getRejection();
    }

    if (this.age >= 10) {
      return new G(this.bmi, this.age).getRejection();
    }

    if (this.age < 10) {
      return new H(this.bmi, this.age).getRejection();
    }

    throw new Error(`Unhandled rejection for age:${this.age} bmi:${this.bmi}`);
  };

  getAssess = (props: SpecifyQuestionnaireModel['FGH']) => {
    if (this.age >= 16) {
      return new F(this.bmi, this.age).getAssess(props);
    }

    if (this.age >= 10) {
      return new G(this.bmi, this.age).getAssess(props);
    }

    if (this.age < 10) {
      return new H(this.bmi, this.age).getAssess(props);
    }

    throw new Error(`Unhandled rejection for age:${this.age} bmi:${this.bmi}`);
  };

  getQuestionOrder(
    props: SpecifyQuestionnaireModel['FGH']
  ): (keyof SpecifyQuestionnaireModel['FGH'])[] {
    if (this.age >= 16) {
      return new F(this.bmi, this.age).getQuestionOrder(props);
    }

    if (this.age >= 10) {
      return new G(this.bmi, this.age).getQuestionOrder(props);
    }

    if (this.age < 10) {
      return new H(this.bmi, this.age).getQuestionOrder(props);
    }

    throw new Error(`Unhandled rejection for age:${this.age} bmi:${this.bmi}`);
  }

  getRiskSurchargeMatrix(props: SpecifyQuestionnaireModel['FGH']) {
    if (this.age >= 16) {
      return new F(this.bmi, this.age).getRiskSurchargeMatrix(props);
    }

    if (this.age >= 10) {
      return new G(this.bmi, this.age).getRiskSurchargeMatrix();
    }

    if (this.age < 10) {
      return new H(this.bmi, this.age).getRiskSurchargeMatrix();
    }

    throw new Error(
      `Unhandled risk surcharge for age:${this.age} bmi:${this.bmi}`
    );
  }
}

export class F implements TreeLogic<'FGH'> {
  private bmi: number;
  private age: number;

  constructor(bmi: number, age: number) {
    this.bmi = bmi;
    this.age = age;
  }

  getRejection = (): boolean => {
    if (this.bmi < 15 || this.bmi >= 37) {
      return true;
    }

    return false;
  };

  getAssess = ({
    hasBeenTreatedForWeightRelatedIssues,
  }: SpecifyQuestionnaireModel['FGH']) => {
    if (hasBeenTreatedForWeightRelatedIssues === 'IN_PATIENT') {
      return true;
    }

    if (this.bmi >= 15 && this.bmi <= 17) {
      return true;
    }

    if (this.bmi >= 32 && this.bmi < 37) {
      return true;
    }

    return false;
  };

  getQuestionOrder({
    hasBeenTreatedForWeightRelatedIssues,
  }: SpecifyQuestionnaireModel['FGH']): (keyof SpecifyQuestionnaireModel['FGH'])[] {
    if (hasBeenTreatedForWeightRelatedIssues === 'IN_PATIENT') {
      return ['hasBeenTreatedForWeightRelatedIssues', 'uploadMedicalDischarge'];
    }

    return ['hasBeenTreatedForWeightRelatedIssues'];
  }

  getRiskSurchargeMatrix({
    hasBeenTreatedForWeightRelatedIssues,
  }: SpecifyQuestionnaireModel['FGH']) {
    if (
      hasBeenTreatedForWeightRelatedIssues === 'OUT_PATIENT' &&
      this.bmi >= 30 &&
      this.bmi < 32
    ) {
      return highSurchargeMatrix(this.age);
    }

    return null;
  }
}

export class G implements TreeLogic<'FGH'> {
  private bmi: number;
  private age: number;

  constructor(bmi: number, age: number) {
    this.bmi = bmi;
    this.age = age;
  }

  private get upperboundValue(): number {
    // All the non-linear values on the upper bound of the BMI sub-tree right before rejection

    const upperBoundValue = [21.5, 22, 23, 24, 24.5, 25][this.age - 10];

    if (upperBoundValue === undefined) {
      throw new Error(`Couldn’t find upper bound value for: ${this.age}`);
    }

    return upperBoundValue;
  }

  getRejection = (): boolean => {
    if (this.bmi < 10) {
      return true;
    }

    if (this.bmi >= 30 + this.age - 10) {
      return true;
    }

    return false;
  };

  getAssess = ({
    hasBeenTreatedForWeightRelatedIssues,
    hasThereBeenADiagnosis,
  }: SpecifyQuestionnaireModel['FGH']) => {
    if (hasBeenTreatedForWeightRelatedIssues === 'IN_PATIENT') {
      return true;
    }

    if (this.bmi >= 10 && this.bmi <= 14 + (this.age - 10) / 2) {
      return true;
    }

    if (hasThereBeenADiagnosis === true) {
      return true;
    }

    if (this.bmi >= this.upperboundValue && this.bmi < 30 + this.age - 10) {
      return true;
    }

    return false;
  };

  getQuestionOrder({
    hasBeenTreatedForWeightRelatedIssues,
  }: SpecifyQuestionnaireModel['FGH']): (keyof SpecifyQuestionnaireModel['FGH'])[] {
    if (hasBeenTreatedForWeightRelatedIssues === 'IN_PATIENT') {
      return ['hasBeenTreatedForWeightRelatedIssues', 'uploadMedicalDischarge'];
    }

    if (
      this.bmi > 14 + (this.age - 10) / 2 &&
      this.bmi < this.upperboundValue
    ) {
      return ['hasBeenTreatedForWeightRelatedIssues', 'hasThereBeenADiagnosis'];
    }

    return ['hasBeenTreatedForWeightRelatedIssues'];
  }

  getRiskSurchargeMatrix() {
    return null;
  }
}

export class H implements TreeLogic<'FGH'> {
  private bmi: number;
  private age: number;

  constructor(bmi: number, age: number) {
    this.bmi = bmi;
    this.age = age;
  }

  getRejection = (): boolean => {
    return false;
  };

  getAssess = ({
    hasBeenTreatedForWeightRelatedIssues,
    hasThereBeenADiagnosis,
  }: SpecifyQuestionnaireModel['FGH']) => {
    if (hasBeenTreatedForWeightRelatedIssues === 'IN_PATIENT') {
      return true;
    }

    if (hasThereBeenADiagnosis === true) {
      return true;
    }

    return false;
  };

  getQuestionOrder({
    hasBeenTreatedForWeightRelatedIssues,
  }: SpecifyQuestionnaireModel['FGH']): (keyof SpecifyQuestionnaireModel['FGH'])[] {
    if (hasBeenTreatedForWeightRelatedIssues === 'IN_PATIENT') {
      return ['hasBeenTreatedForWeightRelatedIssues', 'uploadMedicalDischarge'];
    }

    return ['hasBeenTreatedForWeightRelatedIssues', 'hasThereBeenADiagnosis'];
  }

  getRiskSurchargeMatrix() {
    return null;
  }
}
