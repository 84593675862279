import cloneDeep from 'lodash.clonedeep';

import { AppState } from 'reducers';
import { getQuestionsNeedInvestigation } from 'selectors';

export const getInvestigationQuestionnaire = (
  state: AppState,
  questionsThatNeedInvestigation = getQuestionsNeedInvestigation(state)
) => {
  const copy = cloneDeep(state.investigation);

  const copyKeys = Object.keys(copy);
  const diff = copyKeys.filter(
    (x) => !questionsThatNeedInvestigation.includes(x)
  );

  diff.forEach((key) => {
    delete (copy as any)[key];
  });

  return copy;
};
