import { connect } from 'react-redux';
import * as Sentry from '@sentry/browser';

import View from './view';
import { AppState } from 'reducers';
import { getQuestionBefore, getProgressForQuestion } from 'selectors';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { questionFromPathName } from 'routes';
import { pathForQuestion } from 'routes/path';

interface StateProps {
  percentage: number;
  previousUrl?: string;
  currentQuestion: number;
  totalNumberOfQuestions: number;
  display: boolean;
}

type OwnProps = RouteComponentProps<Record<string, any>>;

export type ViewProps = StateProps & OwnProps;

export const displayProgressBarFromPathName = (pathname: string): boolean => {
  return pathname.startsWith('/questionnaire');
};

const mapStateToProps = (
  state: AppState,
  { location: { pathname } }: OwnProps
): StateProps => {
  const question = questionFromPathName(pathname);
  const display = displayProgressBarFromPathName(pathname);

  if (!question) {
    if (display === true) {
      Sentry.captureException(
        new Error(`Couldn’t find relative question id for ${pathname}`)
      );
    }
    return {
      percentage: 0,
      currentQuestion: 0,
      totalNumberOfQuestions: 0,
      display: false,
    };
  }

  const previousQuestion = getQuestionBefore(state, question);

  const previousQuestionUrl = previousQuestion
    ? pathForQuestion(previousQuestion)
    : undefined;

  const progress = getProgressForQuestion(state, question);

  if (!progress) {
    return {
      percentage: 0,
      currentQuestion: 0,
      totalNumberOfQuestions: 0,
      display,
    };
  }

  const { percentage, currentQuestion, totalNumberOfQuestions } = progress;

  return {
    percentage,
    previousUrl: previousQuestionUrl,
    currentQuestion,
    totalNumberOfQuestions,
    display,
  };
};

export default withRouter(connect(mapStateToProps)(View));
