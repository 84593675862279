import React, { useState, useEffect, useContext } from 'react';
import { Portal } from 'react-portal';

import {
  containerId,
  showFooter,
  hideFooter,
} from 'components/stickyContainer';
import { Condition, categories, conditions } from 'models/medicalConditions';
import search from './search';
import SearchResults from './searchResults';
import SelectedConditions from './selected';
import Accordion from './accordion';
import styles from './style.module.scss';
import { DisplayLiveQuoteContext } from 'Root';
import { useTranslation } from '@getpopsure/i18n-react';

interface ViewProps {
  question: string;
  onAnswerQuestion: (selectedConditions: Condition[]) => void;
  initialConditions?: Condition[];
}

export default ({
  question,
  initialConditions = [],
  onAnswerQuestion,
}: ViewProps) => {
  const { toggleDisplayLiveQuote } = useContext(DisplayLiveQuoteContext);
  const [selectedConditions, setSelectedConditions] = useState<Condition[]>(
    initialConditions
  );
  const [query, setQuery] = useState('');
  const { t } = useTranslation();
  const [searchResults, setSearchResults] = useState<Condition[]>([]);
  const [searchTimeout, setSearchTimeout] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);
  const [stickyContainer, setStickyContainer] = useState<HTMLElement | null>(
    null
  );

  useEffect(() => {
    setStickyContainer(document.getElementById(containerId));
  }, [stickyContainer]);

  useEffect(() => {
    showFooter();
    toggleDisplayLiveQuote?.(false);
    return () => {
      hideFooter();
      toggleDisplayLiveQuote?.(true);
    };
  });

  const onSelect = (condition: Condition) => {
    setSelectedConditions([...selectedConditions, condition]);
    setQuery('');
  };

  const onDeselect = (condition: Condition) =>
    setSelectedConditions(
      selectedConditions.filter(
        (previouslySelectedCondition) =>
          previouslySelectedCondition !== condition
      )
    );

  const onQueryUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentQuery = event.target.value;
    setQuery(currentQuery);

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    setSearchTimeout(
      setTimeout(() => {
        setSearchResults(search({ conditions, query: currentQuery }));
      }, 300)
    );
  };

  return (
    <div className={`p-body ${styles.container}`}>
      <header>
        <h1 className="p-h1">{question}</h1>
      </header>
      <input
        type="text"
        className="p-input mt8 wmx6"
        placeholder={t(
          'component.conditionslector.input.placeholder',
          'Search conditions...'
        )}
        data-cy="select-condition-input"
        onChange={onQueryUpdate}
        value={query}
      />
      <div className="mt16">
        <SelectedConditions
          selectedConditions={selectedConditions}
          onSelect={onSelect}
          onDeselect={onDeselect}
        />
        <div className="mt8">
          {query.length > 0 ? (
            <SearchResults
              conditions={searchResults}
              selectedConditions={selectedConditions}
              onSelect={onSelect}
              onDeselect={onDeselect}
            />
          ) : (
            <Accordion
              categories={categories}
              selectedConditions={selectedConditions}
              onSelect={onSelect}
              onDeselect={onDeselect}
            />
          )}
        </div>
      </div>

      {stickyContainer && (
        <Portal node={stickyContainer}>
          <button
            data-cy="select-condition-continue"
            className="p-btn--primary mt8 wmn3"
            type="submit"
            disabled={selectedConditions.length === 0}
            onClick={() => onAnswerQuestion(selectedConditions)}
          >
            {t('component.conditionslector.continue.action', 'Continue')}
            {selectedConditions.length > 0
              ? ` (${selectedConditions.length})`
              : ''}
          </button>
        </Portal>
      )}
    </div>
  );
};
