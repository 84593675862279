import React from 'react';
import { useTranslation } from '@getpopsure/i18n-react';

import { WrappedForm } from 'pages/questions/formWrapper';
import { generateCheckboxInput } from 'pages/questions/generateInput';
import { nonEmptyArrayValidator } from 'validators';
import Center from 'components/center';
import { InfoHintContainer, InfoHint } from 'components/infoHint';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';
import {
  undergoingTreatmentMapping,
  UndergoingTreatment,
} from 'models/questions/general/medicalHistory';

export default () => {
  const { t } = useTranslation();

  return (
    <Center>
      <h1 className="p-h1">
        {generalQuestionsTitleMapping.undergoingTreatment?.(t)}
      </h1>
      <WrappedForm
        validator={nonEmptyArrayValidator}
        sectionId="medicalHistory"
        questionId="undergoingTreatment"
        label={undergoingTreatmentMapping(t)}
      >
        {({ value, setValue }) => (
          <InfoHintContainer className="mt8">
            <>
              {generateCheckboxInput(
                'undergoingTreatment',
                value,
                undergoingTreatmentMapping(t),
                (updated: UndergoingTreatment[]) => {
                  const diff = updated.filter((x) => !value?.includes(x));
                  let newValue: UndergoingTreatment[];
                  if (diff.includes('NONE')) {
                    newValue = ['NONE'];
                  } else {
                    newValue = updated.filter((x) => x !== 'NONE');
                  }

                  setValue(newValue);
                }
              )}
              {value &&
                value.length > 0 &&
                value.includes('NONE') === false && (
                  <InfoHint
                    title={t(
                      'questions.undergoingtreatment.hint.title',
                      'How will this affect my policy?'
                    )}
                  >
                    {t(
                      'questions.undergoingtreatment.hint.description',
                      'This is a pre-existing condition that cannot be covered inthis policy. You will not be covered for future treatments relating to this'
                    )}
                  </InfoHint>
                )}
            </>
          </InfoHintContainer>
        )}
      </WrappedForm>
    </Center>
  );
};
