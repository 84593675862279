import React from 'react';
import { useTranslation } from '@getpopsure/i18n-react';

import { WrappedForm } from 'pages/questions/formWrapper';
import { generateRadioInput } from 'pages/questions/generateInput';
import { nonEmptyValidator } from 'validators';

import { InfoHintContainer } from 'components/infoHint';
import Center from 'components/center';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';
import { missingTeethUpperOrLowerJawMapping } from 'models/questions/general/medicalHistory';

export default () => {
  const { t } = useTranslation();

  return (
    <Center>
      <h1 className="p-h1">
        {generalQuestionsTitleMapping.missingTeethUpperOrLowerJaw?.(t)}
      </h1>
      <WrappedForm
        validator={nonEmptyValidator}
        sectionId="medicalHistory"
        questionId="missingTeethUpperOrLowerJaw"
        label={missingTeethUpperOrLowerJawMapping(t)}
      >
        {({ value, setValue }) => (
          <InfoHintContainer className="mt8">
            <>
              {generateRadioInput(
                'missingTeethUpperOrLowerJaw',
                value,
                missingTeethUpperOrLowerJawMapping(t),
                setValue
              )}
            </>
          </InfoHintContainer>
        )}
      </WrappedForm>
    </Center>
  );
};
