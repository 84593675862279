import React from 'react';

import styles from './style.module.scss';

export const SECTION_HEADER_CONTAINER = 'section-header-container';

export default ({ children }: { children: React.ReactNode }) => (
  <div className={`p-body ${styles.container}`}>
    <div className={styles.children}>
      <span id={SECTION_HEADER_CONTAINER} />
      {children}
    </div>
  </div>
);
