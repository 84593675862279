import React, { MouseEventHandler } from 'react';
import { useChat } from 'react-live-chat-loader';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import * as Sentry from '@sentry/browser';
import { Trans, useTranslation } from 'react-i18next';
import { BottomOrRegularModal } from '@popsure/dirty-swan';
import { email } from '@getpopsure/private-constants';

import { getUser } from 'selectors/remoteData';

import { ChatIcon } from './assets/ChatIcon';
import { useWidgetTimeout } from './utils/useWidgetTimeout';

import styles from './IntercomLauncher.module.scss';
import { bootIntercomWithUserData } from './utils';

const onError = () => {
  Sentry.captureException(
    '[INTERCOM] chat widget timed out and failed to load'
  );
};

export const IntercomLauncher = ({ color }: { color: string }) => {
  const [, loadChat] = useChat({ loadWhenIdle: true });
  const user = useSelector(getUser);
  const { t } = useTranslation();
  /**
   * Stop the timeout when the messenger successfully loads.
   * onShow is triggered when the messenger is opened.
   */
  const onLoadStarted = async (stopTimeout: () => void) => {
    await bootIntercomWithUserData(user);
    window.Intercom && window.Intercom('onShow', stopTimeout);
  };

  const { setWidgetTimeout, resetTimeout, status } = useWidgetTimeout({
    onLoadStarted,
    onError,
  });

  const handleClick: MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.stopPropagation();

    loadChat({ open: true });

    await setWidgetTimeout();
  };

  const handleResetIntercom = async () => {
    resetTimeout();
    /**
     * Clean Intercom settings to force a new boot.
     */
    window.Intercom && window.Intercom('shutdown');
    window.intercomSettings = undefined;
  };

  if (status === 'ERROR')
    return (
      <BottomOrRegularModal
        isOpen
        onClose={handleResetIntercom}
        title={t('intercomLauncher.error.modal.title', 'Contact us')}
      >
        <p className="p-p pt16 pr24 pl24 pb24">
          <Trans i18nKey="intercomLauncher.error.modal.content">
            Have questions about your insurance or need help?
            <br />
            Reach out to us at{' '}
            <a href={`mailto:${email.hello}`} className="p-a">
              {{ email: email.hello }}
            </a>
          </Trans>
        </p>
        <p className="p-p pb24 pl24 pr24">
          <a href={`mailto:${email.hello}`} className="p-btn p-btn--primary">
            {t('intercomLauncher.error.modal.button.title', 'Send us an email')}
          </a>
        </p>
      </BottomOrRegularModal>
    );

  if (status === 'LOADING')
    return (
      <div className={styles.wrapper}>
        <div
          className={classnames('d-flex ai-center jc-center', styles.button)}
          style={{ backgroundColor: color }}
        >
          <div
            className={classnames(
              'ds-spinner ds-spinner__m',
              styles.whiteSpinner
            )}
          ></div>
        </div>
      </div>
    );

  return (
    <div className={styles.wrapper} id="intercom_custom_button">
      <button className={styles.button} onClick={handleClick}>
        <ChatIcon color={color} />
      </button>
    </div>
  );
};
