import classNames from 'classnames';
import React from 'react';

import styles from './style.module.scss';
import { Condition } from 'models/medicalConditions';

export default ({
  isDismissable = false,
  isSelected,
  condition,
  onSelect,
  onDeselect,
  ...props
}: {
  isDismissable?: boolean;
  isSelected: boolean;
  condition: Condition;
  onSelect: (condition: Condition) => void;
  onDeselect: (condition: Condition) => void;
}) => (
  <div
    data-cy="select-condition-condition-chip"
    className={classNames(styles.condition, {
      [styles['condition--selected']]: isSelected,
      [styles['condition--dismissable']]: isDismissable,
    })}
    onClick={() => {
      isSelected ? onDeselect(condition) : onSelect(condition);
    }}
    {...props}
  >
    {condition.name}
  </div>
);
