import { TreeLogic } from '..';
import { SpecifyQuestionnaireModel } from 'models/questions/specify';
import { intermediateSurchargeMatrix } from 'models/surcharge';

export class M implements TreeLogic<'M'> {
  getRejection(): boolean {
    return false;
  }

  getAssess({
    hasReceivedTreatmentForSinusInfection,
    uploadMedicalDischarge,
    typeOfIllness,
    howManyTimeHasSufferedFromEarCanalInfection,
    hasBeenCuredOfTheInfection,
    hasBeenDeemedUnfitToWorkMoreThan35Days,
    hasBeenDeemedUnfitToWorkMoreThan21Days,
  }: SpecifyQuestionnaireModel['M']) {
    if (uploadMedicalDischarge !== undefined) {
      return true;
    }

    if (hasReceivedTreatmentForSinusInfection === 'NO_TREATMENT_REQUIRED') {
      return true;
    }

    if (typeOfIllness === 'CHRONIC') {
      return true;
    }

    if (howManyTimeHasSufferedFromEarCanalInfection === 'THREE_TIMES_OR_MORE') {
      return true;
    }

    if (hasBeenCuredOfTheInfection === false) {
      return true;
    }

    if (
      hasBeenDeemedUnfitToWorkMoreThan21Days === true ||
      hasBeenDeemedUnfitToWorkMoreThan35Days === true
    ) {
      return true;
    }

    return false;
  }

  getQuestionOrder({
    hasReceivedTreatmentForSinusInfection,
    howManyTimeHasSufferedFromEarCanalInfection,
  }: SpecifyQuestionnaireModel['M']): (keyof SpecifyQuestionnaireModel['M'])[] {
    if (hasReceivedTreatmentForSinusInfection === 'IN_PATIENT') {
      return [
        'hasReceivedTreatmentForSinusInfection',
        'uploadMedicalDischarge',
      ];
    }

    if (howManyTimeHasSufferedFromEarCanalInfection === 'ONCE') {
      return [
        'hasReceivedTreatmentForSinusInfection',
        'typeOfIllness',
        'howManyTimeHasSufferedFromEarCanalInfection',
        'hasBeenCuredOfTheInfection',
        'hasBeenDeemedUnfitToWorkMoreThan21Days',
      ];
    }

    if (howManyTimeHasSufferedFromEarCanalInfection === 'TWICE') {
      return [
        'hasReceivedTreatmentForSinusInfection',
        'typeOfIllness',
        'howManyTimeHasSufferedFromEarCanalInfection',
        'hasBeenCuredOfTheInfection',
        'hasBeenDeemedUnfitToWorkMoreThan35Days',
      ];
    }

    return [
      'hasReceivedTreatmentForSinusInfection',
      'typeOfIllness',
      'howManyTimeHasSufferedFromEarCanalInfection',
    ];
  }

  getRiskSurchargeMatrix() {
    return null;
  }
}

export class N implements TreeLogic<'N'> {
  private age: number;

  constructor(age: number) {
    this.age = age;
  }

  getRejection(): boolean {
    return false;
  }

  getAssess({
    hasReceivedTreatmentForHypotension,
    uploadMedicalDischarge,
    hasBeenDeemedUnfitToWorkMoreThan7Days,
    hasBeenDeemedUnfitToWorkMoreThan12Days,
    hasBeenDeemedUnfitToWorkMoreThan18Days,
    hasBeenDeemedUnfitToWorkMoreThan25Days,
  }: SpecifyQuestionnaireModel['N']) {
    if (hasReceivedTreatmentForHypotension === 'NO_TREATMENT_REQUIRED') {
      return true;
    }

    if (uploadMedicalDischarge !== undefined) {
      return true;
    }

    if (
      hasBeenDeemedUnfitToWorkMoreThan7Days === false ||
      hasBeenDeemedUnfitToWorkMoreThan12Days === false ||
      hasBeenDeemedUnfitToWorkMoreThan18Days === false ||
      hasBeenDeemedUnfitToWorkMoreThan25Days === false
    ) {
      return true;
    }

    return false;
  }

  getQuestionOrder({
    hasReceivedTreatmentForHypotension,
    hasBeenCuredOfCondition,
    howOftenHasSufferedFromHypotension,
  }: SpecifyQuestionnaireModel['N']): (keyof SpecifyQuestionnaireModel['N'])[] {
    if (hasReceivedTreatmentForHypotension === 'IN_PATIENT') {
      return ['hasReceivedTreatmentForHypotension', 'uploadMedicalDischarge'];
    }

    if (hasBeenCuredOfCondition === false) {
      return [
        'hasReceivedTreatmentForHypotension',
        'hasBeenCuredOfCondition',
        'hasBeenDeemedUnfitToWorkMoreThan25Days',
      ];
    }

    const base: (keyof SpecifyQuestionnaireModel['N'])[] = [
      'hasReceivedTreatmentForHypotension',
      'hasBeenCuredOfCondition',
      'howOftenHasSufferedFromHypotension',
    ];

    if (howOftenHasSufferedFromHypotension === 'ONCE') {
      return [...base, 'hasBeenDeemedUnfitToWorkMoreThan7Days'];
    }

    if (howOftenHasSufferedFromHypotension === 'TWICE') {
      return [...base, 'hasBeenDeemedUnfitToWorkMoreThan12Days'];
    }

    if (howOftenHasSufferedFromHypotension === 'THREE_TIMES') {
      return [...base, 'hasBeenDeemedUnfitToWorkMoreThan18Days'];
    }

    if (howOftenHasSufferedFromHypotension === 'FOUR_TIMES_OR_MORE') {
      return [...base, 'hasBeenDeemedUnfitToWorkMoreThan25Days'];
    }

    return base;
  }

  getRiskSurchargeMatrix({
    hasBeenDeemedUnfitToWorkMoreThan18Days,
    hasBeenDeemedUnfitToWorkMoreThan25Days,
  }: SpecifyQuestionnaireModel['N']) {
    if (
      hasBeenDeemedUnfitToWorkMoreThan18Days === false ||
      hasBeenDeemedUnfitToWorkMoreThan25Days === false
    ) {
      return intermediateSurchargeMatrix(this.age);
    }

    return null;
  }
}

export class O implements TreeLogic<'O'> {
  private age: number;

  constructor(age: number) {
    this.age = age;
  }

  getRejection = (): boolean => {
    return false;
  };

  getAssess = ({
    whichCruciateLigamentHasBeenInjured,
    hasFullyHealed,
    recoveryTime,
    requiredAnOperation,
    sufferedFromMeniscusDamage,
    deemedUnfitToWorkForLongerThan180Days,
  }: SpecifyQuestionnaireModel['O']): boolean => {
    if (
      whichCruciateLigamentHasBeenInjured === 'ANTERIOR_AND_POSTERIOR' ||
      whichCruciateLigamentHasBeenInjured === 'POSTERIOR'
    ) {
      return true;
    }

    if (hasFullyHealed === false) {
      return true;
    }

    if (recoveryTime === '6_MONTHS_OR_LESS') {
      return true;
    }

    if (requiredAnOperation === false) {
      if (sufferedFromMeniscusDamage === true) {
        return true;
      }

      if (deemedUnfitToWorkForLongerThan180Days === true) {
        return true;
      }
    }

    if (
      requiredAnOperation === false &&
      deemedUnfitToWorkForLongerThan180Days === true
    ) {
      return true;
    }

    return false;
  };

  getQuestionOrder(): (keyof SpecifyQuestionnaireModel['O'])[] {
    return [
      'whichCruciateLigamentHasBeenInjured',
      'hasFullyHealed',
      'recoveryTime',
      'requiredAnOperation',
      'sufferedFromMeniscusDamage',
      'deemedUnfitToWorkForLongerThan180Days',
    ];
  }

  getRiskSurchargeMatrix({
    deemedUnfitToWorkForLongerThan180Days,
  }: SpecifyQuestionnaireModel['O']) {
    if (deemedUnfitToWorkForLongerThan180Days === false) {
      return intermediateSurchargeMatrix(this.age);
    }

    return null;
  }
}

export class P implements TreeLogic<'P'> {
  getRejection(): boolean {
    return false;
  }

  getAssess({
    hasFullyRecovered,
    hasBeenDeclaredUnfitToWorkLongerThan28Days,
    uploadMedicalDischarge,
  }: SpecifyQuestionnaireModel['P']) {
    if (uploadMedicalDischarge !== undefined) {
      return true;
    }

    if (hasFullyRecovered === false) {
      return true;
    }

    if (hasBeenDeclaredUnfitToWorkLongerThan28Days === true) {
      return true;
    }

    return false;
  }

  getQuestionOrder({
    whatKindOfCareRequired,
  }: SpecifyQuestionnaireModel['P']): (keyof SpecifyQuestionnaireModel['P'])[] {
    if (whatKindOfCareRequired === 'IN_PATIENT') {
      return ['whatKindOfCareRequired', 'uploadMedicalDischarge'];
    }

    return [
      'whatKindOfCareRequired',
      'hasFullyRecovered',
      'hasBeenDeclaredUnfitToWorkLongerThan28Days',
    ];
  }

  getRiskSurchargeMatrix() {
    return null;
  }
}

export class Q implements TreeLogic<'Q'> {
  private age: number;

  constructor(age: number) {
    this.age = age;
  }

  getRejection(): boolean {
    return false;
  }

  getAssess({
    hasSufferedFromDegenerativeJoinDiseaseInTheKnee,
    isPerformingActivitiesThatPlaceStressOnKnee,
    isOverweight,
    isFullyHealed,
    isConditionAffectingBothKnees,
    treatmentRequired,
    hasBeenDeemedUnfitToWorkMoreThan56Days,
  }: SpecifyQuestionnaireModel['Q']) {
    if (hasSufferedFromDegenerativeJoinDiseaseInTheKnee === true) {
      return true;
    }

    if (isPerformingActivitiesThatPlaceStressOnKnee === true) {
      return true;
    }

    if (isOverweight === true) {
      return true;
    }

    if (isFullyHealed === false) {
      return true;
    }

    if (isConditionAffectingBothKnees === true) {
      return true;
    }

    if (
      treatmentRequired === 'FULL_REMOVAL_OF_MENISCUS' ||
      treatmentRequired === 'OTHER' ||
      treatmentRequired === 'NO_TREATMENT'
    ) {
      return true;
    }

    if (hasBeenDeemedUnfitToWorkMoreThan56Days === true) {
      return true;
    }

    return false;
  }

  getQuestionOrder(): (keyof SpecifyQuestionnaireModel['Q'])[] {
    return [
      'hasSufferedFromDegenerativeJoinDiseaseInTheKnee',
      'isPerformingActivitiesThatPlaceStressOnKnee',
      'isOverweight',
      'isFullyHealed',
      'isConditionAffectingBothKnees',
      'treatmentRequired',
      'hasBeenDeemedUnfitToWorkMoreThan56Days',
    ];
  }

  getRiskSurchargeMatrix({
    treatmentRequired,
    hasBeenDeemedUnfitToWorkMoreThan56Days,
  }: SpecifyQuestionnaireModel['Q']) {
    if (
      treatmentRequired === 'PARTIAL_REMOVAL_OF_MENISCUS' &&
      hasBeenDeemedUnfitToWorkMoreThan56Days === false
    ) {
      return intermediateSurchargeMatrix(this.age);
    }

    return null;
  }
}

export class R implements TreeLogic<'R'> {
  getRejection(): boolean {
    return false;
  }

  getAssess({
    hasBeenTreatedForPharyngitis,
    uploadMedicalDischarge,
    hasBeenDeemedUnfitToWorkForLongerThan14Days,
    hasBeenDeemedUnfitToWorkForLongerThan28Days,
    howManyTimesHasSufferedFromPharyngitis,
  }: SpecifyQuestionnaireModel['R']) {
    if (uploadMedicalDischarge !== undefined) {
      return true;
    }

    if (hasBeenTreatedForPharyngitis === 'NO_TREATMENT_REQUIRED') {
      return true;
    }

    if (hasBeenDeemedUnfitToWorkForLongerThan14Days === true) {
      return true;
    }

    if (hasBeenDeemedUnfitToWorkForLongerThan28Days === true) {
      return true;
    }

    if (howManyTimesHasSufferedFromPharyngitis === 'FOUR_TIMES_OR_MORE') {
      return true;
    }

    return false;
  }

  getQuestionOrder({
    hasBeenTreatedForPharyngitis,
    hasFullyRecovered,
    howManyTimesHasSufferedFromPharyngitis,
  }: SpecifyQuestionnaireModel['R']): (keyof SpecifyQuestionnaireModel['R'])[] {
    if (hasBeenTreatedForPharyngitis === 'IN_PATIENT') {
      return ['hasBeenTreatedForPharyngitis', 'uploadMedicalDischarge'];
    }

    const base: (keyof SpecifyQuestionnaireModel['R'])[] = [
      'hasBeenTreatedForPharyngitis',
      'hasFullyRecovered',
      'howManyTimesHasSufferedFromPharyngitis',
    ];

    if (
      hasFullyRecovered === true &&
      howManyTimesHasSufferedFromPharyngitis === 'ONCE'
    ) {
      return [...base, 'hasBeenDeemedUnfitToWorkForLongerThan14Days'];
    }

    return [...base, 'hasBeenDeemedUnfitToWorkForLongerThan28Days'];
  }

  getRiskSurchargeMatrix() {
    return null;
  }
}

export class S implements TreeLogic<'S'> {
  getRejection(): boolean {
    return false;
  }

  getAssess({
    hasReceivedTreatmentForHypothyroidism,
    hasHadOperation,
    uploadMedicalDischarge,
    hasSufferedFromEnlargedThyroid,
    hasSufferedFromThyroidNodules,
    hasHadThyroidCysts,
    hasBeenDeclaredUnfitToWork,
  }: SpecifyQuestionnaireModel['S']) {
    if (hasReceivedTreatmentForHypothyroidism === 'NO_TREATMENT_REQUIRED') {
      return true;
    }

    if (uploadMedicalDischarge !== undefined) {
      return true;
    }

    if (hasHadOperation === true) {
      return true;
    }

    if (hasSufferedFromEnlargedThyroid === true) {
      return true;
    }

    if (hasSufferedFromThyroidNodules === true) {
      return true;
    }

    if (hasHadThyroidCysts === true) {
      return true;
    }

    if (hasBeenDeclaredUnfitToWork === true) {
      return true;
    }

    return false;
  }

  getQuestionOrder({
    hasReceivedTreatmentForHypothyroidism,
  }: SpecifyQuestionnaireModel['S']): (keyof SpecifyQuestionnaireModel['S'])[] {
    if (hasReceivedTreatmentForHypothyroidism === 'IN_PATIENT') {
      return [
        'hasReceivedTreatmentForHypothyroidism',
        'uploadMedicalDischarge',
      ];
    }

    return [
      'hasReceivedTreatmentForHypothyroidism',
      'hasHadOperation',
      'hasSufferedFromEnlargedThyroid',
      'hasSufferedFromThyroidNodules',
      'hasHadThyroidCysts',
      'hasBeenDeclaredUnfitToWork',
    ];
  }

  getRiskSurchargeMatrix() {
    return null;
  }
}
