import React from 'react';
import { DateSelector } from '@popsure/dirty-swan';
import { useTranslation } from '@getpopsure/i18n-react';
import { createDateInRangeValidator } from 'validators';
import { WrappedForm } from 'pages/questions/formWrapper';
import Center from 'components/center';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';
import moment from 'moment';
import AnimateHeight from 'react-animate-height';
export default () => {
  const { t } = useTranslation();

  const maximumFutureEmploymentStartDate = moment()
    .add(1, 'year')
    .startOf('month');

  const employmentStartDateValidator = createDateInRangeValidator({
    maxDate: maximumFutureEmploymentStartDate.toString(),
  });

  return (
    <Center>
      <h1 className="p-h1">
        {generalQuestionsTitleMapping.employmentStartDate?.(t)}
      </h1>
      <WrappedForm
        sectionId="financialHistory"
        questionId="employmentStartDate"
        validator={employmentStartDateValidator}
      >
        {({ value, setValue }) => (
          <div className="mt16">
            <DateSelector
              value={value}
              onChange={(date) => setValue(date)}
              yearBoundaries={{
                min: new Date().getFullYear() - 80,
                max: new Date().getFullYear() + 1,
              }}
            />
            {
              <AnimateHeight
                duration={300}
                height={!employmentStartDateValidator(value) ? 'auto' : 0}
              >
                <div className="p-notice p-notice--warning mt16">
                  {t('questions.employmentStartDate.error', {
                    defaultValue:
                      'Your employment contract must begin before {{maxDate}} in order for us to enroll you in private health insurance.',
                    maxDate: maximumFutureEmploymentStartDate.format(
                      'DD MMM YYYY'
                    ),
                  })}
                </div>
              </AnimateHeight>
            }
          </div>
        )}
      </WrappedForm>
    </Center>
  );
};
