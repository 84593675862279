import React from 'react';
import { useTranslation, TranslationFn } from '@getpopsure/i18n-react';

import { WrappedForm } from 'pages/questions/formWrapper';
import { generateRadioInput } from 'pages/questions/generateInput';
import { nonEmptyValidator } from 'validators';
import Center from 'components/center';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';
import { MaritalStatus } from 'models';

const maritalStatusMapping = (
  t: TranslationFn
): { [K in MaritalStatus]: string } => ({
  SINGLE: t('questions.maritalstatus.mapping.single', 'Single'),
  MARRIED: t('questions.maritalstatus.mapping.married', 'Married'),
  WIDOWED: t('questions.maritalstatus.mapping.widowed', 'Widowed'),
  DIVORCED: t('questions.maritalstatus.mapping.divorced', 'Divorced'),
});

export default () => {
  const { t } = useTranslation();

  return (
    <Center>
      <h1 className="p-h1">
        {generalQuestionsTitleMapping.maritalStatus?.(t)}
      </h1>
      <WrappedForm
        validator={nonEmptyValidator}
        sectionId="personalInfo"
        questionId="maritalStatus"
        label={maritalStatusMapping(t)}
      >
        {({ value, setValue }) => (
          <div className="mt8">
            {generateRadioInput(
              'maritalStatus',
              value,
              maritalStatusMapping(t),
              setValue
            )}
          </div>
        )}
      </WrappedForm>
    </Center>
  );
};
