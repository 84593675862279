import React from 'react';
import { Input } from '@popsure/dirty-swan';
import { useTranslation } from '@getpopsure/i18n-react';

import styles from './style.module.scss';
import { WrappedForm } from 'pages/questions/formWrapper';
import { nameValidator } from 'validators';
import Center from 'components/center';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';

export default () => {
  const { t } = useTranslation();

  return (
    <Center>
      <h1 className="p-h1">{generalQuestionsTitleMapping.name?.(t)}</h1>
      <WrappedForm
        validator={nameValidator}
        sectionId="personalInfo"
        questionId="name"
      >
        {({ value, setValue }) => (
          <>
            <div className="mt8">
              <div>
                <Input
                  className="w100 wmx5"
                  placeholder={t(
                    'questions.name.firstname.input.placeholder',
                    'First name'
                  )}
                  type="text"
                  id="firstName"
                  data-cy="questionnaire-name-firstname"
                  value={value?.firstName ?? ''}
                  onChange={(n) => {
                    setValue({
                      firstName: n.target.value,
                      lastName: value?.lastName ?? '',
                    });
                  }}
                  required={true}
                />
              </div>
              <div className={styles['last-name']}>
                <Input
                  className="w100 wmx5"
                  placeholder={t(
                    'questions.name.lastname.input.placeholder',
                    'Last name'
                  )}
                  type="text"
                  id="lastName"
                  data-cy="questionnaire-name-lastname"
                  value={value?.lastName ?? ''}
                  onChange={(n) => {
                    setValue({
                      lastName: n.target.value,
                      firstName: value?.firstName ?? '',
                    });
                  }}
                  required={true}
                />
              </div>
            </div>
          </>
        )}
      </WrappedForm>
    </Center>
  );
};
