import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { ViewProps } from '..';
import styles from './style.module.scss';
import { DisplayLiveQuoteContext } from 'Root';

export default ({
  percentage,
  previousUrl,
  currentQuestion,
  totalNumberOfQuestions,
  display,
}: ViewProps) => {
  const { toggleDisplayLiveQuote } = useContext(DisplayLiveQuoteContext);

  useEffect(() => {
    toggleDisplayLiveQuote?.(display);
  }, [display, toggleDisplayLiveQuote]);

  return (
    <div
      className={`${styles.footer} ${
        display ? styles['footer--display'] : styles['footer--hide']
      }`}
    >
      {previousUrl ? (
        <Link className={styles.back} to={previousUrl} replace={false} />
      ) : (
        <button className={styles.back} disabled={true} />
      )}
      <div className={styles['progress-container']}>
        <div
          className={styles.percentage}
          style={{ width: `${percentage}%` }}
        />
      </div>
      <div className={styles.counter}>
        {currentQuestion}/{totalNumberOfQuestions}
      </div>
    </div>
  );
};
