import moment from 'moment';
import { TranslationFn } from '@getpopsure/i18n-react';

import { UploadedFile } from 'models';
import { AppState } from 'reducers';
import { addQuestionAfter } from '..';

// TODO: have this exported to public-models

export const employmentStatusMapping = (
  t: TranslationFn
): Record<QNREmploymentStatus, string> => ({
  EMPLOYED: t('questions.employmenstatus.mapping.employed', 'Employed'),
  SELF_EMPLOYED: t(
    'questions.employmenstatus.mapping.selfemployed',
    'Self employed'
  ),
  CIVIL_SERVANT: t(
    'questions.employmenstatus.mapping.civilservant',
    'Civil servant'
  ),
  UNIVERSITY_STUDENT: t(
    'questions.employmenstatus.mapping.unistudent',
    'University student'
  ),
  OTHER: t('questions.employmenstatus.mapping.otherOccupation', 'Other'),
});

export type QNREmploymentStatus =
  | 'UNIVERSITY_STUDENT'
  | 'EMPLOYED'
  | 'SELF_EMPLOYED'
  | 'CIVIL_SERVANT'
  | 'OTHER';

export type PreviousActivity = 'EMPLOYED' | 'NOT_WORKING' | 'OTHER';

export const previousActivityMapping = (
  t: TranslationFn
): {
  [K in PreviousActivity]: string;
} => ({
  EMPLOYED: t('questions.previousactivity.mapping.employed', 'Employed'),
  NOT_WORKING: t(
    'questions.previousactivity.mapping.notworking',
    'Not working'
  ),
  OTHER: t('questions.previousactivity.mapping.otherActivity', 'Other'),
});

export type OtherEmploymentKind = 'UNEMPLOYED' | 'SPOUSE' | 'OTHER';

export const otherEmploymentKindMapping = (
  t: TranslationFn
): { [K in OtherEmploymentKind]: string } => ({
  UNEMPLOYED: t('questions.otheremployment.mapping.unemployed', 'Unemployed'),
  SPOUSE: t('questions.otheremployment.mapping.spouse', 'Spouse'),
  OTHER: t('questions.otheremployment.mapping.otherOccupation', 'Other'),
});

export type FinancialHistoryQuestion =
  | {
      id: 'employmentStatus';
      answer: QNREmploymentStatus;
    }
  | {
      id: 'employerName';
      answer: string;
    }
  | {
      id: 'employmentStartDate';
      answer: string;
    }
  | {
      id: 'workContract';
      answer: UploadedFile[];
    }
  | {
      id: 'income';
      answer: number;
    }
  | {
      id: 'whatIsYourJobTitle'; // <--- start self-employed
      answer: string;
    }
  | {
      id: 'workingAsFreelancerSince';
      answer: string;
    }
  | {
      id: 'incomeEstimate';
      answer: number;
    }
  | {
      id: 'incomeExpectations';
      answer: number;
    }
  | {
      id: 'jobDescription';
      answer: string;
    }
  | {
      id: 'previousActivity';
      answer: PreviousActivity;
    }
  | {
      id: 'previousActivityExplanation';
      answer: string;
    }
  | {
      id: 'monthlyIncomeBeforeTaxWhenEmployed';
      answer: number;
    }
  | {
      id: 'bankStatement';
      answer: UploadedFile[];
    }
  | {
      id: 'incomeStatement';
      answer: UploadedFile[];
    }
  | {
      id: 'futureIncome';
      answer: UploadedFile[];
    }
  | {
      id: 'hasLinkedInProfile';
      answer: boolean;
    }
  | {
      id: 'linkedInProfileUrl';
      answer: string;
    }
  | {
      id: 'hasWebsite';
      answer: boolean;
    }
  | {
      id: 'websiteUrl'; // <--- end self-employed
      answer: string;
    }
  | {
      id: 'whatKindOfOtherEmployment';
      answer: OtherEmploymentKind;
    }
  | {
      id: 'hasGermanTaxId';
      answer: boolean;
    }
  | {
      id: 'germanTaxId';
      answer: string;
    }
  | {
      id: 'schufaConsent';
      answer: boolean;
    }
  | {
      id: 'financialHistoryReview';
      answer: boolean;
    }
  | {
      id: 'selfEmploymentIntro';
      answer: boolean;
    }
  | {
      id: 'employedOutsideGermany';
      answer: boolean;
    };

export const financialHistoryQuestionOrder = (
  state: AppState
): FinancialHistoryQuestion['id'][] => {
  const employmentStatus =
    state.questionnaire.financialHistory?.employmentStatus;
  const hasTaxId = state.questionnaire.financialHistory?.hasGermanTaxId;
  const taxIdQuestions: FinancialHistoryQuestion['id'][] = hasTaxId
    ? ['hasGermanTaxId', 'germanTaxId']
    : ['hasGermanTaxId'];

  const order: FinancialHistoryQuestion['id'][] = [
    'employmentStatus',
    ...taxIdQuestions,
    'schufaConsent',
    'financialHistoryReview',
  ];

  if (employmentStatus === undefined) {
    return ['employmentStatus'];
  }

  switch (employmentStatus) {
    case 'EMPLOYED': {
      const employmentQuestions: FinancialHistoryQuestion['id'][] = [
        'whatIsYourJobTitle',
        'employerName',
        'employmentStartDate',
        'workContract',
        'income',
        'employedOutsideGermany',
      ];

      const employedOrder = addQuestionAfter({
        afterQuestion: 'employmentStatus',
        order,
        questionsToAdd: employmentQuestions,
      }) as FinancialHistoryQuestion['id'][];

      return employedOrder;
    }

    case 'SELF_EMPLOYED':
      let selfEmployedOrder;

      selfEmployedOrder = addQuestionAfter({
        afterQuestion: 'employmentStatus',
        order,
        questionsToAdd: [
          'whatIsYourJobTitle',
          'selfEmploymentIntro',
          'workingAsFreelancerSince',
        ],
      });

      if (!state.questionnaire.financialHistory?.workingAsFreelancerSince) {
        return selfEmployedOrder as FinancialHistoryQuestion['id'][];
      }

      const today = new Date();
      const freelancerSince = new Date(
        state.questionnaire.financialHistory?.workingAsFreelancerSince
      );

      const diff = moment(today).diff(moment(freelancerSince), 'months', false);

      if (diff < 12) {
        selfEmployedOrder = addQuestionAfter({
          afterQuestion: 'workingAsFreelancerSince',
          order: selfEmployedOrder,
          questionsToAdd: ['incomeExpectations', 'previousActivity'],
        });

        const previousActivity =
          state.questionnaire.financialHistory?.previousActivity;

        if (
          previousActivity === 'OTHER' ||
          previousActivity === 'NOT_WORKING'
        ) {
          selfEmployedOrder = addQuestionAfter({
            afterQuestion: 'previousActivity',
            order: selfEmployedOrder,
            questionsToAdd: [
              'previousActivityExplanation',
              'bankStatement',
              'incomeStatement',
              'futureIncome',
              'jobDescription',
            ],
          });
        }

        if (previousActivity === 'EMPLOYED') {
          selfEmployedOrder = addQuestionAfter({
            afterQuestion: 'previousActivity',
            order: selfEmployedOrder,
            questionsToAdd: [
              'monthlyIncomeBeforeTaxWhenEmployed',
              'bankStatement',
              'incomeStatement',
              'futureIncome',
              'jobDescription',
            ],
          });
        }
      }

      if (diff >= 12) {
        selfEmployedOrder = addQuestionAfter({
          afterQuestion: 'workingAsFreelancerSince',
          order: selfEmployedOrder,
          questionsToAdd: [
            'incomeEstimate',
            'bankStatement',
            'incomeStatement',
            'futureIncome',
            'jobDescription',
          ],
        });
      }

      selfEmployedOrder = addQuestionAfter({
        afterQuestion: 'jobDescription',
        order: selfEmployedOrder,
        questionsToAdd: ['hasLinkedInProfile'],
      });

      if (state.questionnaire.financialHistory?.hasLinkedInProfile === true) {
        selfEmployedOrder = addQuestionAfter({
          afterQuestion: 'hasLinkedInProfile',
          order: selfEmployedOrder,
          questionsToAdd: ['linkedInProfileUrl'],
        });
      }

      if (state.questionnaire.financialHistory?.hasLinkedInProfile === false) {
        selfEmployedOrder = addQuestionAfter({
          afterQuestion: 'hasLinkedInProfile',
          order: selfEmployedOrder,
          questionsToAdd: ['hasWebsite'],
        });
      }

      if (state.questionnaire.financialHistory?.hasWebsite === true) {
        selfEmployedOrder = addQuestionAfter({
          afterQuestion: 'hasWebsite',
          order: selfEmployedOrder,
          questionsToAdd: ['websiteUrl'],
        });
      }

      return selfEmployedOrder as FinancialHistoryQuestion['id'][];

    case 'OTHER':
      return addQuestionAfter({
        afterQuestion: 'employmentStatus',
        order,
        questionsToAdd: ['whatKindOfOtherEmployment'],
      }) as FinancialHistoryQuestion['id'][];
    case 'UNIVERSITY_STUDENT':
    case 'CIVIL_SERVANT':
    default:
      return ['employmentStatus'];
  }
};
