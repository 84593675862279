import React from 'react';
import { MultiDropzone } from '@popsure/dirty-swan';
import { useTranslation, Trans } from '@getpopsure/i18n-react';
import moment from 'moment';
import Center from 'components/center';
import connectedAnswer, {
  ViewProps as GenericViewProps,
} from 'pages/questions/connectedAnswer';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';
import { useUploadFiles } from 'utils/hooks';
import { insurance } from '@getpopsure/private-constants';
import { englishFormattedEuroCurrency } from '@getpopsure/public-utility';

const FREELANCER_THRESHOLD_UNDER_51 =
  insurance.privateHealth.freelancerThresholdUnder51;

const FREELANCER_THRESHOLD_51_AND_OVER =
  insurance.privateHealth.freelancerThreshold51OrOlder;

const View = ({
  onAnswerQuestion,
  storeAnsweredQuestion,
  answer,
  questionnaireAnswers,
}: GenericViewProps<'financialHistory', 'incomeStatement'>) => {
  const { t } = useTranslation();
  const {
    isSubmitValid,
    uploadFile,
    uploadedFiles,
    removeFile,
    loading,
  } = useUploadFiles({
    initialValue: answer ?? [],
    questionId: 'incomeStatement',
    onUploadComplete: (uploadedFiles) => storeAnsweredQuestion(uploadedFiles),
  });

  const age = moment().diff(
    questionnaireAnswers?.personalInfo?.dateOfBirth,
    'years'
  );

  const threshold =
    age < 51 ? FREELANCER_THRESHOLD_UNDER_51 : FREELANCER_THRESHOLD_51_AND_OVER;

  const formattedTreshold = englishFormattedEuroCurrency(threshold, true);

  return (
    <Center>
      <h1 className="p-h1">
        {generalQuestionsTitleMapping.incomeStatement?.(t)}
      </h1>
      <Trans i18nKey="questions.incomeStatement.description">
        <p className="p-p mt8">
          Please provide any other documents (e.g.{' '}
          <span className="fw-bold">tax returns</span>, invoices issued to
          clients or other assets) that demonstrate your earnings in the last 12
          months to be above {{ formattedTreshold }} in total.
        </p>
      </Trans>
      <div className="mt16">
        <MultiDropzone
          onFileSelect={uploadFile}
          uploading={loading}
          uploadedFiles={uploadedFiles}
          onRemoveFile={removeFile}
          maxFiles={1}
        />
      </div>
      <button
        data-cy="questionnaire-continue"
        className="p-btn--primary wmn3 mt24"
        type="submit"
        disabled={!isSubmitValid}
        onClick={() => {
          if (!isSubmitValid) {
            return;
          }

          onAnswerQuestion(uploadedFiles);
        }}
      >
        {t('questions.incomeStatement.continue.action', 'Continue')}
      </button>
      <button
        data-cy="questionnaire-skip"
        className="p-btn--secondary wmn3 ml16"
        type="button"
        onClick={() => onAnswerQuestion([])}
      >
        {t('questions.incomeStatement.skip.action', 'Provide later')}
      </button>
    </Center>
  );
};

export default connectedAnswer('financialHistory', 'incomeStatement')(View);
