import { connect } from 'react-redux';

import View from './component';
import { AppState } from 'reducers';
import { getLastReachableQuestion } from 'selectors';
import { restartQuestionnaire } from 'actions';
import { pathForQuestion } from 'routes/path';

interface StateProps {
  name?: {
    firstName: string;
    lastName: string;
  };
  lastAnsweredQuestionUrl: string;
}

interface DispatchProps {
  onRestartQuestionnaire: () => void;
}

interface OwnProps {
  children: JSX.Element;
}

export type ViewProps = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (state: AppState): StateProps => {
  const question = getLastReachableQuestion(state);
  const lastAnsweredQuestionUrl = pathForQuestion(question);

  if (!lastAnsweredQuestionUrl) {
    throw new Error(`Can't find path for question:${question}`);
  }

  return {
    name: state.questionnaire?.personalInfo?.name,
    lastAnsweredQuestionUrl,
  };
};

const mapDispatchToProps = (dispatch: any): DispatchProps => {
  return {
    onRestartQuestionnaire: () => {
      dispatch(restartQuestionnaire());
    },
  };
};

export default connect<StateProps, DispatchProps, OwnProps, AppState>(
  mapStateToProps,
  mapDispatchToProps
)(View);
