import {
  IsEligibleUserResponse,
  ReferralCodeVerifyRequest,
} from 'features/referralEngine/models';
import { instance } from 'network/api';

export const isEligibleUser = (requestBody: ReferralCodeVerifyRequest) =>
  instance.post<ReferralCodeVerifyRequest, IsEligibleUserResponse>(
    '/referral-engine/referral/is-eligible-user',
    requestBody
  );
