/* eslint-disable no-fallthrough */
import moment from 'moment';
import { AppState } from 'reducers';
import { TranslationFn } from '@getpopsure/i18n-react';

export type PreviousHealthInsuranceType =
  | 'PUBLIC_INSURANCE'
  | 'PRIVATE_INSURANCE'
  | 'EXPAT_INSURANCE'
  | 'TRAVEL_INSURANCE'
  | 'NONE';

export type PreviousHealthInsuranceCoverageOptions =
  | 'IN_PATIENT_MEDICAL_TREATMENTS'
  | 'OUT_PATIENT_MEDICAL_TREATMENTS'
  | 'OUT_OF_POCKET_LIMIT_UNDER_5K_PER_YEAR';

export type StartDateContentVariation =
  | 'ON_PUBLIC_IN_DE_AND_EMPLOYED_AND_EMPLOYMENT_START_UNDER_2_MONTHS'
  | 'ON_PUBLIC_IN_DE_AND_EMPLOYED_AND_EMPLOYMENT_START_2_MONTHS_AND_OVER'
  | 'ON_PUBLIC_IN_DE_AND_SELF_EMPLOYED_AND_SELF_EMPLOYMENT_START_UNDER_2_MONTHS'
  | 'ON_PUBLIC_IN_DE_AND_SELF_EMPLOYED_AND_SELF_EMPLOYMENT_START_2_MONTHS_AND_OVER'
  | 'NOT_ON_PUBLIC_OR_PRIVATE_IN_DE_AND_EMPLOYED'
  | 'NOT_ON_PUBLIC_OR_PRIVATE_IN_DE_AND_NOT_EMPLOYED'
  | 'GENERIC';

export const getPreviousHealthInsuranceOptionsTextMapping = (
  t: TranslationFn
): Record<PreviousHealthInsuranceType, string> => ({
  PUBLIC_INSURANCE: t(
    'questions.mostRecentHealthInsurance.mapping.public.text',
    'Public insurance'
  ),
  EXPAT_INSURANCE: t(
    'questions.mostRecentHealthInsurance.mapping.expat.text',
    'Expat/incoming insurance'
  ),
  TRAVEL_INSURANCE: t(
    'questions.mostRecentHealthInsurance.mapping.travel.text',
    'Travel health insurance'
  ),
  PRIVATE_INSURANCE: t(
    'questions.mostRecentHealthInsurance.mapping.private.text',
    'Private insurance'
  ),
  NONE: t('questions.mostRecentHealthInsurance.mapping.none.text', 'None'),
});

export const getPreviousHealthInsuranceOptionsSecondaryTextMapping = (
  t: TranslationFn
): Partial<Record<PreviousHealthInsuranceType, string>> => ({
  PUBLIC_INSURANCE: t(
    'questions.mostRecentHealthInsurance.mapping.public.secondaryText',
    'Including state healthcare systems (e.g. NHS, Medicare)'
  ),
});

export const getPreviousHealthInsuranceCoverageOptionsTextMapping = (
  t: TranslationFn
): Record<PreviousHealthInsuranceCoverageOptions, string> => ({
  IN_PATIENT_MEDICAL_TREATMENTS: t(
    'questions.whatsCovered.mapping.inPatientMedicalTreatments.text',
    'In-patient medical treatments'
  ),
  OUT_PATIENT_MEDICAL_TREATMENTS: t(
    'questions.whatsCovered.mapping.outPatientMedicalTreatments.text',
    'Out-patient medical treatments'
  ),
  OUT_OF_POCKET_LIMIT_UNDER_5K_PER_YEAR: t(
    'questions.whatsCovered.mapping.outOfPocketLimit.text',
    'Out-of-pocket limit under €5000 per year'
  ),
});

export const getPreviousHealthInsuranceCoverageOptionsSecondaryTextMapping = (
  t: TranslationFn
): Partial<Record<PreviousHealthInsuranceCoverageOptions, string>> => ({
  IN_PATIENT_MEDICAL_TREATMENTS: t(
    'questions.whatsCovered.mapping.inPatientMedicalTreatments.secondaryText',
    'In-hospital treatments with an overnight stay'
  ),
  OUT_PATIENT_MEDICAL_TREATMENTS: t(
    'questions.whatsCovered.mapping.outPatientMedicalTreatments.secondaryText',
    'General and specialist doctor visits'
  ),
  OUT_OF_POCKET_LIMIT_UNDER_5K_PER_YEAR: t(
    'questions.whatsCovered.mapping.outOfPocketLimit.secondaryText',
    'An out-of-pocket limit is a cap, or maximum, on the amount of money you have to pay for covered health care services in a calendar year. This is also called the annual deductible.'
  ),
});

export type InsuranceHistoryQuestion =
  | {
      id: 'mostRecentHealthInsurance';
      answer: PreviousHealthInsuranceType;
    }
  | {
      id: 'alreadyOnPublicViaEmployer';
      answer: boolean;
    }
  | {
      id: 'whatWasCovered';
      answer: PreviousHealthInsuranceCoverageOptions[] | string;
    }
  | {
      id: 'hadGermanHealthProvider';
      answer: boolean;
    }
  | {
      id: 'previousProviderName';
      answer: string;
    }
  | {
      id: 'endOfPreviousHealthInsurance';
      answer: string;
    }
  | {
      id: 'startDate';
      answer: string;
    }
  | {
      id: 'hasBeenHealthInsuredAtAllTimes';
      answer: boolean;
    }
  | {
      id: 'livingInGermany';
      answer: boolean;
    }
  | {
      id: 'insuranceHistoryV2Review';
      answer: boolean;
    };

const getNextQuestion = (
  currentQuestion: InsuranceHistoryQuestion['id'],
  state: AppState
): InsuranceHistoryQuestion['id'] | null => {
  const insuranceHistoryV2 = state.questionnaire.insuranceHistory;
  const employmentStartDate =
    state.questionnaire.financialHistory?.employmentStartDate;
  const employmentStatus =
    state.questionnaire.financialHistory?.employmentStatus;
  const employedOutsideGermany =
    state.questionnaire.financialHistory?.employedOutsideGermany;
  const mostRecentHealthInsurance =
    insuranceHistoryV2?.mostRecentHealthInsurance || '';
  const hadGermanHealthProvider = insuranceHistoryV2?.hadGermanHealthProvider;
  const endOfPreviousHealthInsurance =
    insuranceHistoryV2?.endOfPreviousHealthInsurance;

  const hasGermanEmployer =
    employmentStatus === 'EMPLOYED' && !employedOutsideGermany;

  let monthsSinceEmploymentStartDate = 0;
  let monthsSinceEndOfPreviousInsurance = 0;

  if (hasGermanEmployer) {
    const todayDate = moment();
    monthsSinceEmploymentStartDate = todayDate.diff(
      moment(employmentStartDate),
      'months'
    );
    monthsSinceEndOfPreviousInsurance = todayDate.diff(
      moment(endOfPreviousHealthInsurance),
      'months'
    );
  }

  const shouldBeOnGermanPublicHealthInsurance =
    hasGermanEmployer && monthsSinceEmploymentStartDate >= 2;

  switch (currentQuestion) {
    case 'mostRecentHealthInsurance':
      if (
        shouldBeOnGermanPublicHealthInsurance &&
        ['EXPAT_INSURANCE', 'TRAVEL_INSURANCE', 'NONE'].includes(
          mostRecentHealthInsurance
        )
      ) {
        return 'alreadyOnPublicViaEmployer';
      }

      switch (mostRecentHealthInsurance) {
        case 'EXPAT_INSURANCE':
          return 'whatWasCovered';

        case 'TRAVEL_INSURANCE':
          return 'previousProviderName';

        case 'PUBLIC_INSURANCE':
        case 'PRIVATE_INSURANCE':
          return 'hadGermanHealthProvider';
      }

    case 'alreadyOnPublicViaEmployer':
      return 'startDate';

    case 'whatWasCovered':
      return 'previousProviderName';

    case 'hadGermanHealthProvider':
      if (shouldBeOnGermanPublicHealthInsurance && !hadGermanHealthProvider) {
        return 'alreadyOnPublicViaEmployer';
      }

      if (
        (mostRecentHealthInsurance === 'PUBLIC_INSURANCE' &&
          !hadGermanHealthProvider) ||
        (mostRecentHealthInsurance === 'PRIVATE_INSURANCE' &&
          hadGermanHealthProvider)
      ) {
        return 'previousProviderName';
      }

      return 'endOfPreviousHealthInsurance';

    case 'previousProviderName':
      return 'endOfPreviousHealthInsurance';

    case 'endOfPreviousHealthInsurance':
      if (
        shouldBeOnGermanPublicHealthInsurance &&
        monthsSinceEndOfPreviousInsurance >= 2
      ) {
        return 'alreadyOnPublicViaEmployer';
      }

      return 'startDate';

    case 'startDate':
      return 'hasBeenHealthInsuredAtAllTimes';

    case 'hasBeenHealthInsuredAtAllTimes':
      return 'livingInGermany';

    case 'livingInGermany':
      return 'insuranceHistoryV2Review';

    case 'insuranceHistoryV2Review':
      return null;
  }
};

export const insuranceHistoryQuestionOrder = (
  state: AppState
): InsuranceHistoryQuestion['id'][] => {
  let currentQuestion: InsuranceHistoryQuestion['id'] =
    'mostRecentHealthInsurance';
  let nextQuestion = getNextQuestion(currentQuestion, state);

  const newOrder = ['mostRecentHealthInsurance'];

  while (nextQuestion) {
    newOrder.push(nextQuestion);
    currentQuestion = nextQuestion;
    nextQuestion = getNextQuestion(currentQuestion, state);
  }

  return newOrder as InsuranceHistoryQuestion['id'][];
};
