import React, { useState } from 'react';
import { parsePhoneNumberFromString } from 'libphonenumber-js/max';
import { useTranslation } from '@getpopsure/i18n-react';

import AnimateHeight from 'react-animate-height';
import ReactMarkdown from 'react-markdown';

import Phone from 'components/input/phone';
import { InfoHintSummarized } from 'components/infoHint';
import { checkPhoneNumber } from './util';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';
import { questionnaireLegalText } from 'models/questions/text/legal';

import styles from './style.module.scss';

interface ViewProps {
  onAnswerQuestion: (answer: string) => void;
  answer: string | null;
}

export const View = ({ answer, onAnswerQuestion }: ViewProps) => {
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const [value, setValue] = useState(answer ?? '');

  const valid = value.length > 0;

  const handlePhoneSubmission = (
    event:
      | React.FormEvent<HTMLFormElement>
      | React.KeyboardEvent<HTMLInputElement>
  ): void => {
    event.preventDefault();
    const phoneNumber = parsePhoneNumberFromString(
      value.startsWith('+') ? value : '+' + value
    );

    if (!phoneNumber) {
      setError('You have to enter a phone number to continue');
      return;
    }

    const phoneNumberError = checkPhoneNumber(phoneNumber);

    if (phoneNumberError) {
      setError(phoneNumberError);
      return;
    }

    onAnswerQuestion(phoneNumber.format('E.164'));
  };

  return (
    <>
      <h1 className="p-h1 ta-center">
        {generalQuestionsTitleMapping.phoneNumber?.(t)}
      </h1>
      <div className="mt40 w100 wmx4 ml-auto mr-auto">
        <form onSubmit={handlePhoneSubmission}>
          <Phone
            value={value}
            onChange={setValue}
            placeholder="Telephone number"
            onEnterKeyPress={handlePhoneSubmission}
          />
          <AnimateHeight duration={300} height={error?.length > 0 ? 'auto' : 0}>
            <div className={`p-notice--danger mt16 ws4 wmx100 w100`}>
              {error}
            </div>
          </AnimateHeight>
          <InfoHintSummarized
            title="Why are these my only options?"
            summary={
              <>
                <p>
                  This number will be used to activate two factor
                  authentication, as well as act as a digital signature for the
                  contract.
                </p>
                <p className="mt8">
                  By providing your phone number you agree to be contacted.
                  <span className="tc-primary-500 fw-bold ml8">Learn more</span>
                </p>
              </>
            }
          >
            <ReactMarkdown className={styles['text-container']}>
              {questionnaireLegalText.personalInfo.phoneNumber}
            </ReactMarkdown>
          </InfoHintSummarized>
          <button
            data-cy="questionnaire-continue"
            className="p-btn--primary w100 wmx4 mt24"
            type="submit"
            disabled={!valid}
          >
            Continue
          </button>
        </form>
      </div>
    </>
  );
};

export default View;
