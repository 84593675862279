import React from 'react';
import { DateSelector } from '@popsure/dirty-swan';
import { useTranslation } from '@getpopsure/i18n-react';
import { nonEmptyStringValidator } from 'validators';
import Center from 'components/center';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';
import { WrappedForm } from 'pages/questions/formWrapper';

export default () => {
  const { t } = useTranslation();

  const skipCaptionButtonCaption = t(
    'questions.endOfPreviousHealthInsurance.skip.action',
    "It's still active"
  );

  return (
    <Center>
      <h1 className="p-h1">
        {generalQuestionsTitleMapping.endOfPreviousHealthInsurance?.(t)}
      </h1>
      <WrappedForm
        validator={nonEmptyStringValidator}
        sectionId="insuranceHistory"
        questionId="endOfPreviousHealthInsurance"
        skipButtonProps={{
          caption: skipCaptionButtonCaption,
          skipValue: skipCaptionButtonCaption,
        }}
      >
        {({ value, setValue }) => (
          <div className="mt16">
            <DateSelector
              value={value}
              onChange={(date) => setValue(date)}
              yearBoundaries={{
                min: 1990,
                max: new Date().getFullYear() + 1,
              }}
            />
          </div>
        )}
      </WrappedForm>
    </Center>
  );
};
