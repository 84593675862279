import { TreeLogic } from '..';
import { SpecifyQuestionnaireModel } from 'models/questions/specify';

export class I implements TreeLogic<'I'> {
  getRejection = (): boolean => {
    return false;
  };

  getAssess = ({
    hasFullyRecovered,
    hasBeenDeemedUnfitToWork,
    uploadMedicalDischarge,
  }: SpecifyQuestionnaireModel['I']) => {
    if (uploadMedicalDischarge !== undefined) {
      return true;
    }

    if (hasFullyRecovered === false) {
      return true;
    }

    if (hasBeenDeemedUnfitToWork === true) {
      return true;
    }

    return false;
  };

  getQuestionOrder({
    hasReceivedTreatmentForFluLikeIllness,
  }: SpecifyQuestionnaireModel['I']): (keyof SpecifyQuestionnaireModel['I'])[] {
    if (hasReceivedTreatmentForFluLikeIllness === 'IN_PATIENT') {
      return [
        'hasReceivedTreatmentForFluLikeIllness',
        'uploadMedicalDischarge',
      ];
    }

    return [
      'hasReceivedTreatmentForFluLikeIllness',
      'hasFullyRecovered',
      'hasBeenDeemedUnfitToWork',
    ];
  }

  getRiskSurchargeMatrix() {
    return null;
  }
}

export class J implements TreeLogic<'J'> {
  getRejection = (): boolean => {
    return false;
  };

  getAssess = ({
    hasFullyRecovered,
    hasBeenDeemedUnfitToWork,
    uploadMedicalDischarge,
  }: SpecifyQuestionnaireModel['J']) => {
    if (uploadMedicalDischarge !== undefined) {
      return true;
    }

    if (hasFullyRecovered === false) {
      return true;
    }

    if (hasBeenDeemedUnfitToWork === true) {
      return true;
    }

    return false;
  };

  getQuestionOrder({
    hasReceivedTreatmentForInfluenzaLikeIllness,
  }: SpecifyQuestionnaireModel['J']): (keyof SpecifyQuestionnaireModel['J'])[] {
    if (hasReceivedTreatmentForInfluenzaLikeIllness === 'IN_PATIENT') {
      return [
        'hasReceivedTreatmentForInfluenzaLikeIllness',
        'uploadMedicalDischarge',
      ];
    }

    return [
      'hasReceivedTreatmentForInfluenzaLikeIllness',
      'hasFullyRecovered',
      'hasBeenDeemedUnfitToWork',
    ];
  }

  getRiskSurchargeMatrix() {
    return null;
  }
}

export class K implements TreeLogic<'K'> {
  getRejection = (): boolean => {
    return false;
  };

  getAssess = ({
    hasReceivedTreatmentForSoreThroat,
    howManyTimesHasReceivedTreatment,
    uploadMedicalDischarge,
    howManyTimesHasReceivedTreatmentForSoreThroat,
    hasBeenDeemedUnfitToWorkForMoreThan7Days,
  }: SpecifyQuestionnaireModel['K']) => {
    if (uploadMedicalDischarge !== undefined) {
      return true;
    }

    if (hasReceivedTreatmentForSoreThroat === 'NO_TREATMENT_REQUIRED') {
      return true;
    }

    if (
      howManyTimesHasReceivedTreatmentForSoreThroat === 'FOUR_TIMES_OR_MORE'
    ) {
      return true;
    }

    if (hasBeenDeemedUnfitToWorkForMoreThan7Days === true) {
      return true;
    }

    if (howManyTimesHasReceivedTreatment === 'THREE_TIMES_OR_MORE') {
      return true;
    }

    return false;
  };

  getQuestionOrder({
    hasReceivedTreatmentForSoreThroat,
    howManyTimesHasReceivedTreatmentForSoreThroat,
    howManyTimesHasReceivedTreatment,
    hasFullyRecovered,
  }: SpecifyQuestionnaireModel['K']): (keyof SpecifyQuestionnaireModel['K'])[] {
    if (hasReceivedTreatmentForSoreThroat === 'IN_PATIENT') {
      return ['hasReceivedTreatmentForSoreThroat', 'uploadMedicalDischarge'];
    }

    if (hasFullyRecovered === true) {
      if (
        howManyTimesHasReceivedTreatment === 'ONCE' ||
        howManyTimesHasReceivedTreatment === 'TWICE'
      ) {
        return [
          'hasReceivedTreatmentForSoreThroat',
          'hasFullyRecovered',
          'howManyTimesHasReceivedTreatment',
          'hasBeenDeemedUnfitToWorkForMoreThan7Days',
        ];
      }
      return [
        'hasReceivedTreatmentForSoreThroat',
        'hasFullyRecovered',
        'howManyTimesHasReceivedTreatment',
      ];
    }

    if (hasFullyRecovered === false) {
      if (
        howManyTimesHasReceivedTreatmentForSoreThroat === 'FOUR_TIMES_OR_MORE'
      ) {
        return [
          'hasReceivedTreatmentForSoreThroat',
          'hasFullyRecovered',
          'howManyTimesHasReceivedTreatmentForSoreThroat',
        ];
      }

      return [
        'hasReceivedTreatmentForSoreThroat',
        'hasFullyRecovered',
        'howManyTimesHasReceivedTreatmentForSoreThroat',
        'hasBeenDeemedUnfitToWorkForMoreThan7Days',
      ];
    }

    return ['hasReceivedTreatmentForSoreThroat', 'hasFullyRecovered'];
  }

  getRiskSurchargeMatrix() {
    return null;
  }
}

export class L implements TreeLogic<'L'> {
  getRejection = (): boolean => {
    return false;
  };

  getAssess = ({
    hasReceivedTreatmentForEarCanalInfection,
    typeOfIllness,
    hasBeenCuredOfTheInfection,
    hasBeenDeemedUnfitToWorkForMoreThan28Days,
    hasBeenDeemedUnfitToWorkForMoreThan42Days,
    uploadMedicalDischarge,
  }: SpecifyQuestionnaireModel['L']) => {
    if (uploadMedicalDischarge !== undefined) {
      return true;
    }

    if (hasReceivedTreatmentForEarCanalInfection === 'NO_TREATMENT_REQUIRED') {
      return true;
    }

    if (typeOfIllness === 'CHRONIC') {
      return true;
    }

    if (hasBeenCuredOfTheInfection === false) {
      return true;
    }

    if (hasBeenDeemedUnfitToWorkForMoreThan28Days === true) {
      return true;
    }

    if (hasBeenDeemedUnfitToWorkForMoreThan42Days === true) {
      return true;
    }

    return false;
  };

  getQuestionOrder({
    hasReceivedTreatmentForEarCanalInfection,
    typeOfIllness,
    howManyTimesHasSufferedFromEarCanalInfection,
    hasBeenCuredOfTheInfection,
  }: SpecifyQuestionnaireModel['L']): (keyof SpecifyQuestionnaireModel['L'])[] {
    if (hasReceivedTreatmentForEarCanalInfection === 'IN_PATIENT') {
      return [
        'hasReceivedTreatmentForEarCanalInfection',
        'uploadMedicalDischarge',
      ];
    }

    if (hasReceivedTreatmentForEarCanalInfection === 'NO_TREATMENT_REQUIRED') {
      return ['hasReceivedTreatmentForEarCanalInfection'];
    }

    if (typeOfIllness === 'CHRONIC') {
      return ['hasReceivedTreatmentForEarCanalInfection', 'typeOfIllness'];
    }

    if (
      howManyTimesHasSufferedFromEarCanalInfection === 'ONCE' &&
      hasBeenCuredOfTheInfection === true
    ) {
      return [
        'hasReceivedTreatmentForEarCanalInfection',
        'typeOfIllness',
        'howManyTimesHasSufferedFromEarCanalInfection',
        'hasBeenCuredOfTheInfection',
        'hasBeenDeemedUnfitToWorkForMoreThan28Days',
      ];
    }

    if (
      howManyTimesHasSufferedFromEarCanalInfection === 'TWICE' &&
      hasBeenCuredOfTheInfection === true
    ) {
      return [
        'hasReceivedTreatmentForEarCanalInfection',
        'typeOfIllness',
        'howManyTimesHasSufferedFromEarCanalInfection',
        'hasBeenCuredOfTheInfection',
        'hasBeenDeemedUnfitToWorkForMoreThan42Days',
      ];
    }

    if (
      howManyTimesHasSufferedFromEarCanalInfection === 'THREE_TIMES_OR_MORE'
    ) {
      return [
        'hasReceivedTreatmentForEarCanalInfection',
        'typeOfIllness',
        'howManyTimesHasSufferedFromEarCanalInfection',
      ];
    }

    return [
      'hasReceivedTreatmentForEarCanalInfection',
      'typeOfIllness',
      'howManyTimesHasSufferedFromEarCanalInfection',
      'hasBeenCuredOfTheInfection',
    ];
  }

  getRiskSurchargeMatrix() {
    return null;
  }
}
