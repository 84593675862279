import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { Input, BottomOrRegularModal } from '@popsure/dirty-swan';
import { Trans, useTranslation } from '@getpopsure/i18n-react';

import { emailValidator } from 'validators';
import Center from 'components/center';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';
import { ViewProps } from '..';
import SignIn from 'components/singIn';

import styles from './style.module.scss';
import { verifyCustomerEmail } from 'network/api';
import { formatErrorMessageFromError } from 'models/error';
import { useDispatch } from 'react-redux';
import { existingCustomer } from 'actions/session';
import { signOut } from 'actions/session';
import Session from '@getpopsure/session';

export default ({
  onAnswerQuestion,
  answer,
  sessionEmail,
  clearAllRemoteData,
}: ViewProps) => {
  const { t } = useTranslation();
  const [displaySignInModal, setDisplaySingInModal] = useState(false);
  const [isExistingCustomer, setIsExistingCustomer] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [value, setValue] = useState(answer);
  const valid = emailValidator(value);

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    setIsExistingCustomer(false);
  };

  const onSubmitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    if (!value) {
      throw new Error('Trying to continue without an email');
    }
    e.preventDefault();
    setError(undefined);

    if (sessionEmail === value) {
      onAnswerQuestion(value);
      return;
    }

    clearAllRemoteData();

    if (Session.isAuthenticated) {
      dispatch(signOut(false));
    }

    try {
      setLoading(true);

      const {
        data: { userExists },
      } = await verifyCustomerEmail(value);

      if (userExists === false) {
        onAnswerQuestion(value);
      } else {
        dispatch(existingCustomer(true));
        setIsExistingCustomer(true);
      }

      setLoading(false);
    } catch (error) {
      setError(formatErrorMessageFromError(error));
      setLoading(false);
    }
  };

  return (
    <Center>
      <h1 className="p-h1">{generalQuestionsTitleMapping.email?.(t)}</h1>
      <div className="mt8">
        <form onSubmit={onSubmitForm}>
          <Input
            data-cy="questionnaire-email"
            className="w100 wmx5"
            placeholder="Email"
            type="email"
            id="email"
            name="email"
            value={value ?? ''}
            onChange={handleValueChange}
            required={true}
          />{' '}
          <AnimateHeight
            duration={300}
            height={error && error.length > 0 ? 'auto' : 0}
          >
            <div className="p-notice--danger mt16 ws5 wmx100">{error}</div>
          </AnimateHeight>
          <button
            data-cy="questionnaire-continue"
            className={`p-btn--primary mt24 ${styles.button} ${
              loading ? 'p-btn--loading' : ''
            }`}
            type="submit"
            disabled={!valid || loading || isExistingCustomer}
          >
            {t('questions.email.continue.action', 'Continue')}
          </button>
        </form>
        <AnimateHeight duration={300} height={isExistingCustomer ? 'auto' : 0}>
          <p className="p-p mt16 wmx5">
            <Trans i18nKey="questions.email.existingaccount.notice">
              It seems that you already have an account with us!{' '}
              <span
                data-cy="questionnaire-email-sign-in"
                role="button"
                className={`${styles['sign-in']} p-a`}
                onClick={() => setDisplaySingInModal(true)}
              >
                Sign in now
              </span>
            </Trans>
          </p>
        </AnimateHeight>
        <BottomOrRegularModal
          title={t('questions.email.signin.action', 'Sign in to feather')}
          isOpen={displaySignInModal}
          onClose={() => setDisplaySingInModal(false)}
        >
          <SignIn
            email={value ?? ''}
            signedInCallback={() => {
              setDisplaySingInModal(false);
              setIsExistingCustomer(false);
              setTimeout(() => {
                onAnswerQuestion(value);
              }, 600);
            }}
          />
        </BottomOrRegularModal>
      </div>
    </Center>
  );
};
