import React from 'react';
import { useTranslation } from '@getpopsure/i18n-react';

import { WrappedForm } from 'pages/questions/formWrapper';
import { nonEmptyValidator } from 'validators';
import Center from 'components/center';
import { YesNoQuestion } from 'pages/questions/generateInput';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';

export default () => {
  const { t } = useTranslation();

  return (
    <Center>
      <WrappedForm
        validator={nonEmptyValidator}
        sectionId="financialHistory"
        questionId="hasLinkedInProfile"
      >
        {({ value, setValue }) => (
          <>
            <h1 className="p-h1">
              {generalQuestionsTitleMapping.hasLinkedInProfile?.(t)}
            </h1>
            <div className="mt8">
              <YesNoQuestion
                name="hasLinkedInProfile"
                value={value}
                setValue={setValue}
              />
            </div>
          </>
        )}
      </WrappedForm>
    </Center>
  );
};
