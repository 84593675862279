import React, { ComponentType } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { AppState } from 'reducers';
import { pathForQuestion } from 'routes/path';
import {
  getReachableQuestionsInSection,
  getLastReachableQuestion,
  getReachableSections,
} from 'selectors';
import { Question } from 'models/questions';

export const connectedRedirect = <P extends Record<string, any>>(
  question: Question,
  ChildComponent: ComponentType<P>
): ComponentType<P> => {
  const HOCComponent = ({
    redirectToQuestion,
    ...props
  }: {
    redirectToQuestion?: Question;
  }) => {
    if (redirectToQuestion) {
      return <Redirect to={pathForQuestion(redirectToQuestion)} />;
    }

    return <ChildComponent {...(props as P)} />;
  };

  const mapStateToProps = (state: AppState) => {
    const reachableSection = getReachableSections(state);
    const reachableQuestions = getReachableQuestionsInSection(
      state,
      question.sectionId
    );

    if (
      reachableSection.find((sectionId) => sectionId === question.sectionId) !==
        undefined &&
      reachableQuestions.filter(
        ({ questionId, sectionId, type }) =>
          questionId === question.questionId &&
          sectionId === question.sectionId &&
          type === question.type
      ).length > 0
    ) {
      return {};
    }

    return {
      redirectToQuestion: getLastReachableQuestion(state),
    };
  };

  return connect(mapStateToProps)(HOCComponent);
};
