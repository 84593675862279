import { Reducer } from 'redux';

import { Action } from 'actions/type';
import { Tree } from 'models/questions/specify';
import { RecursivePartial, AssociatedShapeFromIdentifier } from 'utils/types';
import { treeIdFromConditionId } from 'models/medicalConditions';
import { QuestionIdInSectionId } from 'models/questions/general';

// TODO: That's a really ugly reducer right there.
// It could be improved by using a nested reducer structure
// https://stackoverflow.com/questions/36786244/nested-redux-reducers
// Or by using some libraries such as https://immerjs.github.io/immer/docs/introduction

type ShapeForSpecifyQuestionnaireInTree<T extends Tree['id']> = {
  [U in AssociatedShapeFromIdentifier<
    Tree,
    'id',
    T
  >['question']['id']]: AssociatedShapeFromIdentifier<
    Tree['question'],
    'id',
    U
  >['answer'];
};

type MedicalHistoryQuestionId = QuestionIdInSectionId<'medicalHistory'>;

export type StoredSpecifyQuestionnaireModel = RecursivePartial<
  {
    [Q in MedicalHistoryQuestionId]?: {
      [T in Tree['id']]: {
        [I in number]: ShapeForSpecifyQuestionnaireInTree<T>;
      };
    } & { generic: { [V in number]: { description: string } } };
  }
>;

const initialState: StoredSpecifyQuestionnaireModel = {};

const reducer: Reducer<StoredSpecifyQuestionnaireModel, Action> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    // STORE_ANSWERED_SPECIFY_QUESTION is only needed to enable the UploadDocComponent that's used in the specify questions to persist the uploaded files without skipping to the next question.
    // Reference PR: https://github.com/getPopsure/private-signup/pull/504
    case 'STORE_ANSWERED_SPECIFY_QUESTION':
    case 'ANSWERED_SPECIFY_QUESTION':
      if (action.question.type !== 'specify') {
        throw new Error('Answered specify question with invalid payload');
      }

      const treeId = treeIdFromConditionId(action.question.conditionId);
      const {
        question: { conditionId, specifyQuestionId },
        answer,
      } = action;

      const questionId = action.question.questionId as MedicalHistoryQuestionId;

      if (treeId && specifyQuestionId && treeId !== 'ZZ') {
        return {
          ...state,
          [questionId]: {
            ...state[questionId],
            [treeId]: {
              ...state[questionId]?.[treeId],
              [conditionId]: {
                ...state[questionId]?.[treeId]?.[conditionId],
                [specifyQuestionId]: answer,
              },
            },
          },
        };
      }

      return {
        ...state,
        [questionId]: {
          ...state[questionId],
          generic: {
            ...state[questionId]?.generic,
            [conditionId]: { description: answer },
          },
        },
      };

    default:
      return state;
  }
};

export default reducer;
