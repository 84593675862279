import React, { useState, useEffect } from 'react';
import { Input } from '@popsure/dirty-swan';

import { ViewProps } from '..';
import styles from './style.module.scss';
import { sendSignInEmail } from 'network/api';

export default ({
  email,
  error,
  loading,
  isInvalid,
  onLoginCodeEntered,
  signedInCallback,
}: ViewProps) => {
  const [loginCode, setLoginCode] = useState('');

  useEffect(() => {
    (async function () {
      await sendSignInEmail(email);
    })();
  }, [email]);

  return (
    <div className={styles.container}>
      <p className="p-p">
        We’ve sent a code to {email}. Please enter the code below
      </p>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onLoginCodeEntered(loginCode, email, signedInCallback);
        }}
      >
        <Input
          data-cy="sign-in-login-code"
          className="mt24 wmx5"
          placeholder="Log in code"
          error={error}
          value={loginCode}
          onChange={(e) => setLoginCode(e.target.value)}
          required={true}
        />
        {isInvalid && (
          <>
            <p className="p-p--small tc-red-500 mt8">
              Please try again or{' '}
              <span
                role="button"
                className={`p-p--small p-a ${styles.resend}`}
                onClick={() => {
                  sendSignInEmail(email);
                }}
              >
                Send a new code
              </span>
            </p>
          </>
        )}
        <button
          data-cy="sign-in-continue"
          className={`p-btn--primary mt24 wmn3 ${
            loading ? 'p-btn--loading' : ''
          }`}
          disabled={loading || loginCode.length === 0}
        >
          Sign in
        </button>
      </form>
    </div>
  );
};
