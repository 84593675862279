import React from 'react';

import { Condition } from 'models/medicalConditions';

import ConditionTablet from '../conditionTablet';

export default ({
  conditions,
  selectedConditions,
  onSelect,
  onDeselect,
}: {
  conditions: Condition[];
  selectedConditions: Condition[];
  onSelect: (condition: Condition) => void;
  onDeselect: (condition: Condition) => void;
}) => {
  return (
    <div className="mt16">
      {conditions.map((condition) => (
        <ConditionTablet
          key={condition.id}
          condition={condition}
          isSelected={selectedConditions.includes(condition)}
          isDismissable={false}
          onSelect={onSelect}
          onDeselect={onDeselect}
        />
      ))}
    </div>
  );
};
