import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from '@getpopsure/i18n-react';
import { generatePageTitle } from 'utils/generatePageTitle/generatePageTitle';
import { useLocation } from 'react-router-dom';

export default () => {
  const { t } = useTranslation();
  const location = useLocation();

  const title = ['Feather', generatePageTitle(location.pathname)]
    .filter(Boolean)
    .join(' - ');

  const content = {
    title,
    description: t(
      'global.meta.title',
      'Honest, simple insurance for expats in Germany. English-language support for health insurance, personal liability insurance, household contents insurance, and more.'
    ),
    favicon: '/favicon.png',
    manifest: '/manifest.json',
    appleTouchIcon: '/logo192.png',
  };

  return (
    <Helmet>
      <meta name="description" content={content.description} />
      <link rel="icon" href={content.favicon} />
      <link rel="apple-touch-icon" href={content.appleTouchIcon} />
      <link rel="manifest" href={content.manifest} />
      <title>{content.title}</title>
    </Helmet>
  );
};
