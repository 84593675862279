import React from 'react';
import { insurance } from '@getpopsure/private-constants';
import {
  setAddOns,
  setQuestionnaire,
  setSickDayPayout,
  setTariffAndDeductible,
} from 'actions';
import Loading from 'components/loading';
import lz from 'lz-string';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  isDeductible,
  isTariff,
  rawQueryParametersAddOnstoAddOn,
} from 'pages/welcome/component/util';

/**
 *
 * Stand alone component for seeding the questionnaire.
 * Will read the search query params to populate a questionnaire with the provided values.
 * Note: If there are previously persisted answers, these will be removed.
 *
 *
 */
export const SeedQuestionnaire = () => {
  // collect the current path and values
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const path = params.get('p');
  const valuesParam = params.get('v');
  const deductible = params.get('deductible');
  const tariff = params.get('tariff');
  const sickDayPayout = params.get('sickDayPayout');
  const addons = params.get('addOns');

  const values = lz.decompressFromEncodedURIComponent(valuesParam ?? '');

  const parsedValues = JSON.parse(values ?? '');

  const dispatch = useDispatch();

  useEffect(() => {
    const addOns = rawQueryParametersAddOnstoAddOn(addons);

    if (isDeductible(deductible) && isTariff(tariff)) {
      dispatch(setTariffAndDeductible(tariff, deductible));
    }

    if (addons !== null && addOns) {
      dispatch(setAddOns(addOns));
    }

    if (sickDayPayout && !isNaN(Number(sickDayPayout))) {
      dispatch(setSickDayPayout(Number(sickDayPayout)));
    }

    // populate the questionnaire with the provided questionnaire answers
    dispatch(setQuestionnaire(parsedValues));

    const timeoutId = setTimeout(() => {
      if (path) {
        window.location.href = path;
      } else {
        window.location.href = insurance.privateHealth.signup;
      }
    }, 1000);

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line
  }, []);

  return <Loading />;
};
