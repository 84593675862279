import React, { useState } from 'react';
import { website } from '@getpopsure/private-constants';
import { useTranslation } from 'react-i18next';
import { Trans } from '@getpopsure/i18n-react';
import { Checkbox, Link } from '@popsure/dirty-swan';

import Center from 'components/center';
import {
  Section,
  sectionFriendlyName as sectionFriendlyNameFunction,
} from 'models/questions/general';

import { ViewProps } from '..';
import styles from './style.module.scss';

import {
  personalInfoSVG,
  financialHistorySVG,
  insuranceHistorySVG,
  medicalHistorySVG,
} from './img';

const SectionView = ({ Image, title }: { Image: any; title: string }) => (
  <div
    className={`d-flex fd-column jc-center ai-center bg-white br8 p24 ${styles['section-chip']}`}
  >
    <Image />
    <p className={`p-p ta-center fw-bold mt16 ${styles['section-chip-title']}`}>
      {title}
    </p>
  </div>
);

export default ({ firstQuestionUrl, history }: ViewProps) => {
  const { t } = useTranslation();
  const PersonalInfoImage = () => <img src={personalInfoSVG} alt="person" />;
  const FinancialHistoryImage = () => (
    <img src={financialHistorySVG} alt="coin" />
  );
  const InsuranceHistoryImage = () => (
    <img src={insuranceHistorySVG} alt="shield" />
  );
  const MedicalHistoryImage = () => <img src={medicalHistorySVG} alt="heart" />;

  const sectionFriendlyName = sectionFriendlyNameFunction(t);

  const mapping: {
    [K in Section['id']]: { title: string; Image: React.ReactNode };
  } = {
    personalInfo: {
      title: sectionFriendlyName.personalInfo,
      Image: PersonalInfoImage,
    },
    financialHistory: {
      title: sectionFriendlyName.financialHistory,
      Image: FinancialHistoryImage,
    },
    insuranceHistory: {
      title: sectionFriendlyName.insuranceHistory,
      Image: InsuranceHistoryImage,
    },
    medicalHistory: {
      title: sectionFriendlyName.medicalHistory,
      Image: MedicalHistoryImage,
    },
  };

  /**
   * Get the info from the pre-signup using URL params
   * the tariff & deductible params are being fetched
   * on src/components/withTariff/index.tsx
   */
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  return (
    <>
      <Center>
        <div data-cy="main-page">
          <h1 className="p-h1 wmx8">
            {t('page.welcome.title', 'Let’s get you covered')}
          </h1>
          <p className={`p-p wmx8 mb16 ${styles.description}`}>
            {t(
              'page.welcome.subtitle',
              'No medical exams, 100% digital signup'
            )}{' '}
            <span role="img" aria-label="">
              🎉
            </span>
          </p>
          <div className={styles['section-container']}>
            {Object.values(mapping).map(({ title, Image }) => (
              <SectionView key={title} Image={Image} title={title} />
            ))}
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              history.push(firstQuestionUrl);
            }}
          >
            <div className="p-label-container mt24" data-cy="legal-accept-tnc">
              <Checkbox
                options={{
                  AGREEMENT: (
                    <p>
                      <Trans i18nKey="page.welcome.legal.tnc">
                        I have read and agree to the{' '}
                        <Link
                          href={website.privacyPolicy}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          privacy policy
                        </Link>{' '}
                        and{' '}
                        <Link
                          href={website.termsAndConditions}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          T&Cs
                        </Link>
                        .
                      </Trans>
                    </p>
                  ),
                }}
                value={agreedToTerms ? ['AGREEMENT'] : []}
                bordered={false}
                classNames={{ label: `${styles.label}` }}
                onChange={() => {
                  setAgreedToTerms(!agreedToTerms);
                }}
              />
            </div>
            <button
              disabled={!agreedToTerms}
              data-cy="questionnaire-start"
              className="p-btn--primary wmn3 mt24"
            >
              {t('page.welcome.start', 'Let’s go!')}
            </button>
          </form>
        </div>
      </Center>
    </>
  );
};
