import { TreeLogic } from '..';
import { SpecifyQuestionnaireModel } from 'models/questions/specify';
import { intermediateSurchargeMatrix } from 'models/surcharge';

export class T implements TreeLogic<'T'> {
  getRejection(): boolean {
    return false;
  }

  getAssess({
    hasHadOperation,
    hasSufferedFromThyroidNodules,
    hasSufferedFromEnlargedThyroid,
    hasHadThyroidCysts,
    hasBeenDeclaredUnfitToWork,
    hasReceivedTreatmentForHypothyroidism,
    uploadMedicalDischarge,
  }: SpecifyQuestionnaireModel['T']): boolean {
    if (hasReceivedTreatmentForHypothyroidism === 'NO_TREATMENT_REQUIRED') {
      return true;
    }

    if (uploadMedicalDischarge !== undefined) {
      return true;
    }

    if (hasHadOperation === true) {
      return true;
    }

    if (hasSufferedFromEnlargedThyroid === true) {
      return true;
    }

    if (hasSufferedFromThyroidNodules === true) {
      return true;
    }

    if (hasHadThyroidCysts === true) {
      return true;
    }

    if (hasBeenDeclaredUnfitToWork === true) {
      return true;
    }

    return false;
  }

  getQuestionOrder({
    hasReceivedTreatmentForHypothyroidism,
  }: SpecifyQuestionnaireModel['T']): (keyof SpecifyQuestionnaireModel['T'])[] {
    if (hasReceivedTreatmentForHypothyroidism === 'IN_PATIENT') {
      return [
        'hasReceivedTreatmentForHypothyroidism',
        'uploadMedicalDischarge',
      ];
    }
    return [
      'hasReceivedTreatmentForHypothyroidism',
      'hasHadOperation',
      'hasSufferedFromEnlargedThyroid',
      'hasSufferedFromThyroidNodules',
      'hasHadThyroidCysts',
      'hasBeenDeclaredUnfitToWork',
    ];
  }

  getRiskSurchargeMatrix() {
    return null;
  }
}
export class U implements TreeLogic<'U'> {
  private age: number;

  constructor(age: number) {
    this.age = age;
  }

  getRejection({
    hasDiopterGreaterThan15,
  }: SpecifyQuestionnaireModel['U']): boolean {
    if (hasDiopterGreaterThan15 === true) {
      return true;
    }
    return false;
  }

  getAssess({
    hadOperationRelatedToVision,
    hasDiopterBetween10And15,
    uploadMedicalDischarge,
  }: SpecifyQuestionnaireModel['U']): boolean {
    if (uploadMedicalDischarge !== undefined) {
      return true;
    }

    if (hadOperationRelatedToVision === true) {
      return true;
    }

    if (hasDiopterBetween10And15) {
      return true;
    }

    return false;
  }

  getQuestionOrder({
    hadReceivedVisionCare,
  }: SpecifyQuestionnaireModel['U']): (keyof SpecifyQuestionnaireModel['U'])[] {
    if (hadReceivedVisionCare === 'IN_PATIENT') {
      return ['hadReceivedVisionCare', 'uploadMedicalDischarge'];
    }

    return [
      'hadReceivedVisionCare',
      'hadOperationRelatedToVision',
      'hasDiopterGreaterThan15',
      'hasDiopterBetween10And15',
      'hasDiopterBetween8And10',
    ];
  }

  getRiskSurchargeMatrix({
    hasDiopterBetween8And10,
  }: SpecifyQuestionnaireModel['U']) {
    if (hasDiopterBetween8And10 === true) {
      return intermediateSurchargeMatrix(this.age);
    }
    return null;
  }
}
export class V implements TreeLogic<'V'> {
  getRejection(): boolean {
    return false;
  }

  getAssess({
    uploadMedicalDischarge,
    hasReceivedTreatmentForTear,
    hasFullyRecovered,
    hasBeenDeclaredUnfitToWorkForMoreThan180Days,
  }: SpecifyQuestionnaireModel['V']) {
    if (uploadMedicalDischarge !== undefined) {
      return true;
    }

    if (hasReceivedTreatmentForTear === 'NO_TREATMENT_REQUIRED') {
      return true;
    }

    if (hasFullyRecovered === false) {
      return true;
    }

    if (hasBeenDeclaredUnfitToWorkForMoreThan180Days === true) {
      return true;
    }

    return false;
  }

  getQuestionOrder({
    hasReceivedTreatmentForTear,
  }: SpecifyQuestionnaireModel['V']): (keyof SpecifyQuestionnaireModel['V'])[] {
    if (hasReceivedTreatmentForTear === 'IN_PATIENT') {
      return ['hasReceivedTreatmentForTear', 'uploadMedicalDischarge'];
    }
    return [
      'hasReceivedTreatmentForTear',
      'hasFullyRecovered',
      'hasBeenDeclaredUnfitToWorkForMoreThan180Days',
    ];
  }

  getRiskSurchargeMatrix() {
    return null;
  }
}

export class W implements TreeLogic<'W'> {
  getRejection(): boolean {
    return false;
  }

  getAssess({
    uploadMedicalDischarge,
    hasFullyRecovered,
    hasBeenDeclaredUnfitToWorkForMoreThan28Days,
  }: SpecifyQuestionnaireModel['W']) {
    if (uploadMedicalDischarge !== undefined) {
      return true;
    }

    if (hasFullyRecovered === false) {
      return true;
    }

    if (hasBeenDeclaredUnfitToWorkForMoreThan28Days === true) {
      return true;
    }
    return false;
  }

  getQuestionOrder({
    hasReceivedTreatmetnForSprainOrBruisingInjury,
  }: SpecifyQuestionnaireModel['W']): (keyof SpecifyQuestionnaireModel['W'])[] {
    if (hasReceivedTreatmetnForSprainOrBruisingInjury === 'IN_PATIENT') {
      return [
        'hasReceivedTreatmetnForSprainOrBruisingInjury',
        'uploadMedicalDischarge',
      ];
    }

    return [
      'hasReceivedTreatmetnForSprainOrBruisingInjury',
      'hasFullyRecovered',
      'hasBeenDeclaredUnfitToWorkForMoreThan28Days',
    ];
  }

  getRiskSurchargeMatrix() {
    return null;
  }
}

export class X implements TreeLogic<'X'> {
  getRejection(): boolean {
    return false;
  }

  getAssess({
    hasFullyRecovered,
    uploadMedicalDischarge,
    hasBeenDeclaredUnfitToWorkForMoreThan28Days,
  }: SpecifyQuestionnaireModel['X']) {
    if (uploadMedicalDischarge !== undefined) {
      return true;
    }

    if (hasFullyRecovered === false) {
      return true;
    }

    if (hasBeenDeclaredUnfitToWorkForMoreThan28Days === true) {
      return true;
    }

    return false;
  }

  getQuestionOrder({
    hasReceivedTreatmentForSprainOrBruisingInjuryToSpine,
  }: SpecifyQuestionnaireModel['X']): (keyof SpecifyQuestionnaireModel['X'])[] {
    if (hasReceivedTreatmentForSprainOrBruisingInjuryToSpine === 'IN_PATIENT') {
      return [
        'hasReceivedTreatmentForSprainOrBruisingInjuryToSpine',
        'uploadMedicalDischarge',
      ];
    }

    return [
      'hasReceivedTreatmentForSprainOrBruisingInjuryToSpine',
      'hasFullyRecovered',
      'hasBeenDeclaredUnfitToWorkForMoreThan28Days',
    ];
  }

  getRiskSurchargeMatrix() {
    return null;
  }
}
export class Y implements TreeLogic<'Y'> {
  getRejection(): boolean {
    return false;
  }

  getAssess({
    uploadMedicalDischarge,
    hasFullyRecovered,
    hasBeenDeclaredUnfitToWorkForMoreThan28Days,
  }: SpecifyQuestionnaireModel['Y']) {
    if (uploadMedicalDischarge !== undefined) {
      return true;
    }

    if (hasFullyRecovered === false) {
      return true;
    }

    if (hasBeenDeclaredUnfitToWorkForMoreThan28Days === true) {
      return true;
    }

    return false;
  }

  getQuestionOrder({
    hasReceivedTreatmetnForSprainOrBruisingInjuryToKnee,
  }: SpecifyQuestionnaireModel['Y']): (keyof SpecifyQuestionnaireModel['Y'])[] {
    if (hasReceivedTreatmetnForSprainOrBruisingInjuryToKnee === 'IN_PATIENT') {
      return [
        'hasReceivedTreatmetnForSprainOrBruisingInjuryToKnee',
        'uploadMedicalDischarge',
      ];
    }

    return [
      'hasReceivedTreatmetnForSprainOrBruisingInjuryToKnee',
      'hasFullyRecovered',
      'hasBeenDeclaredUnfitToWorkForMoreThan28Days',
    ];
  }

  getRiskSurchargeMatrix() {
    return null;
  }
}
export class Z implements TreeLogic<'Z'> {
  getRejection(): boolean {
    return false;
  }

  getAssess({ examinationReports }: SpecifyQuestionnaireModel['Z']) {
    if (examinationReports !== undefined) {
      return true;
    }

    return false;
  }

  getQuestionOrder({
    hadPreventiveCareAppointmentMoreThanTwiceAYear,
    appointmentHadRaisedFindings,
  }: SpecifyQuestionnaireModel['Z']): (keyof SpecifyQuestionnaireModel['Z'])[] {
    if (hadPreventiveCareAppointmentMoreThanTwiceAYear === true) {
      return [
        'hadPreventiveCareAppointmentMoreThanTwiceAYear',
        'examinationReports',
      ];
    }

    if (appointmentHadRaisedFindings === true) {
      return [
        'hadPreventiveCareAppointmentMoreThanTwiceAYear',
        'appointmentHadRaisedFindings',
        'examinationReports',
      ];
    }

    return [
      'hadPreventiveCareAppointmentMoreThanTwiceAYear',
      'appointmentHadRaisedFindings',
    ];
  }

  getRiskSurchargeMatrix() {
    return null;
  }
}
