import { SpecifyQuestionnaireModel, Tree } from 'models/questions/specify';
import { RecursivePartial } from 'utils/types';
import { AppState } from 'reducers';
import { getBMI } from 'selectors/blocker';
import { SurchargeMatrix } from 'models/surcharge';

import { A, B, C, D, E } from './ABCDE';
import { FGH } from './FGH';
import { I, J, K, L } from './IJKL';
import { M, N, O, P, Q, R, S } from './MNOPQRS';
import { T, U, V, W, X, Y, Z } from './TUVWXYZ';
import { AA, AB, AC, AD, AE, AF, AG } from './AA-AG';
import { getReachedAgeThisYear } from 'selectors';

export interface TreeLogic<Tl extends keyof SpecifyQuestionnaireModel> {
  getRejection(
    questions: RecursivePartial<SpecifyQuestionnaireModel[Tl]>
  ): boolean;
  getQuestionOrder(
    questions: RecursivePartial<SpecifyQuestionnaireModel[Tl]>
  ): (keyof SpecifyQuestionnaireModel[Tl])[];
  getAssess(
    questions: RecursivePartial<SpecifyQuestionnaireModel[Tl]>
  ): boolean;
  getRiskSurchargeMatrix(
    questions: RecursivePartial<SpecifyQuestionnaireModel[Tl]>
  ): SurchargeMatrix | null;
}

export const treeLogicArray: {
  [TID in Tree['id']]: (state: AppState) => TreeLogic<TID>;
} = {
  A: () => new A(),
  B: () => new B(),
  C: () => new C(),
  D: (state) => new D(getReachedAgeThisYear(state)!),
  E: (state) => new E(getReachedAgeThisYear(state)!),
  FGH: (state) => new FGH(getBMI(state)!, getReachedAgeThisYear(state)!),
  I: () => new I(),
  J: () => new J(),
  K: () => new K(),
  L: () => new L(),
  M: () => new M(),
  N: (state) => new N(getReachedAgeThisYear(state)!),
  O: (state) => new O(getReachedAgeThisYear(state)!),
  P: () => new P(),
  Q: (state) => new Q(getReachedAgeThisYear(state)!),
  R: () => new R(),
  S: () => new S(),
  T: () => new T(),
  U: (state) => new U(getReachedAgeThisYear(state)!),
  V: () => new V(),
  W: () => new W(),
  X: () => new X(),
  Y: () => new Y(),
  Z: () => new Z(),
  AA: () => new AA(),
  AB: () => new AB(),
  AC: () => new AC(),
  AD: () => new AD(),
  AE: () => new AE(),
  AF: () => new AF(),
  AG: (state) => new AG(getReachedAgeThisYear(state)!),
};
