import React from 'react';
import AnimateHeight from 'react-animate-height';
import { DateSelector } from '@popsure/dirty-swan';
import { useTranslation } from '@getpopsure/i18n-react';

import { WrappedForm } from 'pages/questions/formWrapper';
import { passportExpiryValidator } from 'validators';
import Center from 'components/center';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';

export default () => {
  const { t } = useTranslation();

  return (
    <Center>
      <h1 className="p-h1">
        {generalQuestionsTitleMapping.passportExpiry?.(t)}
      </h1>
      <WrappedForm
        validator={passportExpiryValidator}
        sectionId="personalInfo"
        questionId="passportExpiry"
      >
        {({ value, setValue }) => (
          <>
            <div className="mt8">
              <DateSelector
                value={value}
                onChange={setValue}
                yearBoundaries={{
                  min: new Date().getFullYear(),
                  max: new Date().getFullYear() + 11,
                }}
              />
            </div>
            <AnimateHeight
              duration={300}
              height={
                value && passportExpiryValidator(value) === false ? 'auto' : 0
              }
            >
              <div className="p-notice--warning mt16 wmx6">
                {t(
                  'questions.passportexpiry.warning.notice',
                  'Your passport must at least be valid for another 30 days.'
                )}
              </div>
            </AnimateHeight>
          </>
        )}
      </WrappedForm>
    </Center>
  );
};
