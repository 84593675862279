import React from 'react';

export const ChatIcon = ({ color }: { color: string }) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="100%" height="100%" rx="16" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.3327 24.3332C24.3327 24.3332 21.5267 23.367 19.2571 22.5936H9.71185C8.58268 22.5936 7.66602 21.7425 7.66602 20.6941V9.56442C7.66602 8.51702 8.58268 7.6665 9.71185 7.6665H22.2863C23.416 7.6665 24.3321 8.5165 24.3321 9.56494V19.1504L24.3327 24.3332ZM10.2301 12.1537C10.2301 11.7997 10.5171 11.5127 10.8711 11.5127H18.5635C18.9175 11.5127 19.2045 11.7997 19.2045 12.1537C19.2045 12.5077 18.9175 12.7947 18.5635 12.7947H10.8711C10.5171 12.7947 10.2301 12.5077 10.2301 12.1537ZM10.8711 14.7178C10.5171 14.7178 10.2301 15.0048 10.2301 15.3588C10.2301 15.7128 10.5171 15.9998 10.8711 15.9998H15.9993C16.3534 15.9998 16.6404 15.7128 16.6404 15.3588C16.6404 15.0048 16.3534 14.7178 15.9993 14.7178H10.8711Z"
      fill="white"
    />
  </svg>
);
