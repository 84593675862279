import React, { useEffect } from 'react';
import { useTranslation } from '@getpopsure/i18n-react';
import { Link } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import styles from './style.module.scss';
import Center from 'components/center';

export default () => {
  const { t } = useTranslation();
  useEffect(() => {
    Sentry.captureException(new Error(`404: ${window.location.pathname}`));
  }, []);

  return (
    <Center>
      <div
        role="img"
        data-cy="404-page"
        className={`d-block ml-auto mr-auto ${styles.image}`}
        aria-label="404"
      />
      <p className="p-p mt32 wmx4 ta-center d-block ml-auto mr-auto">
        {t(
          'page.notfound.description',
          'We’re sorry, the page you are looking for could not be found…'
        )}
      </p>
      <Link className="p-a mt32 d-block ta-center" to="/">
        {t('page.notfound.homepage.action', 'Go to homepage')}
      </Link>
    </Center>
  );
};
