import React from 'react';

import { WrappedForm } from 'pages/questions/formWrapper';
import { nonEmptyValidator } from 'validators';
import { YesNoQuestion } from 'pages/questions/generateInput';
import Center from 'components/center';
import { QuestionIdInSectionId } from 'models/questions/general';

export default ({
  title,
  questionId,
}: {
  title: string;
  questionId: QuestionIdInSectionId<'medicalHistory'>;
}) => (
  <Center>
    <WrappedForm
      validator={nonEmptyValidator}
      sectionId="medicalHistory"
      questionId={questionId}
    >
      {({ value, setValue }) => (
        <>
          <h1 className="p-h1">{title}</h1>
          <div className="mt8">
            <YesNoQuestion
              name={questionId}
              value={value as boolean}
              setValue={setValue}
            />
          </div>
        </>
      )}
    </WrappedForm>
  </Center>
);
