import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  sectionIdFromKebabCaseSectionId,
  questionIdFromKebabCaseQuestionId,
} from 'routes';
import { answeredInvestigationForQuestion } from 'actions';
import SelectConditionScreen from 'components/selectCondition';
import { GeneralSectionId } from 'models/questions/general';
import { MedicalHistoryQuestion } from 'models/questions/general/medicalHistory';
import { AppState } from 'reducers';
import { Condition } from 'models/medicalConditions';
import { connectedRedirect } from 'components/connectedRedirect';

const View = ({ storedConditions, answeredInvestigation }: ViewProps) => {
  return (
    <SelectConditionScreen
      initialConditions={storedConditions}
      onAnswerQuestion={(conditions) => {
        answeredInvestigation(conditions);
      }}
      question={'Please, choose your condition below:'}
    />
  );
};

interface StateProps {
  storedConditions?: Condition[];
}

interface DispatchProps {
  answeredInvestigation: (conditions: Condition[]) => void;
}

interface OwnProps {
  questionId: MedicalHistoryQuestion['id'];
  sectionId: GeneralSectionId;
}

type ViewProps = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (
  state: AppState,
  { questionId }: OwnProps
): StateProps => {
  const storedConditions = state.investigation?.[questionId];
  return { storedConditions };
};

const mapDispatchToProps = (
  dispatch: any,
  { questionId, sectionId }: OwnProps
): DispatchProps => {
  return {
    answeredInvestigation: (conditions) => {
      dispatch(
        answeredInvestigationForQuestion(
          {
            type: 'investigation',
            sectionId,
            questionId,
          },
          conditions
        )
      );
    },
  };
};

export default () => {
  // TODO: Move this into a custom hook
  const {
    sectionId: kebabCaseSectionId,
    questionId: kebabCaseQuestionId,
  } = useParams();

  const sectionId = sectionIdFromKebabCaseSectionId(kebabCaseSectionId);

  if (!sectionId) {
    throw new Error(
      `Couldn’t find corresponding section id for: :${kebabCaseSectionId}`
    );
  }

  const questionId = questionIdFromKebabCaseQuestionId(
    sectionId,
    kebabCaseQuestionId
  ) as MedicalHistoryQuestion['id'];

  const ConnectedView = connectedRedirect(
    { sectionId, questionId, type: 'investigation' },
    connect<StateProps, DispatchProps, OwnProps, AppState>(
      mapStateToProps,
      mapDispatchToProps
    )(View)
  );

  return <ConnectedView questionId={questionId} sectionId={sectionId} />;
};
