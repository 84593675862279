import * as Sentry from '@sentry/browser';
import { getFixedT } from '@getpopsure/i18n-react';

import {
  isAddress,
  isName,
  isArrayOfCountries,
  isArrayOfUploadedFiles,
  isAnswerCurrency,
} from 'models';
import { Section } from 'models/questions/general';
import { LabelType } from 'actions/type';
import { formatCurrencyWithSpace } from 'utils/formatCurrencyWithSpace/formatCurrencyWithSpace';

const SKIPPED_QUESTION_ANSWER = 'Provided separately';

export const valueForQuestion = ({
  id,
  answer,
  label,
}: Section['question'] & {
  label?: LabelType;
}): string => {
  const t = getFixedT();

  if (label) {
    if (typeof label === 'string') {
      return label;
    }

    if (typeof answer === 'string') {
      return label[answer as string];
    }

    if (Array.isArray(answer)) {
      return (answer as string[]).map((a) => label[a]).join(', ');
    }

    return Object.values(label).join(', ');
  }

  // E.g. passportNumber question
  if (!label && answer === '') {
    return SKIPPED_QUESTION_ANSWER;
  }

  if (isArrayOfUploadedFiles(answer)) {
    return answer.map(({ name }) => name).join(', ');
  }

  if (isArrayOfCountries(answer)) {
    return answer.map(({ name }) => name).join(', ');
  }

  if (typeof answer === 'string') {
    return answer;
  }

  if (typeof answer === 'number') {
    if (isAnswerCurrency(id)) {
      return formatCurrencyWithSpace(answer, true);
    } else {
      return `${answer}`;
    }
  }

  if (typeof answer === 'boolean') {
    return answer
      ? t('questions.review.booleanquestion.yes', 'Yes')
      : t('questions.review.booleanquestion.no', 'No');
  }

  // E.g. file upload questions
  if (Array.isArray(answer)) {
    return answer.length > 0 ? answer.join(', ') : SKIPPED_QUESTION_ANSWER;
  }

  if (isName(answer)) {
    return `${answer.firstName} ${answer.lastName}`;
  }

  if (isAddress(answer)) {
    if (answer.street === '') {
      return SKIPPED_QUESTION_ANSWER;
    }

    return `${answer.street} ${answer.houseNumber},${
      answer.additionalInformation ? ' ' + answer.additionalInformation : ''
    } ${answer.postcode} ${answer.city}, ${answer.country}`;
  }

  // If there's no mapping,
  // trigger an error
  Sentry.captureException(
    new Error(
      `Couldn’t find value for question id: ${id} with answer: ${answer}`
    )
  );

  return '';
};
