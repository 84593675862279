import React, { useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from '@getpopsure/i18n-react';

import { WrappedForm } from 'pages/questions/formWrapper';
import { nonEmptyValidator } from 'validators';
import Center from 'components/center';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';
import {
  getStartDateContentVariationFromAnswers,
  possibleCoverageStartDateFromTodaysDate,
} from './util';
import { StartDateContent } from './StartDateContent';

export default () => {
  const { t } = useTranslation();

  return (
    <Center>
      <h1 className="p-h1 mb8">
        {generalQuestionsTitleMapping.startDate?.(t)}
      </h1>
      <WrappedForm
        validator={nonEmptyValidator}
        sectionId="insuranceHistory"
        questionId="startDate"
      >
        {({ questionnaireAnswers, value, setValue }) => {
          const contentVariationType = getStartDateContentVariationFromAnswers(
            questionnaireAnswers || {}
          );

          useEffect(() => {
            if (!value) {
              setValue(possibleCoverageStartDateFromTodaysDate()[1]);
            }
          }, [value, setValue]);

          return (
            <div>
              <StartDateContent variationType={contentVariationType} />
              <select
                className="p-select w100 ws5 mt16"
                id="coverageStartDate"
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                value={value}
              >
                {possibleCoverageStartDateFromTodaysDate().map((startDate) => (
                  <option key={startDate} value={startDate}>
                    {moment(startDate).format('Do MMMM YYYY')}
                  </option>
                ))}
              </select>
            </div>
          );
        }}
      </WrappedForm>
    </Center>
  );
};
