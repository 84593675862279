import React, { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useTranslation } from '@getpopsure/i18n-react';

import { Portal } from 'react-portal';
import { sectionFriendlyName as sectionFriendlyNameFunction } from 'models/questions/general';
import { SECTION_HEADER_CONTAINER } from 'components/center';

import { ViewProps } from '..';

import styles from './style.module.scss';

export default ({ currentSection, location }: ViewProps) => {
  const { t } = useTranslation();
  const sectionFriendlyName = sectionFriendlyNameFunction(t);
  const [
    sectionHeaderContainer,
    setSectionHeaderContainer,
  ] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setSectionHeaderContainer(
      document.getElementById(SECTION_HEADER_CONTAINER)
    );
  }, [location]);

  return (
    <>
      {sectionHeaderContainer && currentSection && (
        <Portal node={sectionHeaderContainer}>
          <div className={`p-p tc-primary-500 fw-bold ${styles.mobile}`}>
            {sectionFriendlyName[currentSection]}
          </div>
        </Portal>
      )}
      <AnimateHeight
        duration={300}
        height={currentSection !== undefined ? 'auto' : 0}
      >
        <div className={`${styles.container} ${styles.desktop}`}>
          {Object.entries(sectionFriendlyName).map(([key, name]) => (
            <div
              className={`p-p ${
                currentSection === key ? 'tc-primary-500' : 'tc-grey-500'
              } } ${styles.item}`}
              key={name}
            >
              {name}
            </div>
          ))}
        </div>
      </AnimateHeight>
    </>
  );
};
