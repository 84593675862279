import { SpecifyQuestionnaireModel } from 'models/questions/specify';
import { TreeLogic } from '..';
import { intermediateSurchargeMatrix } from 'models/surcharge';

export class AA implements TreeLogic<'AA'> {
  getRejection = () => {
    return false;
  };

  getAssess = ({
    examinationReports,
  }: Partial<SpecifyQuestionnaireModel['AA']>) => {
    if (examinationReports) {
      return true;
    }
    return false;
  };

  getQuestionOrder({
    hasRequiredPreventiveEyeCare,
    preventiveCareRaisedFindings,
  }: Partial<
    SpecifyQuestionnaireModel['AA']
  >): (keyof SpecifyQuestionnaireModel['AA'])[] {
    if (hasRequiredPreventiveEyeCare === true) {
      return ['hasRequiredPreventiveEyeCare', 'examinationReports'];
    }

    if (preventiveCareRaisedFindings === true) {
      return [
        'hasRequiredPreventiveEyeCare',
        'preventiveCareRaisedFindings',
        'examinationReports',
      ];
    }

    return ['hasRequiredPreventiveEyeCare', 'preventiveCareRaisedFindings'];
  }

  getRiskSurchargeMatrix() {
    return null;
  }
}

export class AB implements TreeLogic<'AB'> {
  getRejection = () => {
    return false;
  };

  getAssess = ({
    examinationReports,
  }: Partial<SpecifyQuestionnaireModel['AB']>) => {
    if (examinationReports) {
      return true;
    }
    return false;
  };

  getQuestionOrder({
    hasRequiredPreventiveGynecologicalCare,
    preventiveCareRaisedFindings,
  }: Partial<
    SpecifyQuestionnaireModel['AB']
  >): (keyof SpecifyQuestionnaireModel['AB'])[] {
    if (hasRequiredPreventiveGynecologicalCare === true) {
      return ['hasRequiredPreventiveGynecologicalCare', 'examinationReports'];
    }

    if (preventiveCareRaisedFindings === true) {
      return [
        'hasRequiredPreventiveGynecologicalCare',
        'preventiveCareRaisedFindings',
        'examinationReports',
      ];
    }

    return [
      'hasRequiredPreventiveGynecologicalCare',
      'preventiveCareRaisedFindings',
    ];
  }

  getRiskSurchargeMatrix() {
    return null;
  }
}

export class AC implements TreeLogic<'AC'> {
  getRejection = () => {
    return false;
  };

  getAssess = ({
    examinationReports,
  }: Partial<SpecifyQuestionnaireModel['AC']>) => {
    if (examinationReports) {
      return true;
    }
    return false;
  };

  getQuestionOrder({
    hasRequiredPreventiveSkinCare,
    preventiveCareRaisedFindings,
  }: Partial<
    SpecifyQuestionnaireModel['AC']
  >): (keyof SpecifyQuestionnaireModel['AC'])[] {
    if (hasRequiredPreventiveSkinCare === true) {
      return ['hasRequiredPreventiveSkinCare', 'examinationReports'];
    }

    if (preventiveCareRaisedFindings === true) {
      return [
        'hasRequiredPreventiveSkinCare',
        'preventiveCareRaisedFindings',
        'examinationReports',
      ];
    }

    return ['hasRequiredPreventiveSkinCare', 'preventiveCareRaisedFindings'];
  }

  getRiskSurchargeMatrix() {
    return null;
  }
}

export class AD implements TreeLogic<'AD'> {
  getRejection = () => {
    return false;
  };

  getAssess = ({
    examinationReports,
  }: Partial<SpecifyQuestionnaireModel['AD']>) => {
    if (examinationReports) {
      return true;
    }
    return false;
  };

  getQuestionOrder({
    hasRoutineDentalCheckUpRaisedFindings,
  }: Partial<
    SpecifyQuestionnaireModel['AD']
  >): (keyof SpecifyQuestionnaireModel['AD'])[] {
    if (hasRoutineDentalCheckUpRaisedFindings === true) {
      return ['hasRoutineDentalCheckUpRaisedFindings', 'examinationReports'];
    }

    return ['hasRoutineDentalCheckUpRaisedFindings'];
  }

  getRiskSurchargeMatrix() {
    return null;
  }
}

export class AE implements TreeLogic<'AE'> {
  getRejection = () => {
    return false;
  };

  getAssess = ({
    examinationReports,
  }: Partial<SpecifyQuestionnaireModel['AE']>) => {
    if (examinationReports) {
      return true;
    }
    return false;
  };

  getQuestionOrder({
    hasEmploymentExaminationRaisedFindings,
  }: Partial<
    SpecifyQuestionnaireModel['AE']
  >): (keyof SpecifyQuestionnaireModel['AE'])[] {
    if (hasEmploymentExaminationRaisedFindings === true) {
      return ['hasEmploymentExaminationRaisedFindings', 'examinationReports'];
    }
    return ['hasEmploymentExaminationRaisedFindings'];
  }

  getRiskSurchargeMatrix() {
    return null;
  }
}

export class AF implements TreeLogic<'AF'> {
  getRejection = () => {
    return false;
  };

  getAssess = ({
    examinationReports,
  }: Partial<SpecifyQuestionnaireModel['AF']>) => {
    if (examinationReports) {
      return true;
    }
    return false;
  };

  getQuestionOrder(): (keyof SpecifyQuestionnaireModel['AF'])[] {
    return ['hasCheckUpRaisedFindings', 'examinationReports'];
  }

  getRiskSurchargeMatrix() {
    return null;
  }
}

export class AG implements TreeLogic<'AG'> {
  private age: number;

  constructor(age: number) {
    this.age = age;
  }

  getRejection = () => {
    return false;
  };

  getAssess = ({
    examinationReports,
    hasFullyHealed,
    hasBeenDeclaredUnfitToWorkForMoreThan14Days,
    hasBeenDeclaredUnfitToWorkForMoreThan28Days,
    howManyTimesHasExperiencedThisIssue,
  }: Partial<SpecifyQuestionnaireModel['AG']>) => {
    if (examinationReports) {
      return true;
    }

    if (hasFullyHealed === false) {
      return true;
    }

    if (hasBeenDeclaredUnfitToWorkForMoreThan14Days === true) {
      return true;
    }

    if (hasBeenDeclaredUnfitToWorkForMoreThan28Days === true) {
      return true;
    }

    if (howManyTimesHasExperiencedThisIssue === '3_TIMES_OR_MORE') {
      return true;
    }

    return false;
  };

  getQuestionOrder({
    hasBeenTreatedForSpineIssues,
    howManyTimesHasExperiencedThisIssue,
  }: Partial<
    SpecifyQuestionnaireModel['AG']
  >): (keyof SpecifyQuestionnaireModel['AG'])[] {
    if (hasBeenTreatedForSpineIssues === 'IN_PATIENT') {
      return ['hasBeenTreatedForSpineIssues', 'examinationReports'];
    }

    if (howManyTimesHasExperiencedThisIssue === '1_TIME') {
      return [
        'hasBeenTreatedForSpineIssues',
        'hasFullyHealed',
        'sinceWhenIsFullyHealed',
        'howManyTimesHasExperiencedThisIssue',
        'hasBeenDeclaredUnfitToWorkForMoreThan14Days',
      ];
    }

    if (howManyTimesHasExperiencedThisIssue === '2_TIMES') {
      return [
        'hasBeenTreatedForSpineIssues',
        'hasFullyHealed',
        'sinceWhenIsFullyHealed',
        'howManyTimesHasExperiencedThisIssue',
        'hasBeenDeclaredUnfitToWorkForMoreThan28Days',
      ];
    }

    return [
      'hasBeenTreatedForSpineIssues',
      'hasFullyHealed',
      'sinceWhenIsFullyHealed',
      'howManyTimesHasExperiencedThisIssue',
    ];
  }

  getRiskSurchargeMatrix({
    hasBeenDeclaredUnfitToWorkForMoreThan28Days,
    sinceWhenIsFullyHealed,
  }: Partial<SpecifyQuestionnaireModel['AG']>) {
    if (
      sinceWhenIsFullyHealed === '12_MONTHS_OR_LESS' &&
      hasBeenDeclaredUnfitToWorkForMoreThan28Days === false
    ) {
      return intermediateSurchargeMatrix(this.age);
    }
    return null;
  }
}
