import { Reducer } from 'redux';

import { QuestionIdInSectionId } from 'models/questions/general';
import { Action } from 'actions/type';
import { Condition } from 'models/medicalConditions';

export type InvestigationQuestionnaireModel = {
  [K in QuestionIdInSectionId<'medicalHistory'>]?: Condition[];
};

const initialState: InvestigationQuestionnaireModel = {};

const reducer: Reducer<InvestigationQuestionnaireModel, Action> = (
  state = initialState,
  action: Action
) => {
  switch (action.type) {
    case 'ANSWERED_INVESTIGATION':
      return {
        ...state,
        [action.question.questionId]: action.conditions,
      };
    default:
      return state;
  }
};

export default reducer;
