import React from 'react';
import { DateSelector } from '@popsure/dirty-swan';
import { useTranslation } from '@getpopsure/i18n-react';

import { WrappedForm } from 'pages/questions/formWrapper';
import Center from 'components/center';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';
import { useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { getTariffAndDeductible } from 'selectors/tariff';

export default () => {
  const { t } = useTranslation();

  const tariffAndDeductible = useSelector((state: AppState) =>
    getTariffAndDeductible(state)
  );

  const isShortTermTariff =
    tariffAndDeductible?.tariff === 'HiMedical' ||
    tariffAndDeductible?.tariff === 'HiMedicalPlus';

  return (
    <Center>
      <h1 className="p-h1">
        {generalQuestionsTitleMapping.relocationDate?.(t)}
      </h1>
      <p className="p-p mt8">
        {t(
          'questions.relocationDate.subTitle',
          'This can be an approximate date if you’re not certain.'
        )}
      </p>
      <WrappedForm
        sectionId="personalInfo"
        questionId="relocationDate"
        validator={() => true}
      >
        {({ value, setValue }) => (
          <div className="mt16">
            <DateSelector
              value={value}
              onChange={(date) => setValue(date)}
              yearBoundaries={{
                min: isShortTermTariff ? new Date().getFullYear() - 6 : 1990,
                max: new Date().getFullYear() + 6,
              }}
            />
          </div>
        )}
      </WrappedForm>
    </Center>
  );
};
