import React from 'react';
import { useTranslation } from '@getpopsure/i18n-react';

import { generateRadioInput } from 'pages/questions/generateInput';
import { WrappedForm } from 'pages/questions/formWrapper';
import { nonEmptyValidator } from 'validators';
import Center from 'components/center';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';
import {
  getPreviousHealthInsuranceOptionsTextMapping,
  getPreviousHealthInsuranceOptionsSecondaryTextMapping,
} from 'models/questions/general/insuranceHistoryV2';

export default () => {
  const { t } = useTranslation();

  return (
    <Center>
      <h1 className="p-h1">
        {generalQuestionsTitleMapping.mostRecentHealthInsurance?.(t)}
      </h1>
      <WrappedForm
        validator={nonEmptyValidator}
        sectionId="insuranceHistory"
        questionId="mostRecentHealthInsurance"
        label={getPreviousHealthInsuranceOptionsTextMapping(t)}
      >
        {({ value, setValue }) => (
          <div className="mt16">
            {generateRadioInput(
              'mostRecentHealthInsurance',
              value,
              getPreviousHealthInsuranceOptionsTextMapping(t),
              setValue,
              getPreviousHealthInsuranceOptionsSecondaryTextMapping(t)
            )}
          </div>
        )}
      </WrappedForm>
    </Center>
  );
};
