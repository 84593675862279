import { Reducer } from 'redux';
import { Action } from 'actions/type';

type State = Record<string, boolean>;

const reducer: Reducer<State, Action> = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_FEATURE_FLAGS':
      return action.featureFlags;
    default:
      return state;
  }
};

export default reducer;
