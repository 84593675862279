import View from './view';
import axios from 'axios';
import { connect } from 'react-redux';
import { AppState } from 'reducers';
import { onLoginCodeEntered } from 'actions';
import {
  getAsyncOperationInProgress,
  getAsyncOperationError,
} from 'selectors/asyncOperation';
import { formatErrorMessageFromError } from 'models/error';

interface StateProps {
  loading: boolean;
  isInvalid: boolean;
  error?: string;
}

interface OwnProps {
  email: string;
  signedInCallback: () => void;
}

interface DispatchProps {
  onLoginCodeEntered: (
    code: string,
    email: string,
    signedInCallback?: () => void
  ) => void;
}

export type ViewProps = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (state: AppState): StateProps => {
  const loading = getAsyncOperationInProgress(state, 'post.signin.code');
  let error = getAsyncOperationError(state, 'post.signin.code');
  let isInvalid = false;

  if (axios.isAxiosError(error) && error?.response?.status === 422) {
    error = new Error('The code you entered was not recognised.');
    isInvalid = true;
  }

  return {
    loading,
    isInvalid,
    error: formatErrorMessageFromError(error),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    onLoginCodeEntered: (
      code: string,
      email: string,
      signedInCallback?: () => void
    ) => {
      dispatch(onLoginCodeEntered(code, email, signedInCallback));
    },
  };
};

export default connect<StateProps, DispatchProps, OwnProps, AppState>(
  mapStateToProps,
  mapDispatchToProps
)(View);
