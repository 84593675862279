import classNames from 'classnames';
import { useTranslation } from '@getpopsure/i18n-react';
import React from 'react';

import { Condition } from 'models/medicalConditions';

import ConditionTablet from '../conditionTablet';

import styles from './style.module.scss';

export default ({
  selectedConditions,
  onDeselect,
  onSelect,
}: {
  selectedConditions: Condition[];
  onSelect: (condition: Condition) => void;
  onDeselect: (condition: Condition) => void;
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={classNames(styles['selected-conditions'], {
        [styles['selected-conditions--empty']]: selectedConditions.length === 0,
      })}
    >
      {selectedConditions.length > 0 ? (
        Array.from(selectedConditions).map((condition) => (
          <ConditionTablet
            key={condition.id}
            condition={condition}
            isSelected={true}
            isDismissable={true}
            onSelect={onSelect}
            onDeselect={onDeselect}
          />
        ))
      ) : (
        <p className={`p-p ${styles['nothing-selected-notice']}`}>
          {t('component.conditionslector.none.notice', 'No condition selected')}
        </p>
      )}
    </div>
  );
};
