import React from 'react';
import { useTranslation } from '@getpopsure/i18n-react';

import { WrappedForm } from 'pages/questions/formWrapper';
import { generateRadioInput } from 'pages/questions/generateInput';
import { nonEmptyValidator } from 'validators';
import Center from 'components/center';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';
import { InfoHint, InfoHintContainer } from 'components/infoHint';
import { eyeDioptersMapping } from 'models/questions/general/medicalHistory';

export default () => {
  const { t } = useTranslation();

  return (
    <Center>
      <h1 className="p-h1">
        {generalQuestionsTitleMapping.maximumEyeDiopters?.(t)}
      </h1>
      <InfoHintContainer className="mt8">
        <WrappedForm
          validator={nonEmptyValidator}
          sectionId="medicalHistory"
          questionId="maximumEyeDiopters"
          label={eyeDioptersMapping(t)}
        >
          {({ value, setValue }) =>
            generateRadioInput(
              'maximumEyeDiopters',
              value,
              eyeDioptersMapping(t),
              setValue
            )
          }
        </WrappedForm>
        <InfoHint
          title={t(
            'questions.maximumeyediopters.hint.title',
            'What is prescription strength?'
          )}
        >
          {t(
            'questions.maximumeyediopters.hint.description',
            'The strength, also called “lens power”, is measured in diopters and is found on your glasses or contact lenses prescription. This number determines the lens power needed to provide clear vision for each eye.'
          )}
        </InfoHint>
      </InfoHintContainer>
    </Center>
  );
};
