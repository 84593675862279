import Session from '@getpopsure/session';

const isDevEnv = process.env.NODE_ENV === 'development';
const apiEndpoint = process.env.REACT_APP_ENDPOINT;

const baseURL =
  isDevEnv || !apiEndpoint ? window.location.toString() : apiEndpoint;

function initializeSession() {
  Session.setBaseUrl(baseURL);
  Session.fetchCsrfToken();
}

export default initializeSession;
