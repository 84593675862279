import { financialHistoryQuestionOrder } from 'models/questions/general/financialHistory';
import { personalInfoQuestionOrder } from 'models/questions/general/personalInfo';
import { insuranceHistoryQuestionOrder as insuranceHistoryV2QuestionOrder } from 'models/questions/general/insuranceHistoryV2';
import {
  medicalHistoryQuestionOrder,
  MedicalHistoryQuestion,
} from 'models/questions/general/medicalHistory';
import { AssociatedShapeFromIdentifier } from 'utils/types';
import { AppState } from 'reducers';
import {
  GeneralSectionId,
  Section,
  GeneralQuestionId,
} from 'models/questions/general';
import { getQuestionsNeedInvestigation } from 'selectors';
import { getSpecifyQuestionOrderForQuestionId } from 'selectors/specify';
import { Question } from 'models/questions';

const generalQuestionsOrder: {
  [T in GeneralSectionId]: (
    state: AppState
  ) => AssociatedShapeFromIdentifier<Section, 'id', T>['question']['id'][];
} = {
  personalInfo: (state) => personalInfoQuestionOrder(state),
  financialHistory: (state) => financialHistoryQuestionOrder(state),
  insuranceHistory: (state) => insuranceHistoryV2QuestionOrder(state),
  medicalHistory: (state) => medicalHistoryQuestionOrder(state),
};

export const getQuestionOrder = (
  state: AppState,
  sectionId: GeneralSectionId
): Question[] => {
  let order: Question[] = [];
  const questionNeedsInvestigation = getQuestionsNeedInvestigation(state);

  (generalQuestionsOrder[sectionId](state) as GeneralQuestionId[]).forEach(
    (questionId) => {
      order.push({ type: 'general', sectionId, questionId });
      if (questionNeedsInvestigation.includes(questionId)) {
        order.push({ type: 'investigation', sectionId, questionId });
        order = order.concat(
          getSpecifyQuestionOrderForQuestionId(
            state,
            questionId as MedicalHistoryQuestion['id']
          )
        );
      }
    }
  );

  return order;
};

export const getSectionOrder = (): GeneralSectionId[] => {
  return Object.keys(generalQuestionsOrder) as GeneralSectionId[];
};
