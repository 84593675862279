import React from 'react';
import { AutocompleteAddress } from '@popsure/dirty-swan';
import { Address } from '@getpopsure/public-models';
import { useTranslation } from '@getpopsure/i18n-react';

import { WrappedForm } from 'pages/questions/formWrapper';
import { addressValidator } from 'validators';
import Center from 'components/center';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';

export default () => {
  const { t } = useTranslation();

  const skipButtonCaption = t(
    'questions.passportNumber.skip.action',
    'Provide later'
  );

  return (
    <Center>
      <h1 className="p-h1">{generalQuestionsTitleMapping.address?.(t)}</h1>
      <WrappedForm
        validator={addressValidator}
        sectionId="personalInfo"
        questionId="address"
        skipButtonProps={{
          caption: skipButtonCaption,
          skipValue: {
            street: '',
            houseNumber: '',
            postcode: '',
            city: '',
            country: '',
          },
        }}
      >
        {({ value, setValue }) => {
          const inputValue = value?.street === '' ? undefined : value;
          return (
            <div className="mt8">
              <AutocompleteAddress
                apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ''}
                address={inputValue}
                onAddressChange={(a) => setValue(a as Address)}
              />
            </div>
          );
        }}
      </WrappedForm>
    </Center>
  );
};
