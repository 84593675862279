import { INTERCOM_BASE_SETTINGS } from '../../constants';

/**
 * Initial Intercom configuration
 */
export const setupIntercomInitialConfiguration = async () => {
  if (!window.intercomSettings) {
    window.intercomSettings = {
      ...INTERCOM_BASE_SETTINGS,
    };
  }
};
