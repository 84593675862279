import { take, put, call, select } from 'redux-saga/effects';

import { ActionType } from 'actions/type';
import { resetStore } from 'actions';
import { createState } from 'utils/state';
import { path } from 'routes/path';
import { AppState } from 'reducers';
import { TariffReducerState } from 'reducers/tariff';
import { push } from '..';

export function* beginNewApplication() {
  while (true) {
    const actionType: ActionType = 'RESTART_QUESTIONNAIRE';

    yield take(actionType);

    const tariff: TariffReducerState = yield select(
      (state: AppState) => state.tariff
    );

    yield put(resetStore(createState({ tariff })));

    yield call(push, path.welcome);
  }
}
