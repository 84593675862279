import React from 'react';
import { useTranslation } from '@getpopsure/i18n-react';
import { YesNoQuestion } from 'pages/questions/generateInput';

import { WrappedForm } from 'pages/questions/formWrapper';
import { nonEmptyValidator } from 'validators';
import Center from 'components/center';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';

export default () => {
  const { t } = useTranslation();

  return (
    <Center>
      <h1 className="p-h1">
        {generalQuestionsTitleMapping.hadGermanHealthProvider?.(t)}
      </h1>
      <WrappedForm
        validator={nonEmptyValidator}
        sectionId="insuranceHistory"
        questionId="hadGermanHealthProvider"
      >
        {({ value, setValue }) => (
          <div className="mt16">
            <YesNoQuestion
              name="hadGermanHealthProvider"
              value={value}
              setValue={setValue}
            />
          </div>
        )}
      </WrappedForm>
    </Center>
  );
};
