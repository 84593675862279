import { treeIdFromConditionId } from 'models/medicalConditions';
import { BlockerId, FriendlyBlockerUrl } from 'models/blockers';
import { Question } from 'models/questions';
import { camelCaseToKebabCase } from 'utils/camelCaseToKebabCase';

export const path = {
  welcome: '/',
  blocker: '/blocker/:blockerId',
  questionInvestigation: '/questionnaire/:sectionId/:questionId/investigation',
  legalDocuments: '/legal-documents',
  brokerMandate: '/broker-mandate',
  confirmation: '/confirmation',
  answerCheck: '/answer-check',
  genericSpecifyQuestion:
    '/questionnaire/:sectionId/:questionId/specify/generic/:conditionId',
  sepcificSpecifyQuestion:
    '/questionnaire/:sectionId/:questionId/specify/:treeId/:conditionId/:specifyQuestionId',
  submitApplication: '/submit-application',
  setup: '/setup',
};

export function pathForQuestion(question: Question): string {
  const preffix = camelCaseToKebabCase(question.sectionId);
  const suffix = camelCaseToKebabCase(question.questionId);

  const base = `/questionnaire/${preffix}/${suffix}`;

  switch (question.type) {
    case 'general':
      return base;
    case 'investigation':
      return `${base}/investigation`;
    case 'specify':
      const treeId = treeIdFromConditionId(question.conditionId);

      if (treeId) {
        if (!question.specifyQuestionId) {
          throw new Error('Can’t find path for question for specify condition');
        }

        return `${base}/specify/${treeId}/${
          question.conditionId
        }/${camelCaseToKebabCase(question.specifyQuestionId)}`;
      } else {
        return `${base}/specify/generic/${question.conditionId}`;
      }
  }
}

export function pathForBlockerId(blockerId: BlockerId): string {
  const mapping: { [k in BlockerId]: FriendlyBlockerUrl } = {
    GENERIC: 'generic',
    UNIVERSITY_STUDENT: 'generic',
    EMPLOYED_OUTSIDE_GERMANY: 'employed-outside-germany',
    NOT_IN_GERMANY: 'not-in-germany',
    CIVIL_SERVANT: 'generic',
    BMI_OUT_OF_BOUND: 'generic',
    SPECIFY_BLOCKER: 'generic',
    HIV: 'generic',
    SUBSTANCE_ADDICTION: 'generic',
    MISSING_BODY_PART: 'generic',
    HIGH_DIOPTERS: 'generic',
    TOO_MANY_MISSING_TEETH: 'generic',
    OTHER_EMPLOYMENT: 'other',
    INCOME_ESTIMATE_TOO_LOW: 'generic',
    INCOME_TOO_LOW: 'generic',
    INCOME_EXPECTATION_TOO_LOW: 'generic',
    ZZ_CONDITION: 'pre-existing-condition',
    MORE_THAN_TEN_TEETH_AFFECTED: 'generic',
    INELIGIBLE_FOR_SHORT_TERM_TARIFF: 'no-short-term-tariff',
  };

  return `/blocker/${mapping[blockerId]}`;
}
