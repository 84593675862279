import { getIntercomIdentityHash } from 'network/api';

type GetVerificationHashPayload =
  | { status: 'NOTFOUND' }
  | { status: 'UNAUTHORIZED' }
  | { status: 'OK'; hash: string };

export const getVerificationHash = async (): Promise<GetVerificationHashPayload> => {
  try {
    const {
      data: { hash },
    } = await getIntercomIdentityHash();
    return { status: 'OK', hash };
  } catch (error) {
    const e = error.toJSON();

    if (e.status === 401) {
      return { status: 'UNAUTHORIZED' };
    }

    return { status: 'NOTFOUND' };
  }
};
