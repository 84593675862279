import React from 'react';
import { Gender } from '@getpopsure/public-models';
import { useTranslation, TranslationFn } from '@getpopsure/i18n-react';

import { WrappedForm } from 'pages/questions/formWrapper';
import { generateRadioInput } from 'pages/questions/generateInput';
import { nonEmptyValidator } from 'validators';
import Center from 'components/center';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';

const genderMapping = (t: TranslationFn): { [K in Gender]: string } => ({
  MALE: t('questions.gender.mapping.male', 'Male'),
  FEMALE: t('questions.gender.mapping.female', 'Female'),
  OTHER: t('questions.gender.mapping.nonconforming', 'Other'),
});

export default () => {
  const { t } = useTranslation();

  return (
    <Center>
      <h1 className="p-h1">{generalQuestionsTitleMapping.gender?.(t)}</h1>
      <div className="mt8">
        <WrappedForm
          validator={nonEmptyValidator}
          sectionId="personalInfo"
          questionId="gender"
          label={genderMapping(t)}
        >
          {({ value, setValue }) => (
            <>
              {generateRadioInput('gender', value, genderMapping(t), setValue)}
            </>
          )}
        </WrappedForm>
      </div>
    </Center>
  );
};
