import React from 'react';
import AnimateHeight from 'react-animate-height';
import { DateSelector } from '@popsure/dirty-swan';
import { useTranslation } from '@getpopsure/i18n-react';

import { WrappedForm } from 'pages/questions/formWrapper';
import { ageValidator, MINIMUM_SIGNUP_AGE } from 'validators';
import Center from 'components/center';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';

export default () => {
  const { t } = useTranslation();

  return (
    <Center>
      <h1 className="p-h1">{generalQuestionsTitleMapping.dateOfBirth?.(t)}</h1>
      <WrappedForm
        validator={ageValidator}
        sectionId="personalInfo"
        questionId="dateOfBirth"
      >
        {({ value, setValue }) => (
          <>
            <div className="mt8">
              <DateSelector
                value={value}
                onChange={(date) => setValue(date)}
                yearBoundaries={{
                  min: 1900,
                  max: new Date().getFullYear() - 18,
                }}
              />
            </div>
            <AnimateHeight
              duration={300}
              height={value && ageValidator(value) === false ? 'auto' : 0}
            >
              <div className="p-notice--warning mt16 wmx6">
                {t(
                  'questions.dateofbirth.warning.notice',
                  'You must be at least {{MINIMUM_SIGNUP_AGE}} years old in order to sign up for private health insurance.',
                  { MINIMUM_SIGNUP_AGE }
                )}
              </div>
            </AnimateHeight>
          </>
        )}
      </WrappedForm>
    </Center>
  );
};
