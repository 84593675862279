import { IntercomSettings } from './models';

export const INTERCOM_API_BASE = 'https://api-iam.eu.intercom.io';

export const INTERCOM_BASE_SETTINGS: IntercomSettings = {
  api_base: INTERCOM_API_BASE,
  app_id: process.env.REACT_APP_INTERCOM_APP_ID,
  hide_default_launcher: true,
  custom_launcher_selector: '#intercom_custom_button',
};
