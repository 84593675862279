import { Reducer } from 'redux';
import cloneDeep from 'lodash.clonedeep';

import { GeneralQuestionnaireModel } from 'models/questions/general';
import { Action } from 'actions/type';
import { Quote, User } from 'models';

export type State = {
  quote?: Quote & { id: string };
  user?: User;
  questionnaire?: { id: string; answers: GeneralQuestionnaireModel };
};

const initialState: State = {};

const reducer: Reducer<State, Action> = (state = initialState, action) => {
  switch (action.type) {
    case 'MERGE_QUOTE':
      return {
        ...state,
        quote: { ...action.quote, id: action.id },
        user: action.userInfo ?? state.user,
      };
    case 'MERGE_USER':
      return { ...state, user: action.user };
    case 'MERGE_QUESTIONNAIRE':
      return { ...state, questionnaire: action.questionnaire };
    case 'CLEAR_REMOTE_DATA':
      const newState = cloneDeep(state);
      delete newState[action.key];
      return newState;
    case 'CLEAR_ALL_REMOTE_DATA':
      return initialState;
    default:
      return state;
  }
};

export default reducer;
