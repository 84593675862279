import React, { useState, useEffect, useCallback } from 'react';
import { Input } from '@popsure/dirty-swan';
import { setAsyncOperationErrored } from 'actions/asyncOperation';

import AnimateHeight from 'react-animate-height';
import styles from './style.module.scss';
import { startPhoneNumberVerification, confirmPhoneNumber } from 'actions';
import { ReactComponent as EditIcon } from './img/edit.svg';

interface ViewProps {
  phoneNumber: string;
  error?: string;
  loading: boolean;
  dispatch: any;
  setShowPhoneVerify: (arg0: boolean) => void;
}

const PhoneVerification = ({
  setShowPhoneVerify,
  phoneNumber,
  loading,
  error,
  dispatch,
}: ViewProps) => {
  const [code, setCode] = useState('');
  const valid = code.length > 0;

  const handleStartPhoneVerification = useCallback((): void => {
    if (phoneNumber) {
      dispatch(startPhoneNumberVerification(phoneNumber));
    }
  }, [dispatch, phoneNumber]);

  useEffect(() => {
    if (!loading) {
      handleStartPhoneVerification();
    }
  }, [handleStartPhoneVerification, loading]);

  const handleConfirmNumber = (
    event: React.FormEvent<HTMLFormElement>
  ): void => {
    event.preventDefault();
    if (code.length !== 6) {
      dispatch(
        setAsyncOperationErrored(
          'post.phone.confirm',
          new Error('The code should be 6 digits long.')
        )
      );
      return;
    }
    dispatch(confirmPhoneNumber(phoneNumber, code));
  };

  return (
    <>
      <h1 className="p-h1 ta-center">Secure and sign your application</h1>
      <p className={`p-p ta-center wmx8 mt16 d-block ml-auto mr-auto`}>
        We sent a <strong className="fw-bold">6-digit verification code</strong>{' '}
        via SMS to the number below. Think of this as a way to secure your
        application but also as your digital signature.
      </p>
      <p className={`p-p mt24 ta-center`}>
        {loading ? 'Sending the code...' : 'Code sent to:'}
      </p>
      <p
        className={`p-a mt8 ta-center fw-bold ${styles['phone-number']}`}
        onClick={() => setShowPhoneVerify(false)}
      >
        {phoneNumber}
        <EditIcon className="ml8" />
      </p>
      <div className={`ml-auto mr-auto mt24 ${styles['phone-image']}`} />
      <div
        onClick={handleStartPhoneVerification}
        className={`p-a ta-center fw-bold ${styles['resend-button']}`}
      >
        Resend SMS
      </div>
      <form onSubmit={handleConfirmNumber}>
        <Input
          data-cy="broker-mandate"
          className={`w100 wmx4 mt40 d-block ml-auto mr-auto`}
          placeholder="6-Digit Verification Code"
          required={true}
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          autoComplete="one-time-code"
          onChange={(e) => {
            setCode(e.target.value);
          }}
        />
        <AnimateHeight
          duration={300}
          height={error && error.length > 0 ? 'auto' : 0}
        >
          <div
            className={`p-notice--danger mt16 ws4 wmx100 ml-auto mr-auto ${styles.error}`}
          >
            {error}
          </div>
        </AnimateHeight>
        <button
          data-cy="broker-mandate-submit"
          type="submit"
          disabled={!valid || loading}
          className={`w100 wmx4 p-btn--primary mt24 wmn3 d-block ml-auto mr-auto`}
        >
          Confirm
        </button>
      </form>
    </>
  );
};

export default PhoneVerification;
