import React from 'react';
import { useTranslation } from '@getpopsure/i18n-react';

import { WrappedForm } from 'pages/questions/formWrapper';
import { generateCheckboxInput } from 'pages/questions/generateInput';
import { nonEmptyArrayValidator } from 'validators';
import Center from 'components/center';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';
import {
  DentalCondition,
  dentalConditionMapping,
} from 'models/questions/general/medicalHistory';

export default () => {
  const { t } = useTranslation();

  return (
    <Center>
      <h1 className="p-h1">
        {generalQuestionsTitleMapping.dentalCondition?.(t)}
      </h1>
      <WrappedForm
        validator={nonEmptyArrayValidator}
        sectionId="medicalHistory"
        questionId="dentalCondition"
        label={dentalConditionMapping(t)}
      >
        {({ value, setValue }) => (
          <div className="mt8">
            {generateCheckboxInput(
              'dentalCondition',
              value,
              dentalConditionMapping(t),
              (updated: DentalCondition[]) => {
                const diff = updated.filter((x) => !value?.includes(x));
                let newValue: DentalCondition[];
                if (diff.includes('NONE')) {
                  newValue = ['NONE'];
                } else {
                  newValue = updated.filter((x) => x !== 'NONE');
                }

                setValue(newValue);
              }
            )}
          </div>
        )}
      </WrappedForm>
    </Center>
  );
};
