import { MaritalStatus, Name } from 'models';
import { Gender, Address, Country } from '@getpopsure/public-models';
import { TranslationFn } from '@getpopsure/i18n-react';
import { AppState } from 'reducers';
import { addQuestionAfter } from 'models/questions/general';

import { containsEEACountry } from 'utils/containsEEACountry';

export type ResidencePermitType =
  | 'LIMITED_RESIDENCE_PERMIT'
  | 'UNLIMITED_RESIDENCE_PERMIT';

export type ResidencePermitStatus = 'HAVE' | 'APPLIED' | 'WILL_APPLY';

export type IdentificationDocument = 'PASSPORT' | 'NATIONAL_ID' | '';

export const residencePermitTypeMapping = (
  t: TranslationFn
): {
  [K in ResidencePermitType]: string;
} => ({
  LIMITED_RESIDENCE_PERMIT: t(
    'questions.residencepermittype.mapping.limited',
    'Limited residence permit'
  ),
  UNLIMITED_RESIDENCE_PERMIT: t(
    'questions.residencepermittype.mapping.unlimited',
    'Unlimited residence permit'
  ),
});

export type PersonalInfoQuestion =
  | {
      id: 'name';
      answer: Name;
    }
  | {
      id: 'email';
      answer: string;
    }
  | {
      id: 'phoneNumber';
      answer: string;
    }
  | {
      id: 'dateOfBirth';
      answer: string;
    }
  | {
      id: 'gender';
      answer: Gender;
    }
  | {
      id: 'address';
      answer: Address;
    }
  | {
      id: 'maritalStatus';
      answer: MaritalStatus;
    }
  | {
      id: 'nationality';
      answer: Country[];
    }
  | {
      id: 'relocationDate';
      answer: string;
    }
  | {
      id: 'livedInGermanyForLast36Months';
      answer: boolean;
    }
  | {
      id: 'residencePermitStatus';
      answer: ResidencePermitStatus;
    }
  | {
      id: 'whatTypeOfResidencePermit';
      answer: ResidencePermitType;
    }
  | {
      id: 'identificationDocument';
      answer: IdentificationDocument;
    }
  | {
      id: 'passportNumber';
      answer: string;
    }
  | {
      id: 'passportExpiry';
      answer: string;
    }
  | {
      id: 'nationalIdNumber';
      answer: string;
    }
  | {
      id: 'nationalIdExpiry';
      answer: string;
    }
  | {
      id: 'personalInfoReview';
      answer: boolean;
    };

export const personalInfoQuestionOrder = (
  state: AppState
): PersonalInfoQuestion['id'][] => {
  const {
    nationality,
    livedInGermanyForLast36Months,
    identificationDocument,
    nationalIdNumber,
    passportNumber,
  } = state.questionnaire?.personalInfo || {};
  const isEEACitizen = nationality && containsEEACountry(nationality);
  const isNationalIdAllowed =
    state?.featureFlags?.private_signup_national_id_document;

  const passportQuestions: PersonalInfoQuestion['id'][] = passportNumber
    ? ['passportNumber', 'passportExpiry']
    : ['passportNumber'];

  const nationalIdQuestions: PersonalInfoQuestion['id'][] = nationalIdNumber
    ? ['nationalIdNumber', 'nationalIdExpiry']
    : ['nationalIdNumber'];

  let order: PersonalInfoQuestion['id'][] = [
    'name',
    'email',
    'dateOfBirth',
    'gender',
    'address',
    'maritalStatus',
    'nationality',
    'personalInfoReview',
  ];

  let questionsToAdd: PersonalInfoQuestion['id'][] = [];

  // Non-EU / EAA citizen (eligible for short-term)
  if (!isEEACitizen) {
    questionsToAdd = [
      'relocationDate',
      'residencePermitStatus',
      'whatTypeOfResidencePermit',
      ...passportQuestions,
    ];
    // EU / EAA citizen (not eligible for short-term)
  } else {
    const idDocumentQuestions: PersonalInfoQuestion['id'][] = isNationalIdAllowed
      ? ['identificationDocument']
      : [];

    const identificationDocumentQuestions: PersonalInfoQuestion['id'][] = !isNationalIdAllowed
      ? passportQuestions
      : {
          PROVIDE_LATER: [],
          NATIONAL_ID: nationalIdQuestions,
          PASSPORT: passportQuestions,
        }[identificationDocument || 'PROVIDE_LATER'];

    questionsToAdd = livedInGermanyForLast36Months
      ? [
          'livedInGermanyForLast36Months',
          ...idDocumentQuestions,
          ...identificationDocumentQuestions,
        ]
      : [
          'livedInGermanyForLast36Months',
          'relocationDate',
          ...idDocumentQuestions,
          ...identificationDocumentQuestions,
        ];
  }

  order = addQuestionAfter({
    questionsToAdd,
    afterQuestion: 'nationality',
    order,
  }) as PersonalInfoQuestion['id'][];

  return order;
};
