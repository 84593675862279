import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';

import Center from 'components/center';
import styles from './style.module.scss';
import connectedAnswer from 'pages/questions/connectedAnswer';

import { GeneralQuestionId } from 'models/questions/general';
import { questionnaireLegalText } from 'models/questions/text/legal';
import { connectedRedirect } from 'components/connectedRedirect';
import { useTranslation, TranslationFn } from '@getpopsure/i18n-react';

type PickFromQuestionId<T extends GeneralQuestionId> = T;

type DisclaimerQuestionIdType = PickFromQuestionId<
  'dataProcessingDisclaimer' | 'insuranceLawDisclaimer'
>;

const mapping = (
  t: TranslationFn
): {
  [k in DisclaimerQuestionIdType]: {
    title: string;
    text: string;
    insurerText?: string;
  };
} => ({
  dataProcessingDisclaimer: {
    title: t('page.disclaimer.1.title', 'Before we continue… (1 of 2)'),
    text: questionnaireLegalText.medicalHistory!.dataProcessingDisclaimer!,
  },
  insuranceLawDisclaimer: {
    title: t('page.disclaimer.2.title', 'Before we continue… (2 of 2)'),
    text: questionnaireLegalText.medicalHistory!.insuranceLawDisclaimer!,
    insurerText: t(
      'page.disclaimer.2.description',
      'The following questions are asked by the insurer (Hallesche Krankenversicherung a.G.) to determine the details of your coverage.'
    ),
  },
});

export default <Q extends DisclaimerQuestionIdType>({
  questionId,
}: {
  questionId: Q;
}) => {
  function View({
    onAnswerQuestion,
    answer,
  }: {
    onAnswerQuestion: (answer: any) => void;
    answer?: boolean;
  }) {
    const [agreedToTerms, setAgreedToTerms] = useState(answer ?? false);
    const { t } = useTranslation();

    return (
      <Center>
        <h1 className="p-h1">{mapping(t)[questionId].title}</h1>
        {mapping(t)[questionId].insurerText && (
          <p className="p-p mt8 wmx8">{mapping(t)[questionId].insurerText}</p>
        )}
        <p className="p-p mt8">
          {t(
            'page.disclaimer.disclaimer.description',
            'It is important that you are aware of the following:'
          )}
        </p>
        <div className={`mt16 wmx5 p-p p-p--small ${styles['text-container']}`}>
          <ReactMarkdown
            linkTarget="_blank"
            source={mapping(t)[questionId].text}
          />
        </div>
        <div className="p-label-container mt24" data-cy="legal-accept-tnc">
          <input
            id="terms-checkbox"
            className="p-checkbox"
            type="checkbox"
            checked={agreedToTerms}
            onChange={() => {
              setAgreedToTerms(!agreedToTerms);
            }}
            onClick={() => {
              setAgreedToTerms(!agreedToTerms);
            }}
          />
          <label
            data-cy="questionnaire-legal-agree"
            style={{ minHeight: 0 }} // TODO: Issue with dirtyswan here
            className="p-label"
            htmlFor="terms-checkbox"
          >
            {t(
              'page.disclaimer.legal.description',
              'I have read and agree to the terms above'
            )}
          </label>
        </div>
        <button
          disabled={!agreedToTerms}
          data-cy="questionnaire-legal-continue"
          className="p-btn--primary mt24 wmn3"
          onClick={() => {
            onAnswerQuestion(true);
          }}
        >
          {t('page.disclaimer.legal.continue', 'Continue')}
        </button>
      </Center>
    );
  }

  const ConnectedView = connectedRedirect(
    { type: 'general', sectionId: 'medicalHistory', questionId },
    connectedAnswer('medicalHistory', questionId)(View)
  );

  return <ConnectedView />;
};
