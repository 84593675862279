import React from 'react';
import { CurrencyInput } from '@popsure/dirty-swan';
import { useTranslation } from '@getpopsure/i18n-react';

import { WrappedForm } from 'pages/questions/formWrapper';
import { nonEmptyValidator } from 'validators';
import Center from 'components/center';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';

export default () => {
  const { t } = useTranslation();

  return (
    <Center>
      <WrappedForm
        validator={nonEmptyValidator}
        sectionId="financialHistory"
        questionId="incomeExpectations"
      >
        {({ value, setValue }) => (
          <>
            <h1 className="p-h1">
              {generalQuestionsTitleMapping.incomeExpectations?.(t)}
            </h1>
            <CurrencyInput
              className="w100 mt8 wmx5"
              placeholder={t(
                'questions.incomeexpectation.input.placeholder',
                'Earning expectation'
              )}
              type="text"
              value={value}
              onChange={setValue}
              required={true}
            />
          </>
        )}
      </WrappedForm>
    </Center>
  );
};
