/**
 * This is usefull when using mapped type over an identifier,
 * You can extract the shape associated with that identifier
 *
 */
export type AssociatedShapeFromIdentifier<
  T,
  V extends keyof T,
  U extends T[V]
> = T extends { [L in V]: U } ? T : never;

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

export type StringOrNever<I> = I extends string ? I : never;

// https://jpwilliams.dev/how-to-unpack-the-return-type-of-a-promise-in-typescript
export type AsyncReturnType<T extends (...args: any) => any> = T extends (
  ...args: any
) => Promise<infer U>
  ? U
  : T extends (...args: any) => infer U
  ? U
  : any;

export function noEmpty<T>(value: T | null | undefined): value is T {
  return value !== undefined && value !== null;
}
