import React from 'react';
import { useTranslation } from '@getpopsure/i18n-react';

import { WrappedForm } from 'pages/questions/formWrapper';
import { nonEmptyValidator } from 'validators';
import { YesNoQuestion } from 'pages/questions/generateInput';
import Center from 'components/center';
import { InfoHint, InfoHintContainer } from 'components/infoHint';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';

export default () => {
  const { t } = useTranslation();

  return (
    <Center>
      <h1 className="p-h1">
        {generalQuestionsTitleMapping.unableToProduceChildren?.(t)}
      </h1>
      <WrappedForm
        validator={nonEmptyValidator}
        sectionId="medicalHistory"
        questionId="unableToProduceChildren"
      >
        {({ value, setValue }) => (
          <InfoHintContainer className="mt8">
            <>
              <div>
                <YesNoQuestion
                  name={'unableToProduceChildren'}
                  value={value}
                  setValue={setValue}
                />
              </div>
              {value === true && (
                <InfoHint
                  title={t(
                    'questions.unabletoproducechildren.hint.title',
                    'This will affect your policy'
                  )}
                >
                  {t(
                    'questions.unabletoproducechildren.hint.description',
                    'We will be able to get you covered, but unfortunately all costs related to the unfulfilled wish for a child will not be covered under the policy.'
                  )}
                </InfoHint>
              )}
            </>
          </InfoHintContainer>
        )}
      </WrappedForm>
    </Center>
  );
};
