import React, { useEffect } from 'react';

import Session from '@getpopsure/session';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'reducers';
import { signOut } from 'actions/session';
import { getUser } from 'selectors/remoteData';
import { fetchUser } from 'actions';

interface ViewProps {
  children: React.ReactNode;
}

const View = ({ children }: ViewProps) => {
  const dispatch = useDispatch();
  const isAuthenticated = Session.isAuthenticated;

  const user = useSelector(getUser);
  const existingCustomer = useSelector(
    (state: AppState) => state.session.existingCustomer
  );

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchUser());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (existingCustomer && user && !isAuthenticated) {
      dispatch(signOut());
    }
  }, [dispatch, existingCustomer, isAuthenticated, user]);

  return <>{children}</>;
};

export default View;
