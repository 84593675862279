import React from 'react';
import { insurance } from '@getpopsure/private-constants';
import { useTranslation } from '@getpopsure/i18n-react';
import { Trans } from '@getpopsure/i18n-react';

import Center from 'components/center';
import { InfoHint, InfoHintContainer } from 'components/infoHint';
import { WrappedForm } from 'pages/questions/formWrapper';
import { trueValidator } from 'validators';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';

export default () => {
  const { t } = useTranslation();

  return (
    <Center>
      <InfoHintContainer className="mt8">
        <WrappedForm
          validator={trueValidator}
          sectionId="financialHistory"
          questionId="schufaConsent"
        >
          {({ value, setValue }) => (
            <>
              <h1 className="p-h1">
                {generalQuestionsTitleMapping.schufaConsent?.(t)}
              </h1>
              <div className="wmx6">
                <p className="p-p mt8">
                  <Trans i18nKey="questions.consenttoschufa.legal.text">
                    By selecting “I accept”, I consent to the exchange of data
                    with Schufa in accordance with the{' '}
                    <a
                      className="p-a"
                      href={insurance.privateHealth.schufa}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Schufa information
                    </a>
                    .
                  </Trans>
                </p>
                <div
                  className="p-label-container mt16"
                  data-cy="questionnaire-schufa"
                >
                  <input
                    className="p-checkbox"
                    type="radio"
                    id="schufa"
                    checked={value ?? false}
                    onClick={() => {
                      setValue(value === undefined ? true : !value);
                    }}
                  />
                  <label className="p-label" htmlFor="schufa">
                    {t(
                      'questions.consenttoschufa.legal.accept.action',
                      'I accept the terms above'
                    )}
                  </label>
                </div>
              </div>
            </>
          )}
        </WrappedForm>
        <InfoHint
          title={t('questions.consenttoschufa.hint.title', 'What is Schufa?')}
        >
          <Trans i18nKey="questions.consenttoschufa.hint.description">
            Schufa is a credit bureau. Based on data from banks and other
            institutions, it assesses your creditworthiness. Why it is used for
            your health insurance: the risk carrier needs to check whether the
            monthly costs of the health insurance will be covered by their
            customers, so they can offer a safe and stable product.
          </Trans>
        </InfoHint>
      </InfoHintContainer>
    </Center>
  );
};
