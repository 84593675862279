import axios, { AxiosError, AxiosInstance } from 'axios';

import { transformQuestionnaireModel } from './transformQuestionnaireModel';
import { Quote, User, BankAccountDetails } from 'models';
import { AuthenticatedResponse } from 'models/network';
import { SubmitBankAccountDetail } from 'models/bankAccount';
import { v4 as uuidv4 } from 'uuid';
import Session, { CSRF_TOKEN_KEY } from '@getpopsure/session';
import { store } from 'reducers';
import * as Sentry from '@sentry/browser';
import { clearAllRemoteData } from 'actions';
import { signOut } from 'actions/session';
import { Action } from 'actions/type';

import { AssociatedShapeFromIdentifier } from 'utils/types';

export type AssociatedActionFromType<
  T extends Action['type']
> = AssociatedShapeFromIdentifier<Action, 'type', T>;

const isDevEnv = process.env.NODE_ENV === 'development';
const apiEndpoint = process.env.REACT_APP_ENDPOINT;

const endpoint =
  isDevEnv || !apiEndpoint
    ? `${window.location.protocol}//${window.location.host}`
    : apiEndpoint;

const isRunningOnCypress = (): boolean => {
  return !!window.Cypress;
};

const instance: AxiosInstance = axios.create({
  baseURL: endpoint,
  xsrfCookieName: CSRF_TOKEN_KEY,
  xsrfHeaderName: 'csrf-token',
  withCredentials: true,
  headers: {
    'auth-version': 'v2',
    ...(isRunningOnCypress() ? { 'x-feather-e2e-tests': true } : {}),
  },
});

const handleOnAccountRefreshError = (response?: Response | void) => {
  const isSignoutRequest =
    typeof response !== 'undefined' && response?.url === '/account/signout';

  if (response?.status === 401 && !isSignoutRequest) {
    store.dispatch(clearAllRemoteData());
    store.dispatch(signOut());

    if (Session.isAuthenticated) {
      Sentry.captureMessage(
        '[SESSION_ERROR]: Returned with a 401 while being authenticated on account refresh',
        Sentry.Severity.Error
      );
    }
  }
};

const handleSignoutError = (error: AxiosError) => {
  if (error?.response) {
    const isSignoutRequest = error.response.config.url === '/account/signout';

    if (error.response.status === 401 && !isSignoutRequest) {
      store.dispatch(clearAllRemoteData());
      store.dispatch(signOut());

      if (Session.isAuthenticated) {
        Sentry.captureMessage(
          '[SESSION_ERROR]: Returned with a 401 while being authenticated',
          Sentry.Severity.Error
        );
      }
    }
  }
};

Session.addAxiosInterceptors(
  instance,
  handleOnAccountRefreshError,
  handleSignoutError
);

export { instance };

export async function sendSignInEmail(email: string) {
  return instance.post('/account/signin', {
    email,
  });
}

export async function verifyCustomerEmail(email: string) {
  return instance.post<{ email: string; userExists: boolean }>(
    '/user/email_validation',
    { email }
  );
}

export async function signInWithTemporaryLoginCode(
  code: string,
  email: string
) {
  return instance.post<AuthenticatedResponse>('/account/signin/code', {
    email,
    code,
  });
}

export async function createQuestionnaireUpload(
  file: File,
  questionId: string
) {
  return instance.post<{
    id: string;
    token: string;
    uploadUrl: string;
    downloadUrl?: string;
  }>('/questionnaires/uploads', {
    filename: file.name,
    questionId,
  });
}

export async function createQuestionnaire(
  questionnaire: ReturnType<typeof transformQuestionnaireModel>
) {
  return instance.post<{ id: string; answers: any }>(
    '/questionnaires/',
    questionnaire
  );
}

export async function downloadTakeawayArchives(questionnaireId: string) {
  return instance.get<{ completed: number; downloadUrl: string }>(
    `/questionnaires/${questionnaireId}/takeaway_archive`
  );
}

export async function downloadApplicationDocuments(applicationId: string) {
  return instance.get<{ completed: number; downloadUrl: string }>(
    `/applications/${applicationId}/document`
  );
}

export async function createUser(
  userData: Pick<
    User,
    'email' | 'firstName' | 'lastName' | 'dateOfBirth' | 'gender'
  >
) {
  return instance.post<AuthenticatedResponse>('/user', userData);
}

export async function uploadFile(
  file: File,
  uploadUrl: string,
  onUploadProgress?: (p: { loaded: number; total: number }) => void
) {
  return instance.put(uploadUrl, file, {
    withCredentials: false,
    timeout: 0,
    onUploadProgress,
  });
}

export async function startPhoneVerification(phoneNumber: string) {
  return instance.post('/account/phone/verify', { phoneNumber });
}

export async function confirmPhoneNumber(phoneNumber: string, code: string) {
  return instance.post('/account/phone/confirm', { phoneNumber, code });
}

export async function submitBankAccountDetail({
  bankAccountDetails,
  quoteId,
  referralCode,
}: {
  bankAccountDetails: BankAccountDetails;
  quoteId: string;
  referralCode?: string;
}) {
  return instance.post<SubmitBankAccountDetail>(
    `/quotes/${quoteId}/checkout`,
    {
      ...bankAccountDetails,
      ...(referralCode ? { referralCode } : {}),
    },
    {
      headers: {
        'idempotency-key': uuidv4(),
      },
    }
  );
}

export async function setCheckoutStarted(email: string) {
  return instance.post('/customerio/checkout_started', {
    email,
    insuranceType: 'PRIVATE_HEALTH',
  });
}

export async function getQuote(questionnaireId: string) {
  return instance.post<{ id: string; tariffInfo: Quote; userInfo?: User }[]>(
    `/quotes/for_questionnaire/${questionnaireId}`
  );
}

export async function getUser() {
  return instance.get<User>('/me');
}

export async function signOutUser() {
  return instance.delete('/account/signout');
}

export async function getIntercomIdentityHash() {
  return instance.post('/me/intercom_identity_hash');
}

export async function trackFinanceAdsConversion({
  policyId,
}: {
  policyId: string;
}) {
  return instance.post('/finance_ads/track_conversion', {
    policyId,
    financeAdsCategory: 'eng_privatehealth',
  });
}
