import React from 'react';

export default <Q extends any>({
  onAnswerQuestion,
  valid,
  value,
  children,
}: {
  onAnswerQuestion: (answer: Q) => void;
  valid: boolean;
  value: Q;
  children: React.ReactNode;
}) => (
  <>
    <form
      onSubmit={(e) => {
        e.preventDefault();

        if (!valid) {
          return;
        }
        onAnswerQuestion(value);
      }}
    >
      {children}
    </form>
  </>
);
