import { MultiDropzone } from '@popsure/dirty-swan';
import { UploadedFile } from 'models';
import { useUploadFiles } from 'utils/hooks';
import React, { useEffect } from 'react';

interface Props {
  value: UploadedFile[];
  questionId: string;
  setUploadedFiles: (files: UploadedFile[]) => void;
  validator: any;
  storeAnsweredQuestion?: (answer: any, label?: any) => void;
}

export const UploadDocComponent = ({
  questionId,
  value,
  setUploadedFiles,
  validator,
  storeAnsweredQuestion,
}: Props) => {
  const {
    isSubmitValid,
    uploadFile,
    uploadedFiles,
    removeFile,
    loading,
  } = useUploadFiles({
    initialValue: value,
    questionId,
    onUploadComplete: (uploadedFiles) => storeAnsweredQuestion?.(uploadedFiles),
  });

  useEffect(() => {
    setUploadedFiles(uploadedFiles);
  }, [uploadedFiles, setUploadedFiles]);

  useEffect(() => {
    validator(isSubmitValid);
  }, [isSubmitValid, validator]);

  const removeUploadedFile = (fileId: string) => {
    const filteredUploadedFiles = uploadedFiles.filter(
      ({ id }) => id !== fileId
    );
    removeFile(fileId);
    setUploadedFiles([...filteredUploadedFiles]);
  };

  return (
    <MultiDropzone
      onFileSelect={uploadFile}
      uploading={loading}
      uploadedFiles={uploadedFiles}
      onRemoveFile={removeUploadedFile}
    />
  );
};
