import React from 'react';
import { useTranslation } from '@getpopsure/i18n-react';
import { Trans } from '@getpopsure/i18n-react';

import { WrappedForm } from 'pages/questions/formWrapper';
import { nonEmptyValidator } from 'validators';
import { YesNoQuestion } from 'pages/questions/generateInput';
import Center from 'components/center';
import { InfoHint, InfoHintContainer } from 'components/infoHint';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';

import styles from './style.module.scss';

export default () => {
  const { t } = useTranslation();
  return (
    <Center>
      <h1 className="p-h1">
        {generalQuestionsTitleMapping.hasGermanTaxId?.(t)}
      </h1>
      <InfoHintContainer className="mt8">
        <WrappedForm
          validator={nonEmptyValidator}
          sectionId="financialHistory"
          questionId="hasGermanTaxId"
        >
          {({ value, setValue }) => (
            <YesNoQuestion
              name="hasGermanTaxId"
              value={value}
              setValue={setValue}
            />
          )}
        </WrappedForm>
        <InfoHint
          title={t('questions.hasgermantaxid.hint.title', 'What is a tax ID?')}
        >
          <Trans i18nKey="questions.hasgermantaxid.hint.description">
            Your tax ID (steuerliche Identifikationsnummer or Steuer-ID) is a
            unique number automatically assigned and sent to you when you first
            register in Germany. This is not your tax number (Steuernummer).
            <ul className={styles.ul}>
              <li>
                Hallesche Krankenversicherung a.G. will pass on to the tax
                authorities the amounts of the tax reducing health and
                compulsory nursing care premiums as well as the necessary
                personal data of you.
              </li>
              <li>
                We ask you to give us the tax ID in order to pass on this data.
                If you do not provide your tax ID, we are entitled to obtain it
                from the Federal Central Tax office.
              </li>
            </ul>
          </Trans>
        </InfoHint>
      </InfoHintContainer>
    </Center>
  );
};
