import * as Sentry from '@sentry/browser';

import { version } from '../../../package.json';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://2e07028732444b629a0dcdb5d291483e@sentry.io/5030497',
    environment: process.env.REACT_APP_ENVIRONMENT,
    release: version,
  });
}
