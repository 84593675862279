import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { GeneralSectionId } from 'models/questions/general';
import View from './components';
import { AppState } from 'reducers';
import { questionFromPathName } from 'routes';

interface StateProps {
  currentSection?: GeneralSectionId;
}
export type OwnProps = RouteComponentProps<Record<string, any>>;

export type ViewProps = StateProps & OwnProps;

const mapStateToProps = (
  _: AppState,
  { location: { pathname } }: OwnProps
): StateProps => ({
  currentSection: questionFromPathName(pathname)?.sectionId,
});

export default withRouter(connect(mapStateToProps)(View));
