const formatWord = (word?: string) => {
  if (!word || word.length === 0) {
    return '';
  }

  // Camel case to separate words
  const [firstLetter, ...rest] = word
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .toLowerCase()
    .split('');

  // Capitalize first letter
  return `${firstLetter.toUpperCase()}${rest.join('')}`;
};

const getDetailsFromPath = (path: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [detailsStr, categoryStr, ...rest] = path.split('/').reverse();

  return [
    formatWord(categoryStr?.replaceAll('-', ' ')),
    formatWord(detailsStr?.replaceAll('-', ' ')),
  ]
    .filter(Boolean)
    .join(' - ');
};

export const generatePageTitle = (path: string) => getDetailsFromPath(path);
