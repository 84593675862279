import React, { useState } from 'react';
import { Trans, useTranslation } from '@getpopsure/i18n-react';
import { useChat } from 'react-live-chat-loader';
import Form from 'components/form';
import Center from 'components/center';
import connectedAnswer, {
  ViewProps as GenericViewProps,
} from 'pages/questions/connectedAnswer';
import { BlockerIcon } from 'pages/blocker/view';

// This "semi-blocker" screen is shown whenever the customer should be on public health insurance
// already, due to being employed by a German employer (who is legally obligated to sign them up for public),
// but has answered something different on the "mostRecentHealthInsurance" question.

const View = ({
  onAnswerQuestion,
  answer,
}: GenericViewProps<'insuranceHistory', 'alreadyOnPublicViaEmployer'>) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(answer);
  const valid = value !== undefined;
  const [, loadChat] = useChat();

  const handleSendUsAMessageClick = () => {
    loadChat({ open: true });
  };

  return (
    <Center>
      <Form valid={valid} value={value} onAnswerQuestion={onAnswerQuestion}>
        <div className="ta-center wmx8 p-container d-flex fd-column ai-center">
          <BlockerIcon />
          <h1 className="p-h1 mt16">
            {t(
              'questions.alreadyOnPublicViaEmployer.title',
              'Your employer already signed you up with public health insurance'
            )}
          </h1>
          <Trans i18nKey="questions.alreadyOnPublicViaEmployer.description">
            <p className="p-p mt8">
              Your employer has 6 weeks after your employment start date to sign
              you up for public health insurance. You have to be insured by now
              and should contact your employer for more details about your
              health insurance.
            </p>
            <p className="p-p mt24">
              If you want to cancel your public insurance going forward and
              switch to private, you can continue the sign-up below.
            </p>
          </Trans>
          <button
            data-cy="questionnaire-continue"
            className="p-btn--primary wmn4 mt24"
            type="submit"
            onClick={() => setValue(true)}
          >
            {t(
              'questions.alreadyOnPublicViaEmployer.continue.action',
              'Continue sign-up'
            )}
          </button>
          <button
            className="p-btn--secondary bg-white wmn4 mt24"
            type="button"
            onClick={handleSendUsAMessageClick}
          >
            {t(
              'questions.alreadyOnPublicViaEmployer.contactUs.action',
              'Contact us'
            )}
          </button>
        </div>
      </Form>
    </Center>
  );
};

export default connectedAnswer(
  'insuranceHistory',
  'alreadyOnPublicViaEmployer'
)(View);
