import React from 'react';
import { useTranslation } from '@getpopsure/i18n-react';

import { WrappedForm } from 'pages/questions/formWrapper';
import { nonEmptyArrayValidator, nonEmptyStringValidator } from 'validators';
import Center from 'components/center';
import { generalQuestionsTitleMapping } from 'models/questions/text/title';

import { generateCheckboxInput } from 'pages/questions/generateInput';
import {
  getPreviousHealthInsuranceCoverageOptionsSecondaryTextMapping,
  getPreviousHealthInsuranceCoverageOptionsTextMapping,
  PreviousHealthInsuranceCoverageOptions,
} from 'models/questions/general/insuranceHistoryV2';

export default () => {
  const { t } = useTranslation();

  const skipButtonCaption = t(
    'questions.whatWasCovered.skip.action',
    "I don't know"
  );

  return (
    <Center>
      <h1 className="p-h1">
        {generalQuestionsTitleMapping.whatWasCovered?.(t)}
      </h1>
      <WrappedForm
        validator={(input) =>
          typeof input === 'string'
            ? nonEmptyStringValidator(input)
            : nonEmptyArrayValidator(input)
        }
        sectionId="insuranceHistory"
        questionId="whatWasCovered"
        label={getPreviousHealthInsuranceCoverageOptionsTextMapping(t)}
        skipButtonProps={{
          caption: skipButtonCaption,
          skipValue: skipButtonCaption,
        }}
      >
        {({ value, setValue }) => {
          // The value when skipping this question is the string "I don't know" (skipButtonCaption) to make it show up on the review screen.
          // (If we used an empty array instead as the "skipValue", the question would not show up on the review screen.)
          // When going back to this question however, the value will be a string, which of course can not be mapped via generateCheckboxInput().
          // That means we need to make it an empty string for displaying purposes instead.
          const inputValue = typeof value === 'string' ? [] : value;

          return (
            <div className="mt8">
              <p className="p-p tc-grey-600 mb16">
                In most cases, all 3 options apply.
              </p>
              {generateCheckboxInput(
                'whatWasCovered',
                inputValue,
                getPreviousHealthInsuranceCoverageOptionsTextMapping(t),
                (newValue: PreviousHealthInsuranceCoverageOptions[]) => {
                  setValue(newValue);
                },
                getPreviousHealthInsuranceCoverageOptionsSecondaryTextMapping(t)
              )}
            </div>
          );
        }}
      </WrappedForm>
    </Center>
  );
};
